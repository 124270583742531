import React from 'react';
import {ImageAddon, ProductColorImages} from "../selection/selectionSlice";
import {AddonInfos, BoutiqueColorToHex, DefaultPersoContent, PersoInfos} from "./boutiqueSlice";
import PersoAddonConfig from "./PersoAddonConfig";
import {FormattedMessage} from "react-intl";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectPersoColor from "./SelectPersoColor";
import ColorTag from "../catalog/ColorTag";

export const SMALL_PERSO_CONTENTS = ['initials', 'text', 'number']
export const BIG_PERSO_CONTENTS = ['text', 'number']


export default function PersoAddonsList(props:{
  imagePersoAddons: ImageAddon[],
  persoAllowed: boolean,
  setPersoAllowed: (persoAllowed: boolean) => void,
  persoExpanded: boolean,
  setPersoExpanded: (persoExpanded: boolean) => void,
  persoColor: string,
  setPersoColor: (colorIndex: number, persoColor: string) => void,
  boutiqueColors: string[],
  setBoutiqueColors: (colors:string[]) => void,
  persoAddons: PersoInfos[],
  colorIndex: number,
  firstColorName: string,
  nbProductColors: number,
  excludedAddons: string[],
  setPersoInfos: (persoInfos: PersoInfos, colorIndex: number) => void}) {

  const {imagePersoAddons, persoAllowed, setPersoAllowed, persoExpanded, setPersoExpanded,
          persoColor, setPersoColor, persoAddons, colorIndex, firstColorName, nbProductColors,
          excludedAddons, setPersoInfos} = props;

  // console.log('%c At PersoAddonsList level, persoAddons: ' + JSON.stringify(persoAddons), 'color: red');
  
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
      setPersoExpanded(isExpanded);
    };

  // console.log('Rendering PersoAddonsList of color ', colorIndex, ' with ', JSON.stringify(persoAddons));

  // Showing the user the list of colors in the perso addons (because they might be collapsed and not visible)
  let differentPersoColors: string[] = []

  const persoAddonsConfigs = imagePersoAddons.map((imageAddon, i) => {

    const persoInfos = persoAddons.find(addon => addon.name === imageAddon.name) || {
      addonId: imageAddon.id,
      name: imageAddon.name,
      activated: true,
      content: DefaultPersoContent(imageAddon),
      colorList: Array(nbProductColors).map((color: ProductColorImages) => imageAddon.lightBg ? 'black' : 'white'),
    };

    if (persoColor === '' && persoInfos.colorList[colorIndex] && differentPersoColors.indexOf(persoInfos.colorList[colorIndex]) === -1) {
      differentPersoColors.push(persoInfos.colorList[colorIndex])
    }

    return (
      <PersoAddonConfig key={`${i}-${imageAddon.id}`}
                        imgAddon={imageAddon}
                        persoInfos={persoInfos}
                        definePersoColor={persoColor === ''}
                        colorIndex={colorIndex}
                        boutiqueColors={props.boutiqueColors} setBoutiqueColors={props.setBoutiqueColors}
                        disabled={excludedAddons.includes(imageAddon.name)} setPersoInfos={setPersoInfos} />
    );
  })

  return (
    <div className='perso-addon-list'>
      <Accordion sx={{
        '&.MuiAccordion-root': {
            boxShadow: 'none'
          },
        '&.MuiAccordion-root:before': {
          height: 0
        }
      }
      } expanded={persoExpanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          className='perso-accordion'
        >
          <FormControlLabel label={
            <>
              <FormattedMessage id={`product.allow-persos`} />
              {(persoColor !== '' &&
                <SelectPersoColor colorIndex={colorIndex} color={persoColor} setColor={setPersoColor}
                                boutiqueColors={props.boutiqueColors} setBoutiqueColors={props.setBoutiqueColors} />) ||
                <>
                  {differentPersoColors.length > 0 &&
                    <div className='perso-colors'>
                      {
                        differentPersoColors.map((color, i) => {
                          // console.log('Display differentPersoColors color: ', color)
                          const hexColor = BoutiqueColorToHex(props.boutiqueColors, color);
                          return(<ColorTag color1={hexColor} color2={hexColor} size='small'/>)
                        })
                      }
                    </div>
                  }
                </>
              }
            </>
          } control={<Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='medium'
                               disabled={colorIndex !== 0} checked={persoAllowed}
            onChange={ (event) => setPersoAllowed(event.target.checked) } />} />
        </AccordionSummary>
        <AccordionDetails>
          {persoAddonsConfigs}
        </AccordionDetails>
      </Accordion>
      <div>
        {colorIndex > 0 && <div className="choose-logo-position">
			    (<FormattedMessage id='product.choose-perso-on-first-image' values={{color: firstColorName}} />)
		    </div>}
      </div>
    </div>
  )
}
