import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  defineColors,
  invertColors,
  primaryColor,
  secondaryColor,
  selectPrimaryColor,
  selectSecondaryColor
} from "./catalogSlice";
import {SelectColor} from "./SelectColor";
import {ColorTypes} from "../../app/catalog";
import {useAppSelector} from "../../app/hooks";
import {SelectGender} from "./SelectGender";
import {SelectBrand} from "./SelectBrand";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import {SelectStyle} from "./SelectStyle";
import {BrandIcons} from "./BrandIcons";
import {updateProfile} from "../sessions/sessionSlice";
import {RootState} from "../../app/store";
import {DAGOBA_COLOR} from "../../app/global";



export default function SearchBar() {
  const primaryColorValue = useAppSelector(primaryColor);
  const secondaryColorValue = useAppSelector(secondaryColor);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedUser) {
      if (currentUser?.locale && currentUser?.color1 !== primaryColorValue || currentUser?.color2 !== secondaryColorValue) {
        dispatch(defineColors({primaryColor: currentUser?.color1, secondaryColor: currentUser?.color2}));
      }
    }
  }, [loggedUser, currentUser]);

  return (
    <Box className='search-bar' >
      <Box className='two-colors' >
          <SelectColor colorType={ColorTypes.Primary} />
          <SwapHorizontalCircleIcon sx={{cursor:'pointer', mt:'38px', color: DAGOBA_COLOR}}
                                    onClick={(event) => {
            if (secondaryColorValue !== 'none') {
              dispatch(invertColors((event.target as HTMLInputElement).value));
              if (loggedUser) {
                dispatch(updateProfile({color1: secondaryColorValue, color2: primaryColorValue}));
              }
            }
          }} />
          <SelectColor colorType={ColorTypes.Secondary} />
      </Box>
      <SelectGender />
      <SelectStyle />
      <SelectBrand />
      <BrandIcons />

    </Box>
  );
}