import React, {useState} from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import ColorTag from "../catalog/ColorTag";
import ColorPicker from "../utils/ColorPicker";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store";

const BASE_COLORS = ['white', 'black']

export default function SelectPersoColor(props: {
            colorIndex: number,
            color: string,
            setColor: (colorIndex: number, color:string) => void,
            boutiqueColors: string[],
            setBoutiqueColors: (colors:string[]) => void,
}) {

  // console.log("Render SelectPersoColor for color " + props.color)
  const dispatch = useDispatch<AppDispatch>();


  const {colorIndex, color, setColor, boutiqueColors, setBoutiqueColors} = props;

  const allColors = BASE_COLORS.concat(boutiqueColors.map((color, index) => {
    return 'color' + (index + 1);
  })).concat('new-color');

  // console.log("allColors", JSON.stringify(allColors));

  const setColorHex = (colorCode: string, hexColor: string) => {
    console.log('setColorHex of ', colorCode, ' to ', hexColor);
    let newBoutiqueColors = boutiqueColors.slice();

    if (colorCode === 'new-color') {
      newBoutiqueColors = newBoutiqueColors.concat(hexColor);
    } else {
      newBoutiqueColors[parseInt(colorCode.substring(5)) - 1] = hexColor;
    }
    setBoutiqueColors(newBoutiqueColors)

    if (colorCode === 'new-color') {
      setColor(colorIndex, 'color' + (boutiqueColors.length + 1));
    }
  }


  const persoColorHex = (colorCode: string) => {
    switch (colorCode) {
      case 'white':
        return '#FFFFFF';
      case 'black':
        return '#000000';
      case 'new-color':
        return '#808080';
      default:
        return boutiqueColors[parseInt(colorCode.substring(5)) - 1];
    }
  }

  const colorName = (colorCode: string) => {
    if (colorCode.startsWith('color')) {
      return <FormattedMessage id="color.color-number" values={{number: parseInt(colorCode.substring(5))}} />;
    } else if (colorCode === 'new-color') {
      return <FormattedMessage id={`color.new-color`} />;
    } else {
      return <FormattedMessage id={`color.${colorCode}`} />;
    }
  }

  const colorValue = (colorCode: string) => {

    const colorHex = persoColorHex(colorCode);
    const editableColor = (colorCode.startsWith('color') && parseInt(colorCode.substring(5)) > 2);

    return(
      <div className='perso-color-value'>
        <div className='perso-color-name'>
          <>
            {colorCode !== 'new-color' && <ColorTag color1={colorHex} color2='' active={false} size='small' />}
            {colorName(colorCode)}
          </>
        </div>
        <div className='perso-color-brush'>
          {editableColor &&
            <ColorPicker key={`ed-${colorCode}`} color={colorHex} whiteAllowed={false} displayColor={false}
                         propertyName={colorCode} setColor={setColorHex} opened={false} />
          }
        </div>
      </div>
    );
  }

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    // console.log("new color chosen for perso addon => ", event.target.value);
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    if (event.target.value !== 'new-color') {
      // console.log("set color to ", event.target.value as string);
      setColor(colorIndex, event.target.value as string);
    } else {
      // console.log("Selected new color")
      setDisplayColorPicker(true);
    }
  }

  return (
    <>
      <div className='perso-color'>
        <Select SelectDisplayProps={{
          style: { paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 20, fontSize: '0.8em' } }}
                className={'select-color'}
                size="medium"
                value={color}
                onChange={handleColorChange}
                onClick={(e) => { e.stopPropagation(); }}
                renderValue={value => colorValue(value)}
                >
        {allColors.map((colorCode: string) => {
            return (
              <MenuItem key={`persocolor ${colorIndex}-${colorCode}`} value={colorCode}>
                {colorValue(colorCode)}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      {displayColorPicker &&
          <ColorPicker key={`ed-new-color`} color='' whiteAllowed={false} displayColor={false}
                       propertyName='new-color' setColor={setColorHex} opened={true} handleClose={setDisplayColorPicker} />}
    </>
  )

}
