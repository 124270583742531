import React, {useState} from 'react';
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {ILogoInfos, LogoVersion} from "../logos/logosSlice";
import LogoVersionSelect from "../logos/LogoVersionSelect";
import {ImageAddon} from "../selection/selectionSlice";
import {AddonInfos} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import {ToggleButton, ToggleButtonGroup, useMediaQuery} from "@mui/material";

export const defaultLogoVersionId = (imageAddon: ImageAddon, logo: ILogoInfos) => {
  let name = '';
  if (imageAddon.name.startsWith('small')) {
    name = 'Standard';
  } else if (imageAddon.name.startsWith('big')) {
    name = 'Grand';
  } else {
    name = 'Standard';
  }

  return(logo.versions.find(version => version.name === name)?.id || logo.versions[0]?.id || 0);
}


export default function SelectAddonLogo(props: {
            logos: ILogoInfos[],
            imageAddon: ImageAddon,
            addonInfo: AddonInfos,
            selectLogoVersion: (logoId: number, logoVersionId: number) => void,
            noneAllowed: boolean}) {

  const matches = useMediaQuery("(min-width:800px)");

  const {logos, imageAddon, addonInfo, selectLogoVersion, noneAllowed} = props;

  // initial value is the one already saved or the default value based on the addon name
  const [logosCurrentVersionIds, setLogosCurrentVersionIds] = useState<number[]>(logos.map(logo => {
    if ((logo.id === addonInfo.logoId) && (addonInfo.logoVersionId !== 0)) {
      return addonInfo.logoVersionId;
    } else {
      return defaultLogoVersionId(imageAddon, logo);
    }
  }));

  const handleSelectLogo = (
    event: React.MouseEvent<HTMLElement>,
    newLogoId: number | 0,
  ) => {
    const currentVersionId = logosCurrentVersionIds[logos.findIndex(logo => logo.id === newLogoId)];
    // console.log("handleSelectLogo for " + imageAddon.name + " current is " + addonInfo.logoId + " chosen => " + newLogoId + " with version " + currentVersionId);
    // clicking on the selected toggle button will send null
    if (newLogoId !== null && newLogoId !== undefined && newLogoId !== addonInfo.logoId) {
      selectLogoVersion(newLogoId, currentVersionId);
    }
  };

  const handleSelectLogoVersion = (newLogoId: number, logoVersionId: number) => {
    const newCurrentVersions = logosCurrentVersionIds.map((currentVersionId, index) => {
      if (logos[index].id === newLogoId) {
        return logoVersionId;
      } else {
        return currentVersionId;
      }
    });
    // console.log("handleSelectLogoVersion, new logo " + newLogoId + " with new version " + logoVersionId + " and newCurrentVersions " + JSON.stringify(newCurrentVersions));
    setLogosCurrentVersionIds(newCurrentVersions);
    selectLogoVersion(newLogoId, logoVersionId);
  }

  // console.log("Render SelectAddonLogo for addon " + imageAddon.name + " with logo " + addonInfo.logoId + " and logoCurrentVersions " + JSON.stringify(logosCurrentVersionIds) + " and version " + logosCurrentVersionIds[logos.findIndex(logo => logo.id === addonInfo.logoId)]);

  return (
    <ToggleButtonGroup orientation={`${matches ? `horizontal` : `vertical`}`} size="small" value={addonInfo.logoId} exclusive onChange={handleSelectLogo}>

      <ToggleButton key={`${imageAddon.id}-0`} value={0} size="small" className='select-logo-none' disabled={!noneAllowed}>
        <span><FormattedMessage id="global.none" /></span>
      </ToggleButton>

      {logos.map((logo: ILogoInfos, version_idx: number) => (
        <ToggleButton key={`${imageAddon.id}-${version_idx}`} value={logo.id} size="small" className='select-logo-button'>
          <LogoVersionSelect imageAddon={imageAddon} logo={logo} versionId={logosCurrentVersionIds[version_idx]}
                              setVersionId={handleSelectLogoVersion} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
