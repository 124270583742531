import React, {useState} from 'react';
import {ILogoInfos, LogoVersion} from "../logos/logosSlice";
import {ImageAddon} from "../selection/selectionSlice";
import {AddonInfos, PersoAddonPrice} from "./boutiqueSlice";
import {FormattedMessage, FormattedNumber} from "react-intl";
import SelectAddonLogo from "./SelectAddonLogo";

export default function LogoAddonConfig(props: {
  imgAddon: ImageAddon,
  addonInfos: AddonInfos,
  colorIndex: number,
  disabled: boolean,
  setAddonInfos: (colorIndex: number, addonInfos: AddonInfos) => void,
  logos: ILogoInfos[]}) {

  const {imgAddon, addonInfos, colorIndex, disabled, setAddonInfos, logos} = props;

  const [logo, setLogo] = useState<ILogoInfos | undefined>(logos.find(l => l.id === addonInfos.logoId));
  const [logoVersion, setLogoVersion] = useState<LogoVersion | undefined>(logo?.versions.find(v => v.id === addonInfos.logoVersionId));

  const handleSelectLogo = (logoId: number, logoVersionId: number) => {
    // console.log('At LogoAddonConfig level, handleSelectLogo ' + logoId + ' version ' +  logoVersionId);

    const newLogo = logos.find(l => l.id === logoId);

    setLogo(newLogo);
    setLogoVersion(logos.find(l => l.id === logoId)?.versions.find(v => v.id === logoVersionId));

    setAddonInfos(colorIndex, {
      addonId: imgAddon.id,
      name: imgAddon.name,
      logoId: logoId,
      logoVersionId: logoVersionId,
      zoom: addonInfos.zoom
    })
  }

  // console.log('Rendering image addon ' + imgAddon.name + ' with logo ' + addonInfos.logoId + ' version ' + addonInfos.logoVersionId);

  if (colorIndex === 0 || logoVersion) {
    return (
      <div className={`logo-addon-config ${disabled ? 'disabled' : ''}  ${logoVersion ? 'selected' : ''}`}>
        <div className="addon-name">
          <FormattedMessage id={`addon.${imgAddon.name}`} />
        </div>
        <div className='addon-logo-price'>
          <div className='select-addon-logo'>
            <SelectAddonLogo logos={logos} imageAddon={imgAddon} addonInfo={addonInfos}
                             selectLogoVersion={handleSelectLogo} noneAllowed={colorIndex === 0} />
          </div>
          <div className='addon-price'>
            {logoVersion &&
              <FormattedNumber
		            value={logoVersion.priceCents / 100} style="currency" currency={'EUR'}
		            minimumFractionDigits={2} maximumFractionDigits={2}
              />}
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }


}
