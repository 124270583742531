import React, {useState, useEffect, memo } from 'react';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import LogoCard from "./LogoCard";
import {editedLogoHasChangesSelector, hasAutomaticLogoSelector, ILogoInfos} from "./logosSlice";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";


const LogosList = (props: {logos: ILogoInfos[]}) => {

  const {logos} = props;

  const logoHasChanges = useSelector(editedLogoHasChangesSelector);
  const hasAutomaticLogo = useSelector(hasAutomaticLogoSelector);

  return(
    <Box sx={{width: '180px'}}>
      <Grid container spacing={1} className='logos'>
          { hasAutomaticLogo && <div className='temporary'>
            <FormattedMessage id="logos.temporary-logos" />
          </div>}

          {logos && logos.map(logo =>
              <LogoCard logo={logo} key={logo.id} editable={logoHasChanges === false} />
          )}

      </Grid>
    </Box>
  )
}


export default LogosList;