import React, {useEffect, useState} from 'react'
import reactCSS from 'reactcss'
import {ColorResult, SketchPicker} from 'react-color'
import BrushIcon from "@mui/icons-material/Brush";

const colorSet = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#ff7aed',
    '#9013FE',
    '#084090',
    '#4A90E2',
    '#50E3C2',
    '#000000',
    '#A0A0A0'
  ]

const ColorPicker = (props:{
  propertyName: string,
  opened: boolean,
  color:string,
  displayColor:boolean,
  hideBrush?: boolean,
  setColor: (propertyName:string, newHexColor: string) => void,
  whiteAllowed:boolean,
  handleClose?: (open: boolean) => void
}) => {

  // console.log("Render ColorPicker with propertyName " + props.propertyName + " opened=" + props.opened + " and color=" + props.color)

  const {propertyName, opened, color, displayColor, hideBrush, setColor, whiteAllowed} = props

  const [displayColorPicker, setDisplayColorPicker] = useState(opened)
  const [displayedColor, setDisplayedColor] = useState(color)

  useEffect(() => {
    setDisplayedColor(color)
  }, [color, opened]);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setDisplayColorPicker(false)
        setColor(propertyName, color)
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [])

  const styles = reactCSS({
    'default': {
      color: {
        width: '50px',
        height: '20px',
        borderRadius: '2px',
        background: displayedColor
      },
      swatch: {
        padding: '3px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        marginTop: '2px',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: 2,
        marginLeft: '-325px'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      modal: {
        position: 'fixed',
        zIndex: 10,
        top: '50%',
        left: '50%',
        marginTop: '-190px',
        marginLeft: '-190px',
      },
      picker : {
        boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 2px, rgb(0 0 0 / 35%) 0px 8px 16px'
      }
    },
  });

  const openColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    setDisplayColorPicker(true)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color: ColorResult) => {
    // console.log("color changed to " + color.hex)
    setDisplayedColor(color.hex)
  };

  const handleHoverColor = (color: ColorResult) => {
    // console.log("color hovered " + color.hex)
    setDisplayedColor(color.hex)
  };

  const handleChangeComplete = (color: ColorResult) => {
    // console.log("color change completed")
    setDisplayedColor(color.hex)
    setColor(propertyName, color.hex)
  };

  const proposedColors = () => {
    let baseColors = [...colorSet];

    if (whiteAllowed) {
      baseColors.push('#FFFFFF')
    }

    return baseColors
  }

  const colorDisplay = () => {
    if (displayColor) {
      return (
        <div style={styles.swatch} onClick={openColorPicker}>
          <div style={styles.color} />
        </div>
      )
    } else if (!opened) {
      return (!hideBrush &&
        <div id={`edc-${propertyName}`} onClick={openColorPicker} style={{display: "flex", alignItems: "center"}}>
          <BrushIcon htmlColor={displayedColor} />
        </div>
      )
    } else {
      return null
    }
  }

  const colorPickerCard = () => {
    if (displayColor) {
      return (
        <div style={styles.popover as React.CSSProperties}>
          <div style={ styles.cover as React.CSSProperties } onClick={ () => handleClose() }/>
          <SketchPicker color={ displayedColor } disableAlpha={true} width='360px' presetColors={proposedColors()}
                        onChange={handleChange} onSwatchHover={handleHoverColor}
                        onChangeComplete={ (color: ColorResult) => handleChangeComplete(color) } />
        </div>
      )
    } else {
      return(
        <div style={styles.modal as React.CSSProperties}>
          <div style={ styles.cover as React.CSSProperties } onClick={ () => {
            props.handleClose && props.handleClose(false)
          }} />
          <SketchPicker color={ displayedColor } disableAlpha={true} width='360px'
                        presetColors={proposedColors()}
                        onChange={handleChange} onSwatchHover={handleHoverColor}
                        onChangeComplete={handleChangeComplete} />
        </div>
      )
    }
  }

  return (
    <>
      {colorDisplay()}
      { displayColorPicker ? colorPickerCard() : null }
    </>
  )
}

export default ColorPicker