import React from 'react';
import {ColorTypes} from "../../app/catalog";
import {useDispatch, useSelector} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {primaryColor, secondaryColor, selectPrimaryColor, selectSecondaryColor} from "./catalogSlice";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {FormControl, FormHelperText} from "@mui/material";
import {useIntl} from "react-intl";
import {ProductColors, HexCode, TextColor} from "../../app/color";
import {updateProfile} from "../sessions/sessionSlice";
import {RootState} from "../../app/store";


export function SelectColor(props: {colorType: ColorTypes}) {

  const intl = useIntl();

  const colorType = props.colorType
  const selectPrimary = colorType === ColorTypes.Primary;
  const color = useAppSelector( selectPrimary ? primaryColor : secondaryColor );
  const otherColor = useAppSelector( selectPrimary ? secondaryColor : primaryColor );

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;

  const dispatch = useDispatch();

  const productColors = ProductColors.slice(selectPrimary ? 1 : 0);

  const handleColor = (event: SelectChangeEvent) => {
    let color_code = event.target.value as string;
    dispatch(selectPrimary ? selectPrimaryColor(color_code) : selectSecondaryColor(color_code));

    let colorPayload = selectPrimary ? {
      color1: color_code
    } :
    {
      color2: color_code
    }

    if (loggedUser) {
      dispatch(updateProfile(colorPayload));
    }

  };

  const textColor = TextColor(color);

  return (
    <FormControl sx={{ m: 1, ml:'5px', mr:'0px' }} size="small" className='select-tool'>
      <FormHelperText sx={{ml:0,mr:0}}>{intl.formatMessage({ id: `color.${selectPrimary ? "main" : "secondary"}`})}</FormHelperText>
      <Select className={`color-group ${textColor} ${selectPrimary ? 'primary' : 'secondary'}`}  value={color} onChange={handleColor}
              style={{"backgroundColor": HexCode(color)}}>
        {productColors.map(color => (
          otherColor !== color.code &&
          <MenuItem key={`${selectPrimary ? 'p' : 's'}${color.code}`} value={color.code}
                    style={{backgroundColor: color.hexcode, color:color.textcolor}}>
            {intl.formatMessage({ id: `color.${color.code}`})}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

}
