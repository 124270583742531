import React, {useState} from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import {PriceFormatted, IProduct} from "../../app/product";
import {FormControl} from "@mui/material";

// const MAX_MARGIN_PERCENTS = 15;

const maxMarginPercent = (productPrice: number) => {
  if (productPrice <= 699) {
    return 30;
  } else if (productPrice < 1000) {
    return 25;
  } else if (productPrice < 1500) {
    return 20;
  } else if (productPrice < 2200) {
    return 17.50;
  } else if (productPrice < 2700) {
    return 16;
  } else if (productPrice < 3350) {
    return 15;
  } else if (productPrice < 3750) {
    return 13.5;
  } else if (productPrice < 5000) {
    return 12;
  } else if (productPrice < 6800) {
    return 11;
  } else if (productPrice < 7600) {
    return 10.5;
  } else {
    return 10;
  }
}


export default function SelectProductMargin(props: {
  product: IProduct,
  margin: number,
  setMargin: (margin: number) => void
  }) {

  const {product, margin, setMargin} = props;

  // const possibleMargins = [0, 50, 100, 150, 200, 250, 300, 350];

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // All the below code is to compute the possible margins for a given price and help define maxMarginPercent function
  // let previousPrice = 0;
  // for (let p = 4000; p <= 10000; p += 50) {
  //   const maxMarginPC = MaxMarginPercent(p)
  //   const localMaxMargin = p * maxMarginPC / 100;
  //   const localPossibleMargins = [];
  //   for (let j = 0; j <= localMaxMargin; j += 50) {
  //     localPossibleMargins.push(j);
  //   }
  //
  //   const highestMargin = localPossibleMargins[localPossibleMargins.length - 1];
  //
  //   const color = highestMargin < previousPrice ? "red" : "green";
  //
  //   console.log(PriceFormatted(highestMargin) + "  %cPrice=" + PriceFormatted(p) + " (" + maxMarginPC + "%) Max price=" + PriceFormatted(p + highestMargin), "color: " + color);
  //   previousPrice = highestMargin
  // }

  const productMaxPrice = product.prices[product.prices.length - 1];
  const maxMargin = productMaxPrice * maxMarginPercent(productMaxPrice) / 100;
  const possibleMargins = [];

  for (let i = 0; i <= maxMargin; i += 50) {
    possibleMargins.push(i);
  }

  const handleSetMargin = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    // console.log("SelectAddonPerso: handleContentChange for " + imageAddon.name + " with new content " + event.target.value);
    // setPersoContent(event.target.value as string);
    // console.log("SelectProductMargin: handleSetMargin for " + product.title + " with new margin " + event.target.value);
    setMargin(parseInt(event.target.value));
  }

  // console.log("Render SelectProductMargin for product " + product.title + " with margin " + margin);

  return (
    <Select className={'select-contentss'} size="small" autoWidth
            value={margin.toString()}
            onChange={handleSetMargin}
            onClick={(e) => { e.stopPropagation(); }}>
      {possibleMargins.map((m: number) => {
        return (
          <MenuItem key={`${product.id}-${m}`} value={m.toString()}>
            {PriceFormatted(m)}
          </MenuItem>
        );
      })}
    </Select>
  )

}
