import React, {useState, useEffect } from 'react';
import {Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow} from "@mui/material";
import {ILogoInfos, editLogo, logoVersionUsageProducts} from "./logosSlice";
import { styled } from '@mui/material/styles';
import {FormattedMessage, useIntl} from "react-intl";
import {PriceFormatted} from "../../app/product";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {DAGOBA_COLOR} from "../../app/global";


const LOGO_SIZE_NAMES = [
  "30",
  "75",
  "150",
  "300",
  "600",
]

function LogoVersionsCard(props: {logo: ILogoInfos}) {

  const intl = useIntl();

  const logo = props.logo;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: DAGOBA_COLOR,
      // color: theme.palette.common.white,
      color: '#FFFFFF',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const versionInformation = (logoVersionId: number) => {
    const usage = logoVersionUsageProducts(logo, logoVersionId)
    if (usage.length === 0) {
      return '';
    } else {
        return(intl.formatMessage({ id: "logos.usage"}) + `<ul>${usage}</ul>`)
    }
  }

  const usedExplanation = (usage: string) => (usage ? <div dangerouslySetInnerHTML={{ __html: usage}}/> : null)

  const stylesForUsed = (used: boolean) => (used ?
      {cursor: 'pointer', fontWeight: 'bold'} : {cursor: 'auto', fontWeight: 'normal'});

  return (
    <TableContainer component={Paper}>
      {/*<Table sx={{ minWidth: 300 }} size="small">*/}
      <Table size="small" className='logo-table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width={80}><FormattedMessage id="global.name" /></StyledTableCell>
            <StyledTableCell align="center"><FormattedMessage id="global.width_height_mm" /></StyledTableCell>
            <StyledTableCell align="center">Max cm2</StyledTableCell>
            <StyledTableCell align="center" width={35}><FormattedMessage id="global.price" /></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logo.versions.map((logoVersion) => ({
            ...logoVersion, usage: versionInformation(logoVersion.id)
          })).map((lvWithUsage) => (
            <BoudaToolTip title={usedExplanation(lvWithUsage.usage)} placement="left" sx={{textAlign: 'left'}}>
              <StyledTableRow key={lvWithUsage.id}>
                <StyledTableCell align="left" width={80} sx={stylesForUsed(!!lvWithUsage.usage)}>{lvWithUsage.name}</StyledTableCell>
                <StyledTableCell align="center" sx={stylesForUsed(!!lvWithUsage.usage)}>{`${lvWithUsage.width}x${lvWithUsage.height}`}</StyledTableCell>
                <StyledTableCell align="center" sx={stylesForUsed(!!lvWithUsage.usage)}>{LOGO_SIZE_NAMES[lvWithUsage.sizeCode - 1]}</StyledTableCell>
                <StyledTableCell align="right" width={35} sx={stylesForUsed(!!lvWithUsage.usage)}>{PriceFormatted(lvWithUsage.priceCents)}</StyledTableCell>
              </StyledTableRow>
            </BoudaToolTip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default LogoVersionsCard;