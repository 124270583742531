import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import {FormattedMessage} from "react-intl";
import {Button, Grid, Stack} from "@mui/material";
import CatalogSearch from "./CatalogSearch";
import Box from "@mui/material/Box";
import ProductsTable from "./ProductsTable";
import {useAppSelector} from "../../../app/hooks";
import {ProductForm} from "./ProductForm";
import {openDefinition, shownDefinitonSelector} from "../../catalog/catalogSlice";

export function CatalogAdmin() {

  const shownDefinition = useAppSelector(shownDefinitonSelector)

  const dispatch = useDispatch<AppDispatch>();

  // console.log("Render CatalogAdmin with shownDefinition: ", JSON.stringify(shownDefinition));

  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, w:1, minHeight: '80vh', backgroundColor: 'yellows'}} >
      {(shownDefinition && <ProductForm /> ||
        <>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center', backgroundColor: 'reds'}} >
            <CatalogSearch />
            <Button variant="contained" color="success" size="medium" sx={{m: 1}}
                    onClick={() => { dispatch(openDefinition({productId: null}))}}>
              <FormattedMessage id="global.add" />
            </Button>
          </Box>
          <Box sx={{flex: 1, backgroundColor: 'bslue'}} >
            <ProductsTable />
          </Box>
        </>
      )}
    </Box>
  )

}
