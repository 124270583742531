import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {IProduct} from "./product";
import {IProductDefinition, IProductSearch} from "./catalog";

const PRODUCT_DEFINITION_URL = "/products/definition";
const PROCURE_DATABASE_URL = "/products/procure";
const UPDATE_PRODUCT_PROPERTY_URL = "/products/update_property";
const UPDATE_PRODUCT_DEFINITION_URL = "/products/update_definition";
const CREATE_JUNIOR_VERSION_URL = "/products/create_junior";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}`;

export async function fetchProducts(category_code: string) {
  // console.log("Fetching products with CORS 11 on url => '" +
  //   `${API_URL}/products` + "' with category_code " + category_code);
  //
  return fetch(`${API_URL}/products.json?category=${category_code}`, {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
    }).then(response => response.json()).
    catch(error => {
      // todo: handle error properly
      // console.log('Error in fetchProducts: ', error);
      return({error: 'Fetching products failed'});
    });

}


export async function fetchProductDefinitionRequest(
  pSearch: IProductSearch
) {
  const data = {
    search: pSearch,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(PRODUCT_DEFINITION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateProductDefinitionRequest(
  definition: IProductDefinition
) {
  const data = {
    definition: definition,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  return axios
    .patch(UPDATE_PRODUCT_DEFINITION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function createJuniorVersionRequest(
  id: number,
  juniorSize: string
) {
  const data = {
    product_id: id,
    junior_size: juniorSize,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  console.log("createJuniorVersionRequest with data ", JSON.stringify(data));

  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  return axios
    .patch(CREATE_JUNIOR_VERSION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export  interface IProductPropertyUpdate {
  productIds: number[];
  propertyName: string;
  propertyValue: string;
}

export async function updateProductPropertyRequest(
  property: IProductPropertyUpdate
) {
  const data = {
    product_ids: property.productIds,
    property_name: property.propertyName,
    property_value: property.propertyValue,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  return axios
    .patch(UPDATE_PRODUCT_PROPERTY_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

