import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import catalogReducer from '../features/catalog/catalogSlice';
import productDetailsReducer from '../features/productDetails/productDetailsSlice';
import selectionReducer from '../features/selection/selectionSlice';
import sessionReducer from '../features/sessions/sessionSlice';
import globalReducer from '../features/global/globalSlice';
import boutiqueReducer from '../features/dashboard/boutiqueSlice';
import logosReducer from '../features/logos/logosSlice';
import productionReducer from '../features/admin/production/productionSlice';
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  // blacklist: [],
  predicate: (action: any) => typeof action !== "function",
  serializableCheck: {
    // Ignore these action types
    // ignoredActions: ['openConfirmDialog'],
    // Ignore these field paths in all actions
    ignoredActionPaths: ['payload.action','payload.message'],
    // Ignore these paths in the state
    ignoredPaths: ['global.confirmDialogState.dialogAction', 'global.snackBarState.snackBarMessage'],
  },
};
const middlewares = [createStateSyncMiddleware(config)];

export const store = configureStore({
  reducer: {
    global: globalReducer,
    session: sessionReducer,
    catalog: catalogReducer,
    product_details: productDetailsReducer,
    selection: selectionReducer,
    boutique: boutiqueReducer,
    logos: logosReducer,
    production: productionReducer,
  },
  middleware: (getDefaultMiddleware)  => middlewares.concat(getDefaultMiddleware()),
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       // Ignore these action types
  //       // ignoredActions: ['openConfirmDialog'],
  //       // Ignore these field paths in all actions
  //       ignoredActionPaths: ['payload.action'],
  //       // Ignore these paths in the state
  //       ignoredPaths: ['global.confirmDialogState.dialogAction'],
  //     },
  //   }),
});

initMessageListener(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
