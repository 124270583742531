import React from 'react';
import {useDispatch} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import {currentWorkshopSelector, selectWorkshop, workshopListSelector} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";

export function SelectWorkshop() {

  const workshopList = useAppSelector(workshopListSelector);
  const currentWorkshop = useAppSelector(currentWorkshopSelector);
  const dispatch = useDispatch();

  // No need to display the select if there is only one workshop
  if (workshopList.length <= 1) return (null);

  const changeWorshop = (event: SelectChangeEvent) => {
    dispatch(selectWorkshop(event.target.value))
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 150 }} size="small" className='select-tool'>
      <FormHelperText sx={{ml:0}}>Atelier</FormHelperText>
      <Select value={currentWorkshop?.name || ''} onChange={changeWorshop}>
        {workshopList.map((name, i) => (
          <MenuItem key={`sel-wrkshp${i+1}`} value={name}>{name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )


}
