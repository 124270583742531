import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ToggleButton,
} from "@mui/material";
import {ImageAddon} from "../selection/selectionSlice";
import {PersoAddonPrice, PersoInfos} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import SelectAddonPerso from "./SelectAddonPerso";
import SelectPersoColor from "./SelectPersoColor";
import {FormattedNumber} from 'react-intl';

export default function PersoAddonConfig(props: {
  imgAddon: ImageAddon,
  persoInfos: PersoInfos,
  definePersoColor: boolean,
  colorIndex: number,
  boutiqueColors: string[],
  setBoutiqueColors: (colors:string[]) => void,
  disabled: boolean,
  setPersoInfos: (persoInfos: PersoInfos, colorIndex: number) => void}) {

  const {imgAddon, persoInfos, colorIndex, boutiqueColors, disabled, setPersoInfos} = props;

  const handleActivate = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPersoActivated(event.target.checked);

    setPersoInfos({
      addonId: imgAddon.id,
      name: persoInfos.name,
      activated: event.target.checked,
      content: persoInfos.content,
      colorList: persoInfos.colorList
    }, colorIndex);
  }

  const handleSelectContent = (content: string) => {
    // console.log('At PersoAddonConfig level, handleSelectContent ' +  content);

    setPersoInfos({
      addonId: imgAddon.id,
      name: persoInfos.name,
      activated: persoInfos.activated,
      content: content,
      colorList: persoInfos.colorList
    }, colorIndex)
  }

  const handleSelectColor = (colorIndex: number, color: string) => {
    // console.log('At PersoAddonConfig level for image index ' + colorIndex + ' handleSelectColor ' +  color);

    let newColorList = [...persoInfos.colorList];
    newColorList[colorIndex] = color;

    setPersoInfos({
      addonId: imgAddon.id,
      name: persoInfos.name,
      activated: persoInfos.activated,
      content: persoInfos.content,
      colorList: newColorList
    }, colorIndex)
  }

  if (colorIndex === 0 || persoInfos.content !== '') {
    // console.log('Render PersoAddonConfig for ' + imgAddon.name + ' with colorIndex ' + colorIndex + ', disabled ' + disabled + ' and infos ' + JSON.stringify(persoInfos));
    return (
      <div className={`perso-addon-config ${disabled ? 'disabled' : ''}`}>
        <div className="perso-name-content">
          <div className="person-name">
            <FormControlLabel label={<FormattedMessage id={`addon.${imgAddon.name}`} />}
              control={
              <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                        disabled={colorIndex > 0 || disabled}
                        checked={persoInfos.activated}
                        onChange={handleActivate} />
            } />
          </div>
          { disabled ? null : <>
            <SelectAddonPerso imageAddon={imgAddon}
                              disabled={colorIndex > 0 || disabled}
                              persoInfos={persoInfos}
                              setPersoContent={handleSelectContent} />
            {props.definePersoColor &&
			          <SelectPersoColor colorIndex={colorIndex}
			                            color={persoInfos.colorList[colorIndex]}
			                            setColor={handleSelectColor}
			                            boutiqueColors={props.boutiqueColors}
			                            setBoutiqueColors={props.setBoutiqueColors} />
            }
          </>}
        </div>
        <div className="perso-price">
          <FormattedNumber
            value={PersoAddonPrice(imgAddon, persoInfos.content) / 100}
            style="currency" currency={'EUR'} minimumFractionDigits={2} maximumFractionDigits={2}
          />
        </div>
      </div>
    )
  } else {
    return null;
  }

}
