import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import produce from "immer";
import {RootState} from "../../app/store";
import {fetchBoutiques, getSubdomainAvailability, previewBoutiqueProduct, updateBoutique} from "../../app/boutiqueAPI";
import {openSnackBar} from "../global/globalSlice";
import {IProduct} from "../../app/product";
import {ImageAddon, ProductColorImages, ProductImage, SelectedProduct} from "../selection/selectionSlice";
import {arrayMoveImmutable, arrayMoveMutable} from "../../app/utils";
import {IProductSearch} from "../../app/catalog";
import {fetchProductDefinitionRequest} from "../../app/productAPI";
import {FormattedMessage} from "react-intl";

export interface AddonInfos {
  addonId: number;
  name:string;
  logoId: number;
  logoVersionId: number;
  zoom: number;
}

export const DefaultPersoContent = (imageAddon: ImageAddon) => {
  let content = '';
  if (imageAddon.name.startsWith('small')) {
    content = 'initials';
  } else if (imageAddon.name.startsWith('big') && imageAddon.name.includes('middle')) {
    content = 'number';
  } else {
    content = 'text';
  }
  return content;
}

//----------------------------------------------------
//------------------- BOUTIQUE COLORS -------------------
// in the back end, we save colors as hex values
// here on the frontend, we use color1, color2, etc. whenever possible

// from boutiqueColor to hexColor
export const BoutiqueColorToHex = (boutiqueColors: string[], color: string) => {
  if ((color === 'white') || (color === 'black') || (color === '')) {
    return color;
  } else {
    return boutiqueColors[parseInt(color.substring(5))-1];
  }
}


// from hexColor to boutiqueColor
export const DetectBoutiqueColors = (boutiqueColors: string[], hexColor:string) => {
  if (hexColor === '') {
    return ''
  } else if ((hexColor === 'white') || (hexColor === 'black')) {
    return hexColor;
  } else {
    const boutiqueColorIdx = boutiqueColors.findIndex((c) => c ===  hexColor);
    return((boutiqueColorIdx !== -1) ? `color${boutiqueColorIdx+1}` : '')
  }
}


export const PersoAddonPrice = (imageAddon: ImageAddon, content: string) => {

  const addonSmallSize = imageAddon.name.startsWith('small');
  const addonForBag = imageAddon.name.includes('bag');

  // console.log("persoAddonPrice for " + imageAddon.name + " with content " + content);

  switch(content) {
    case 'initials':
      return 350;

    case 'text':
      if (addonForBag) {
        return addonSmallSize ? 550 : 750;
      } else {
        return addonSmallSize ? 450 : 550;
      }

    case 'number':
      return addonSmallSize ? 350 : 550;

    default:
      return 0;
  }
}


export const AllPersoAddonsActivatedToDefault = (colors: ProductColorImages[]) => colors[0].images.
    map((image: ProductImage) => image.persoAddons).flat().
    map((persoAddon: ImageAddon) => {
      return ({
        addonId: persoAddon.id,
        name: persoAddon.name,
        activated: true,
        content: DefaultPersoContent(persoAddon),
        // colorList: Array(colors.length).fill(''),
        colorList: colors.map((color: ProductColorImages, index) => {
          if (index === 0) {
            return persoAddon.lightBg ? 'black' : 'white';
          } else {
            const equivalentAddonOnThisColor = color.images.map((image: ProductImage) => image.persoAddons).flat().
            find((addon: ImageAddon) => addon.name === persoAddon.name);

            if (equivalentAddonOnThisColor) {
              return equivalentAddonOnThisColor.lightBg ? 'black' : 'white';
            } else {
              return 'black';
            }
          }
        }),
      } as PersoInfos);
    });

const addonsBackgrounds = (colors: ProductColorImages[]) => colors.map((color: ProductColorImages) => {

  const imagesBackgrounds = color.images
    .filter((image: ProductImage) => (image.persoAddons.length > 0))
    .map((image: ProductImage) => {
      const imagePersoAddonAllLightBg = image.persoAddons.every((persoAddon: ImageAddon) => persoAddon.lightBg);
      const imagePersoAddonAllDarkBg = image.persoAddons.every((persoAddon: ImageAddon) => !persoAddon.lightBg);

      const imageAddonsBg = imagePersoAddonAllLightBg ? 'light' : (imagePersoAddonAllDarkBg ? 'dark' : 'mixed');
      // console.log("addonsDefaultColors for " + color.color + " with image " + image.id + " => " + imageAddonsBg);
      return(imageAddonsBg);
    });

  const colorPersoAddonAllLightBg = imagesBackgrounds.every((imageBg: string) => imageBg === 'light');
  const colorPersoAddonAllDarkBg = imagesBackgrounds.every((imageBg: string) => imageBg === 'dark');
  const colorPersoBg = colorPersoAddonAllLightBg ? 'light' : (colorPersoAddonAllDarkBg ? 'dark' : 'mixed');

  // console.log("colorPersoBg for " + color.color + " => " + colorPersoBg);
  return(colorPersoBg);
  });

export interface PersoInfos {
  addonId: number;  // this is the addon id (amoung the addons of first color images of the configured product)
  name: string;
  activated: boolean;
  content: string;  // Text, Number, Initials

  // color of the perso in each color of the configured product, if not blank.
  // If blank, the persoColor of CollectionProductColor is used
  colorList: string[];
}

export interface CollectionProductColor {
  colorImages: ProductColorImages;
  promote: boolean;
  logoAddons: AddonInfos[];
  persoColor: string; // default colors of all perso addons of the color, il blank, defined at CollectionProduct level, in persos
  hexColor1: string;
  hexColor2: string;
}

export interface CollectionProduct {
  collectionId: number;
  product: IProduct;
  productId: number | null;
  version: number;
  persoAllowed: boolean;
  persos: PersoInfos[];
  colors: CollectionProductColor[];
  margin: number;
  customTitle: string | null;
  hasChanges: boolean;
  imageUpdating?: boolean
}

interface collectionSibling {
  key: string
  id: number; // id of the original product
}

interface collectionGroup {
  manual: boolean;
  head: string;
  siblings: collectionSibling[];
}

export interface Collection {
  id: number;
  name: string;
  hasSiblings: boolean;
  separate: boolean;
  products: CollectionProduct[];
  groups: collectionGroup[];
}

const newCollection: Collection = {
  id: 0,
  name: "New collection",
  hasSiblings: false,
  separate: false,
  products: [],
  groups: [],
}

const collectionHasSiblings = (collection_products: CollectionProduct[]) => {

  // console.log("collectionHasSiblings for " + collection_products.length + " products");

  const hasSiblings = collection_products.some((cp1: CollectionProduct) =>
    cp1.product.connectedProductIds.some((pid: number) =>
      collection_products.some((cp2: CollectionProduct) => (cp2 !== cp1 && cp2.product.id === pid)
      )))

  // console.log("collectionHasSiblings => " + hasSiblings);

  return hasSiblings;
}

const collProductUniqueKey = (product: CollectionProduct) => {
  // return(product.productId !== null ? product.productId.toString() : product.product.id + "-" + product.version);
  return(product.product.id + "-" + product.version);
}

const dumpGroups = (collection: Collection, color:string) => {

  // console.log("%cGroups of collection " + collection.id, "color: " + color);
  collection.groups.forEach((group: collectionGroup) => {

    const groupProductPositions = group.siblings.map((sibling: collectionSibling) => indexInCollection(collection, sibling.key, sibling.id))

    // console.log("%c  " + group.head + " : " + group.siblings.map((sibling: collectionSibling) => sibling.key).join(', ') + " with product positions " + groupProductPositions.join(', '), "color: " + color);
  });
}

const dumpProducts = (color:string, products: CollectionProduct[]) => {
  // console.log("%cProducts of collection " + products[0]?.collectionId, "color: " + color);
  // products.forEach((cp: CollectionProduct, cp_index: number) => {
  //   console.log("%cposition " + cp_index + " : (" + collProductUniqueKey(cp) + ") " + cp.product.title + "(" + cp.product.id + ")  connections " + cp.product.connectedProductIds.join(', '), "color: " + color);
  // });

}

const belongsToGroup =(collection: Collection, key: string, id: number ) => {
  return(collection.groups.find((group: collectionGroup) =>
    group.siblings.some((sibling: collectionSibling) => sibling.key === key && sibling.id === id)));
}

const indexInProducts = (products: CollectionProduct[], key: string, id: number) =>
  products.findIndex((cp: CollectionProduct) => collProductUniqueKey(cp) === key && cp.product.id === id);

const indexInCollection = (collection: Collection, key: string, id: number) => indexInProducts(collection.products, key, id);

const groupPosition = (products: CollectionProduct[], group: collectionGroup) => indexInProducts(products, group.siblings[0].key, group.siblings[0].id);

const removeProductInGroups = (collection: Collection, id:number, key: string) => {

  // console.log("%cremoveProductInGroups for " + key + " (" + id + ")", "color: purple");
  collection.groups.filter((g:collectionGroup) => !g.manual).forEach((group: collectionGroup) => {

    // if we are removing the first product of the group, we need to update the group head
    // with the future first product of the group
    if (group.head === key) {
      group.head = group.siblings[1].key;
    }
    group.siblings = group.siblings.filter((sibling: collectionSibling) => !(sibling.key === key && sibling.id === id));

    // if the group is reduced to one product, we remove the group
    if (group.siblings.length === 1) {
      collection.groups = collection.groups.filter((g: collectionGroup) => g !== group);
      return;
    }
  });

}

export const productAttachedRight = (collection: Collection, collProduct: CollectionProduct) =>
  collection.groups.some((group: collectionGroup) => {
    const index = group.siblings.findIndex((sibling: collectionSibling) => sibling.key === collProductUniqueKey(collProduct) && sibling.id === collProduct.product.id);
    if (index > -1) {
      return(group.siblings[index + 1] !== undefined);
    }
    return false;
  });

export const productAttachedLeft = (collection: Collection, collProduct: CollectionProduct) =>
  collection.groups.some((group: collectionGroup) => {
    const index = group.siblings.findIndex((sibling: collectionSibling) => sibling.key === collProductUniqueKey(collProduct) && sibling.id === collProduct.product.id);
    if (index > -1) {
      return(group.siblings[index - 1] !== undefined);
    }
    return false;
  });

const groupProductsAdjacents = (collection: Collection) => collection.groups.every((group: collectionGroup) => {
  const groupProductPositions = group.siblings.map((sibling: collectionSibling) => indexInCollection(collection, sibling.key, sibling.id))
  // console.log("Group " + group.head + " has product positions " + groupProductPositions.join(', '));
  return(groupProductPositions.every((siblingIndex: number, siblingIndex_index: number, siblingIndexes: number[]) => {
    return siblingIndex_index === 0 || siblingIndex === siblingIndexes[siblingIndex_index - 1] + 1;
  }));
});


const rearrangedProductInGroups = (collection: Collection, cp: CollectionProduct) => {

  const id = cp.product.id;
  const key = collProductUniqueKey(cp)
  const productIndex = indexInCollection(collection, key, id);

  // console.log("\n%crearrangeProductInGroups for " + key + " (" + id + ") at new position " + productIndex, "color: purple");
  // console.log("Before removing the product from all groups");
  // dumpGroups(collection, "orange");

  // remove the product from all groups
  removeProductInGroups(collection, cp.product.id, collProductUniqueKey(cp));

  // console.log("After removing the product from all groups");
  dumpProducts("purple", collection.products);
  dumpGroups(collection, "purple");

  let connected = false;

  // try to connect to an existing group to the left of the new position
  collection.groups.reverse().every((group: collectionGroup, group_index: number) => {

    const groupFirstSiblingIndex = indexInCollection(collection, group.siblings[0].key, group.siblings[0].id);
    // console.log("Group " + group.head + " is at position " + groupFirstSiblingIndex);

    // check that this group is to the left of the new position
    if (groupFirstSiblingIndex > productIndex) {

      // console.log("Group " + group.head + " is to the right (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), skip it")
      return true;  // continue the search for suitable group

    } else {
      // console.log("Group " + group.head + " is to the left (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), check if it can be connected")

      // check if the product is connectable to the first sibling of this group
      const firstSibling = collection.products[groupFirstSiblingIndex];
      // console.log("First sibling of the group is " + firstSibling.product.title + " with connected products " + firstSibling.product.connectedProductIds.join(', '));

      if (firstSibling.product.connectedProductIds.includes(id) &&
        !group.siblings.some((sibling: collectionSibling) => sibling.id === id)) {
        // add the product to the group at the right position
        group.siblings.push({key: key, id: id});
        arrayMoveMutable(group.siblings, group.siblings.length - 1, productIndex - groupFirstSiblingIndex);
        if (productIndex > (groupFirstSiblingIndex + group.siblings.length - 1)) {
          arrayMoveMutable(collection.products, productIndex, groupFirstSiblingIndex + group.siblings.length - 1);
        }
        connected = true;
        return false; // stop the search for suitable group
      }
      // console.log("Cannot put in the group " + group.head)
      return true;  // continue the search for suitable group
    }
  });

  // reset in the right order
  collection.groups.reverse();

  if (!connected) {

    // console.log('Not connected to the left, trying to connect to the right')

    // try to connect to an existing group to the right of the new position
    collection.groups.every((group: collectionGroup, group_index: number) => {

      const groupFirstSiblingIndex = indexInCollection(collection, group.siblings[0].key, group.siblings[0].id);
      // console.log("Group " + group.head + " is at position " + groupFirstSiblingIndex);

      // check that this group is to the right of the new position
      if (groupFirstSiblingIndex <= productIndex) {

        // console.log("Group " + group.head + " is to the left (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), skip it")
        return true;  // continue the search for suitable group

      } else {
        // check if the product is connectable to the first sibling of this group

        // console.log("Group " + group.head + " is to the right (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), check it")
        const firstSibling = collection.products[groupFirstSiblingIndex];
        // console.log("First sibling of the group is " + firstSibling.product.title + " with connected products " + firstSibling.product.connectedProductIds.join(', '));

        if (firstSibling.product.connectedProductIds.includes(id) &&
          !group.siblings.some((sibling: collectionSibling) => sibling.id === id)) {

          // console.log("Product " + key + " (" + id + ") is connectable to the group " + group.head + " at position " + groupFirstSiblingIndex);

          // add the product to the group at the first position
          group.siblings.unshift({key: key, id: id});
          group.head = key;
          group.manual = false;

          // move all siblings next to the new head of group
          group.siblings.forEach((sibling: collectionSibling, sibling_index: number) => {
            if (sibling_index > 0) {
              const siblingGlobalIndex = indexInCollection(collection, sibling.key, sibling.id);

              // console.log("Before moving right sibling " + sibling_index + " from position " + siblingGlobalIndex + " to position " + (productIndex + sibling_index));

              // dumpProducts("darkred", collection.products);
              arrayMoveMutable(collection.products, siblingGlobalIndex, productIndex + sibling_index);

              // console.log("After moving");
              dumpProducts("darkred", collection.products);
            }
          });
          // console.log("Setting connected to true and exiting the loop");
          connected = true;
          return false; // stop the search for suitable group
        }
        // console.log("Cannot put in the group " + group.head)
        return true;  // continue the search for suitable group
      }
    });
  } else {
    // console.log("Connected to the left, exiting soon")
  }

  if (connected) {
    // console.log("After connecting the product to a group, sort the groups");

    collection.groups.sort((g1, g2) =>
      (groupPosition(collection.products, g1) > (groupPosition(collection.products, g2)) ? 1 : -1))

  }

  dumpGroups(collection, "darkred")

  return connected;
}

const connectCollectionSiblings = (collection: Collection) => {

  // console.log("%c\n\nStarting connectCollectionSiblings of collection " + collection.name, "color: navy");
  let coll_products = [...collection.products];

  let need_sorting = false;
  let nb_iterations = 0;    // just to avoid infinite loops

  do {

    // console.log("%c\n\nBefore sorting at iteration " + nb_iterations, "color: navy");
    dumpProducts("navy", coll_products);
    dumpGroups(collection, "navy");
    need_sorting = false;

    coll_products.every((cp: CollectionProduct, cp_index: number) => {

      // console.log("%c " + cp_index + " : " + cp.product.title + "(" + cp.product.id + ") connections " + cp.product.connectedProductIds.join(', '), "color: red, font-weight: bold");
      if (cp.product.connectedProductIds.length > 0) {

        const alreadyInGroup = belongsToGroup(collection, collProductUniqueKey(cp), cp.product.id);

        // if the product is in a group as a sibling, don't do anything with it
        // only search other siblings if the product is the head of the group
        if (alreadyInGroup && !(alreadyInGroup.head === collProductUniqueKey(cp))) {
          return true;
        }

        const nextCollProducts = coll_products.slice(cp_index + 1);
        const reOrderedProducts = [...nextCollProducts]

        let thisProductGroup: collectionGroup = alreadyInGroup || {} as collectionGroup;
        let nbSiblingsInGroup = alreadyInGroup ? (alreadyInGroup.siblings.length - 1) : 0;
        let nbSiblingsAdded = 0;

        const connectedProductsToSearch = cp.product.connectedProductIds.filter((sibling_pid: number) => (alreadyInGroup === undefined ||
          !alreadyInGroup.siblings.some((sib ) => (sib.id === sibling_pid))))

        if (connectedProductsToSearch.length > 0) {
          connectedProductsToSearch.forEach((sibling_pid: number, connectedIdx) => {
            // console.log("Looking for sibling " + connectedIdx + " with original id " + sibling_pid + " in " + reOrderedProducts.length + " products to the right")

            const cp_sibling_index = reOrderedProducts.findIndex((cp2: CollectionProduct) => (cp2.product.id === sibling_pid)
              && !belongsToGroup(collection, collProductUniqueKey(cp2), cp2.product.id));

            if (cp_sibling_index !== -1) {
              const groupedCollProduct = reOrderedProducts[cp_sibling_index];
              const new_key = collProductUniqueKey(groupedCollProduct)

              // console.log("Found sibling " + connectedIdx + " at reOrderedProducts position " + cp_sibling_index + " (" + new_key + ")");

              arrayMoveMutable(reOrderedProducts, cp_sibling_index, nbSiblingsInGroup);

              // console.log("%cAfter moving " + new_key + " to new reOrderedProducts position : " + nbSiblingsInGroup, "color: orange")
              dumpProducts("orange", reOrderedProducts);

              if (thisProductGroup.head === undefined) {
                thisProductGroup = {
                  manual: false,
                  head: collProductUniqueKey(cp),
                  siblings: [{
                    key: collProductUniqueKey(cp),
                    id: cp.product.id
                  },
                    {
                      key: new_key,
                      id: groupedCollProduct.product.id
                    }]
                }
                // console.log("Adding group item to new group " + thisProductGroup.head);
                collection.groups.push(thisProductGroup);
                dumpGroups(collection, "orange")
              } else {
                // console.log("Adding group item to existing group " + thisProductGroup.head);
                thisProductGroup.siblings.push({
                  key: new_key,
                  id: groupedCollProduct.product.id
                });
                dumpGroups(collection, "orange")

              }

              nbSiblingsInGroup++;
              nbSiblingsAdded++;
            }
          });

          if (thisProductGroup.head !== undefined && nbSiblingsAdded > 0) {
            coll_products = [...coll_products.slice(0, cp_index + 1).concat(reOrderedProducts)];

            // console.log("Group created, so exiting the loop after dumping groups & products");
            dumpGroups(collection, "green")
            dumpProducts("green", coll_products);
            need_sorting = true
            return false;
          } else {
            // console.log("No creation or modification of group, going to the next product");
            return true;
          }
        } else {
          // console.log("No connected products to search, going to the next product");
          return true;
        }

      } else {
        return true;
      }

    });

    nb_iterations++;

    if (nb_iterations > 8) {
      // console.log("%cconnectCollectionSiblings iteration " + nb_iterations, 'color: red');
    }

  } while (need_sorting && nb_iterations < 15);


  // console.log("%cAfter connectCollectionSiblings", "color: darkgreen");
  collection.products = coll_products;
  collection.groups.sort((g1, g2) =>
    (groupPosition(coll_products, g1) > (groupPosition(coll_products, g2)) ? 1 : -1))

  dumpProducts("darkgreen", coll_products)
  dumpGroups(collection, "darkgreen")
}

export interface BoutiqueInfos {
  id: number | null;
  logoId: number | null;
  name: string;
  subtitle: string;
  subdomain: string;
  acronym: string;
  color1: string;
  color2: string;
  collections: Collection[];
  customColors: string[] | undefined;
}

export const BoutiqueAllColors = (boutique: BoutiqueInfos) => ([boutique.color1, boutique.color2].concat(boutique.customColors || []));


export interface BoutiquesState {
  boutiques: BoutiqueInfos[];
  loaded: boolean;
  currentBoutique: BoutiqueInfos | null;
  currentProductConfig: CollectionProduct | null;
  hasChanges: boolean;
  error: boolean;
  subdomainTaken: boolean;
}

const initialState: BoutiquesState = {
  boutiques: [{
    id: null,
    logoId: null,
    name: "",
    subtitle: "",
    subdomain: "",
    acronym: "",
    color1: "",
    color2: "",
    collections: [],
    customColors: [],
  }],
  loaded: false,
  currentBoutique: null,
  currentProductConfig: null,
  hasChanges: false,
  error: false,
  subdomainTaken: false,
};

const updateHasChanges = (state: BoutiquesState) => {

  if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
    const currentBoutiqueId = state.currentBoutique.id;
    const boutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);
    const hasChanges = JSON.stringify(state.currentBoutique) !== JSON.stringify(state.boutiques[boutiqueIndex]);
    // console.log("updateHasChanges for boutique " + currentBoutiqueId + " => " + hasChanges)
    state.hasChanges = hasChanges;
  }
}

export const getBoutiques = createAsyncThunk(
  "boutique/getBoutiques",
  async (_, thunkAPI) => {

    const response = await fetchBoutiques();
    if (response.error) {
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Could not load boutique"}));

      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const saveBoutique = createAsyncThunk(
  "boutique/saveBoutique",
    async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    if (state.boutique.currentBoutique) {
      // remove not modifiable data from the products
      const boutiquePayload = {...state.boutique.currentBoutique,
        collections: state.boutique.currentBoutique.collections.map((collection: Collection) => (
            {...collection,
              products: collection.products.map((product: CollectionProduct, index) => (
                  {...product,
                    hasChanges: product.hasChanges,
                    margin: product.margin,
                    customTitle: product.customTitle,
                    position: index,
                    product: {...product.product,
                      description: "",
                      brand: "",
                      fabric: "",
                      imgScaleDown: 0,
                      gender: -1,
                      connection: '',
                      connectedProducts: [],
                      variants: [],
                      colorMatch: 0},
                    colors: product.colors.map((color: CollectionProductColor) => (
                        {...color,
                          colorImages: {...color.colorImages, images: []}
                        }
                    ))
                  }
              ))
            }))
      };

      // the backend will recalculate the custom colors (named color for the whole boutique)
      // based on the products persos colors
      let boutiqueInfos = {...boutiquePayload, customColors: undefined};

      // console.log("Before await updateBoutique " + JSON.stringify(boutiqueInfos));
      const response = await updateBoutique(boutiqueInfos);

      if (response.error) {
        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  }
);

const boutiqueWithSiblingInfo = (boutique: BoutiqueInfos) => {
  return {...boutique,
    collections: boutique.collections.map((collection: Collection) =>
      ({...collection,
        hasSiblings: collectionHasSiblings(collection.products)}))};
}

const storeAllBoutiques = (state: BoutiquesState, boutiques: BoutiqueInfos[]) => {
  state.boutiques = boutiques.map((boutique: BoutiqueInfos) => boutiqueWithSiblingInfo(boutique));

  if (boutiques.length > 0) {
    state.currentBoutique = boutiqueWithSiblingInfo(boutiques[0])   // siblingInfo is not yet stored in the state
  }
  state.error = false;
  state.loaded = true;

  // dumpProducts('green', boutiques[0].collections[0].products);
  // dumpGroups(boutiques[0].collections[0], 'green');
  // dumpProducts('green', boutiques[0].collections[1].products);
  // dumpGroups(boutiques[0].collections[1], 'green');

}

const storeBoutique = (state: BoutiquesState, boutique: BoutiqueInfos) => {

  // console.log("%cAfter saving boutique", "color:blue", boutique)
  if (boutique.id !== null) {
    const boutiqueIndex = state.boutiques.findIndex((b) => b.id === boutique.id);

    const boutiqueWithSiblingInfo = {...boutique,
      collections: boutique.collections.map((collection: Collection) =>
        ({...collection, hasSiblings: collectionHasSiblings(collection.products)}))};

    // console.log("%cboutiqueWithSiblingInfo", 'color:blue;', boutiqueWithSiblingInfo);

    state.boutiques[boutiqueIndex] = boutiqueWithSiblingInfo;
    state.currentBoutique = boutiqueWithSiblingInfo;
  }
  state.hasChanges = false;
}

export interface RequestPreviewColor {
  colorName: string;
  logoAddons: AddonInfos[];
}

export interface RequestPreviewProduct {
  id: number;
  productId: number | null;
  version: number;
  colors: RequestPreviewColor[];
}

export interface RequestPreviewData {
  boutiqueId: number,
  product: RequestPreviewProduct
}

interface previewImage {
  pagodeImageId:  number;
  boutiqueImageUrl: string;
}

export const RequestProductPreview = createAsyncThunk(
  "boutique/previewCollectionProduct",
  async (payload:{infos: RequestPreviewData}, thunkAPI) =>
  {
    const response = await previewBoutiqueProduct(payload.infos);

    if (response.error) {
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Could not make preview"}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: 'success', message: "Preview created"}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const checkSubdomainAvailability = createAsyncThunk(
  "boutique/checkSubdomainAvailability",
    async (payload: { id: number|null, subdomain: string }, thunkAPI) => {
    const response = await getSubdomainAvailability(payload);
    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }
  // The value we return becomes the `fulfilled` action payload
  return response;
  }
)

export const boutiqueSlice = createSlice({
  name: "boutique",
  initialState,
  reducers: {
    updateBoutiques: (state, action) => {
      // console.log("updateBoutiques", JSON.stringify(action.payload));
      storeAllBoutiques(state, action.payload);
    },
    clearBoutique: (state) => {
      return initialState
    },
    storeBoutiqueInfo: (state, action) => {
      // console.log("storeBoutiqueInfo", action.payload);
      if (state.currentBoutique !== null) {
        const propertyName = action.payload.field as keyof BoutiqueInfos;
        if (propertyName.startsWith('color')) {
          const colorNumber = parseInt(propertyName.substring(5));
          let previousColor = '';

          switch (colorNumber) {
            case 1:
              previousColor = state.currentBoutique.color1;
              state.currentBoutique.color1 = action.payload.value;
              break;
            case 2:
              previousColor = state.currentBoutique.color2;
              state.currentBoutique.color2 = action.payload.value;
              break;
            default:
              if (state.currentBoutique.customColors === undefined) {
                state.currentBoutique.customColors = [];
              } else {
                previousColor = state.currentBoutique.customColors[colorNumber - 3];
              }

              // update all persos using this color since it was changed globally
              state.currentBoutique.customColors[colorNumber - 3] = action.payload.value;
          }

          if (previousColor) {
            // console.log("previous color", previousColor);
            state.currentBoutique.collections.forEach((collection) => {
              collection.products.forEach((product) => {
                product.colors.forEach((color) => {
                  if (color.persoColor === previousColor) {
                    // console.log(product.product.title, " color ", color.colorImages.color, " Replacing color ", color.persoColor, " with ", action.payload.value);
                    color.persoColor = action.payload.value;
                  }
                });
                product.persos.forEach((perso) => {
                  perso.colorList = perso.colorList.map((color) => {
                    if (color === previousColor) {
                      // console.log(product.product.title, " perso ", perso.name, " Replacing color ", color, " with ", action.payload.value);
                      return(action.payload.value);
                    } else return color;
                    });
                });
              })
            });
          }

        } else {
          // console.log("storeBoutiqueInfo ", propertyName, action.payload.value);
          (state.currentBoutique[propertyName] as any) = action.payload.value;
        }
        updateHasChanges(state);
      }
    },
    storeCollectionInfo: (state, action) => {
      // console.log("storeCollectionInfo", JSON.stringify(action.payload));
      if (state.currentBoutique !== null) {
        const collectionId = action.payload.id as number;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        // console.log("collectionIndex", collectionIndex);
        if (collectionIndex !== -1) {
          (state.currentBoutique.collections[collectionIndex][action.payload.info.field as keyof Collection] as any) = action.payload.info.value;

          // console.log("stored collection info", action.payload.info.field, action.payload.info.value);
          if (action.payload.info.field === 'separate') {
            if (action.payload.info.value as boolean) {
              state.currentBoutique.collections[collectionIndex].groups = [];
            } else {
              connectCollectionSiblings(state.currentBoutique.collections[collectionIndex]);
            }
          }
          updateHasChanges(state);
        }
      }
    },
    addCollection: (state) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections.push(newCollection)
        updateHasChanges(state);
      }
    },
    deleteCollection: (state, action) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections = state.currentBoutique.collections.filter((collection) => collection.id !== action.payload.collectionId);
        updateHasChanges(state);
      }
    },
    resetBoutique: (state) => {
      let currentBoutiqueIndex = 0;
      if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
        const currentBoutiqueId = state.currentBoutique.id;
        currentBoutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);
      }
      storeBoutique(state, state.boutiques[currentBoutiqueIndex])
    },
    refreshImageUpdating: (state) => {
      let currentBoutiqueIndex = 0;
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections.forEach((collection) => {
          collection.products.forEach((product) => {
            product.imageUpdating = product.hasChanges;
          })
        });
      }
    },
    addProductToCollection: (state, action) => {

      if (state.currentBoutique !== null) {

        // console.log("boutiqueSlice addProductToCollection with payload " + JSON.stringify(action.payload));

        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        if (collectionIndex !== -1) {

          const allProductsToAdd = [{product: action.payload.product, colors: action.payload.colors}];
          action.payload.otherVersions.forEach((ov: {product:{},colors:{}}) => allProductsToAdd.push(
              {product: ov.product, colors: ov.colors}));

          // console.log("Add products to collection " + collectionId + " : " + allProductsToAdd.map((p) => p.title).join(', '));
          // allProductsToAdd.forEach((product, index) => {
          //   console.log("------------");
          //   console.log("%cProduct " + index + " : " + product.title, "color: red");
          //   console.log(JSON.stringify(product))
          // });

          const newCollection = {...state.currentBoutique.collections[collectionIndex]};

          const currentCollectionProducts = state.currentBoutique.collections[collectionIndex].products;

          allProductsToAdd.forEach((p_to_add) => {

            const {product, colors} = p_to_add;

            // adding version number to product in the collection
            const existing_versions = currentCollectionProducts
                .filter(p => p.product.id === product.id)
                .map(p => p.version);

            const new_version = existing_versions.length > 0 ? Math.max(...existing_versions) + 1 : 1;

            // console.log("Product " + product.title + " new_version => " + new_version);

            const persoBackgrounds = addonsBackgrounds(colors);

            const newCollectionProduct: CollectionProduct = {
              collectionId: collectionId,
              product: product,
              productId: null,
              version: new_version,
              persoAllowed: true,

              // activate all persos by default, then choosing the logos will remove the ones that are not allowed
              persos: AllPersoAddonsActivatedToDefault(colors),

              // by default logos are not activated
              colors: colors.map((color:string, colorIndex: number) => {
                // console.log("color ", colorIndex, " has addons on bg ", persoBackgrounds[colorIndex]);
                return ({
                  colorImages: color,
                  promote: false,
                  logoAddons: [],
                  persoColor: persoBackgrounds[colorIndex] === 'dark' ? 'white' : persoBackgrounds[colorIndex] === 'light' ? 'black' : '',
                });
              }),
              margin: 0,
              customTitle: null,
              hasChanges: false
            }

            newCollection.products.push(newCollectionProduct);

          });


          if (newCollection.separate) {
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          } else {

            connectCollectionSiblings(newCollection)

            // Products not rearranged in groups after adding the product, just commit the new collection with the new product and update hasSiblings
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);
          }

          // console.log('\n\n\nupdate the hasChanges state of the boutique !!!\n\n\n');
          updateHasChanges(state);
        }
      }
    },
    removeProductFromCollection: (state, action) => {

      if (state.currentBoutique !== null) {

        const collectionId = action.payload.collectionId;
        const id = action.payload.id;
        const version = action.payload.version;
        const productId = action.payload.productId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        if (collectionIndex !== -1) {

          const newCollection = {...state.currentBoutique.collections[collectionIndex]};

          newCollection.products = newCollection.products.filter((product) => {
              if (productId !== null) {
                return product.productId !== productId;
              } else {
                return product.product.id !== id || product.version !== version;
              }
            }
          );

          const cp_key = `${id}-${version}`;

          removeProductInGroups(newCollection, id, cp_key);
          connectCollectionSiblings(newCollection);

          state.currentBoutique.collections[collectionIndex] = newCollection;
          state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          updateHasChanges(state);
        }
      }
    },
    moveProducts: (state, action) => {
      // console.log("boutiqueSlice moveProducts because of " + JSON.stringify(action.payload.movingCollProduct));

      if (state.currentBoutique !== null) {
        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);

        if (collectionIndex !== -1) {

          const collection = state.currentBoutique.collections[collectionIndex]

          // the moved product might have its version number changed
          const movedCollProduct = action.payload.movingCollProduct;
          const updatedMovedCollProduct = {...action.payload.movingCollProduct};

          const oldCollectionId = action.payload.oldCollectionId;
          // console.log("boutiqueSlice moveProducts from collection " + oldCollectionId + " to collection " + collectionId);

          const newCollProducts = action.payload.collProducts.map((colP: CollectionProduct) => {

            let productVersion = colP.version;

            // if a new product is in the collection, fix the version number
            if (oldCollectionId !== collectionId && colP.collectionId !== collectionId) {
              // console.log("Moving the product to a new collection " + collectionId + ", fix the version number");
              const existing_versions = collection.products.
                filter(p => p.product.id === movedCollProduct.product.id).map(p => p.version);

              productVersion = existing_versions.length > 0 ? (Math.max(...existing_versions) + 1) : 1;

              // console.log("will assign new version " + productVersion + " to the product " + movedCollProduct.product.id);
              updatedMovedCollProduct.version = productVersion;
            }

            return ({...colP,
              collectionId: collectionId,
              version: productVersion
            });
          });

          dumpProducts('blue', newCollProducts);
          dumpGroups(collection, 'blue');

          const newCollection = {...collection, products: newCollProducts};

          if (newCollection.separate) {
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          } else {

            const connectedToGroup = rearrangedProductInGroups(newCollection, updatedMovedCollProduct);

            if (!groupProductsAdjacents(newCollection)) {
              // console.log("Groups are not connected anymore, don't apply the new product");
              return;
            }

            if (!connectedToGroup) {
              // the product is not in a group, try to add it to a group
              connectCollectionSiblings(newCollection)
            } else {
              // console.log("The product is connected to a group, don't try to connect the siblings")
            }
            // dumpProducts('purple', newCollProducts);
            // dumpGroups(collection, 'purple');

            // Products not rearranged in groups after adding the product, just commit the new collection with the new product and update hasSiblings
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          }

          // console.log("\nRemove the product from the old collection\n")

          // remove the product from old collection if the product changed collection
          if (oldCollectionId !== collectionId) {
            const oldCollectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === oldCollectionId);
            if (oldCollectionIndex !== -1) {
              const oldCollection = {...state.currentBoutique.collections[oldCollectionIndex]};
              oldCollection.products = oldCollection.products.filter((product) => {
                  return product.product.id !== movedCollProduct.product.id || product.version !== movedCollProduct.version;
                }
              );

              // console.log("Dump des produits de l'ancienne collection")
              dumpProducts('red', oldCollection.products);

              const cp_key = `${movedCollProduct.product.id}-${movedCollProduct.version}`;

              removeProductInGroups(oldCollection, movedCollProduct.product.id, cp_key);
              connectCollectionSiblings(oldCollection);

              // dumpProducts('purple', oldCollection.products);
              // dumpGroups(oldCollection, 'purple');

              state.currentBoutique.collections[oldCollectionIndex] = oldCollection;
              state.currentBoutique.collections[oldCollectionIndex].hasSiblings = collectionHasSiblings(oldCollection.products);
            }
          }

          // console.log('\n\n\nupdate the hasChanges state of the boutique !!!\n\n\n');
          updateHasChanges(state);
        }
      }
    },
    moveCollections: (state, action) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections = action.payload.collCollections;
        updateHasChanges(state);
      }
    },
    openProductConfig: (state, action) => {
      state.currentProductConfig = action.payload;
    },
    saveProductConfig: (state, action) => {
      // console.log("saveProductConfig " + JSON.stringify(action.payload));

      if (state.currentBoutique !== null) {
        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);

        if (collectionIndex !== -1) {

          const id = action.payload.id;
          const productId = action.payload.productId;
          const productIndex = state.currentBoutique.collections[collectionIndex].products.findIndex(
            (product) => {
              if (productId !== null) {
                return product.productId === productId;
              } else {
                return product.productId === null && product.product.id === id && product.version === action.payload.version;
              }
            }
          );

          if (productIndex !== -1) {
            const collectionProduct = state.currentBoutique.collections[collectionIndex].products[productIndex];

            collectionProduct.persoAllowed = action.payload.persoAllowed;
            collectionProduct.persos = action.payload.persos.filter((perso:PersoInfos) => perso.activated);
            collectionProduct.colors = action.payload.colors.map((color:CollectionProductColor, index:number) =>
                ({...color,
                  logoAddons: action.payload.colorLogoAddons[index],
                  persoColor: action.payload.persoColors[index]
                }));
            collectionProduct.margin = action.payload.margin;
            collectionProduct.customTitle = action.payload.customTitle;
            collectionProduct.hasChanges = true;

            state.currentBoutique.customColors = action.payload.boutiqueColors.slice(2);

            updateHasChanges(state);
            state.currentProductConfig = null;
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoutiques.pending, (state) => {
        // console.log("getSelection pending");
        // state.saving = true;
      })
      .addCase(getBoutiques.fulfilled, (state, action: any) => {
        console.log("getBoutiques fulfilled with " + action.payload.length + " boutiques");
        storeAllBoutiques(state, action.payload);
      })
      .addCase(getBoutiques.rejected, (state, action: any) => {
        // console.log("getSelection rejected");
        state.error = true;
      })
      .addCase(saveBoutique.fulfilled, (state, action: any) => {
        // console.log("saveBoutique fulfilled with " + JSON.stringify(action.payload));
        storeBoutique(state, action.payload);
      })
      .addCase(saveBoutique.pending, (state, action: any) => {
        // console.log("saveBoutique pending");
      })
      .addCase(saveBoutique.rejected, (state, action: any) => {
        // console.log("saveBoutique rejected with " + JSON.stringify(action.payload));
      })
      .addCase(RequestProductPreview.fulfilled, (state, action: any) => {
        // console.log("requestProductPreview fulfilled with " + JSON.stringify(action.payload));
        if (state.currentProductConfig !== null) {
          state.currentProductConfig.colors = state.currentProductConfig.colors.map((color, index) => (
            {
              ...color,
              colorImages: {
                ...color.colorImages,
                images: color.colorImages.images.map((image, imageIndex) => {
                  const boutiqueImageIndex = action.payload.findIndex((boutiqueImage:previewImage) =>
                    boutiqueImage['pagodeImageId'] === image.id);

                  if (boutiqueImageIndex === -1) {
                    return(image);
                  } else {
                    return ({
                      ...image,
                      boutiqueUrl: action.payload[boutiqueImageIndex]['boutiqueImageUrl']
                    });
                  }
                })
              }
            })
          );
        }
      })
      .addCase(RequestProductPreview.pending, (state, action: any) => {
        // console.log("requestProductPreview pending");
      })
      .addCase(RequestProductPreview.rejected, (state, action: any) => {
        // console.log("requestProductPreview rejected with " + JSON.stringify(action.payload));
      })
      .addCase(checkSubdomainAvailability.pending, (state) => {
        // console.log("checkSubdomainAvailability pending");
        state.subdomainTaken = false;
      })
      .addCase(checkSubdomainAvailability.fulfilled, (state, action: any) => {
        // console.log("checkSubdomainAvailability fulfilled with " + JSON.stringify(action.payload));
        state.subdomainTaken = action.payload.taken;
      })
      .addCase(checkSubdomainAvailability.rejected, (state, action: any) => {
        // console.log("checkSubdomainAvailability rejected");
        state.subdomainTaken = false;
      })
  }
});

export const {
  updateBoutiques,
  clearBoutique,
  storeBoutiqueInfo,
  storeCollectionInfo,
  addCollection,
  moveCollections,
  deleteCollection,
  resetBoutique,
  refreshImageUpdating,
  addProductToCollection,
  removeProductFromCollection,
  moveProducts,
  openProductConfig,
  saveProductConfig
} = boutiqueSlice.actions;

// export const selectionStateSelector = (state: RootState) => state.selection;

// returns an array of the boutiques and the collections that the product is in
// [
//   {"boutique":"Brebieres","collection":"Lifestyle","product":["rouge/blanc","royal/blanc"]},
//   {"boutique":"Brebieres","collection":"Sportswear","product":["royal/blanc","noir/jaune fluo"]},
//   {"boutique":"Brebieres","collection":"Accessoires","colors":["rouge/blanc"]}
// ]

export const productBoutiquesAndCollectionsSelector = (state: RootState, productId: number) =>
    state.boutique.boutiques.flatMap((boutique) =>
      boutique.collections.flatMap((collection: Collection) => {
        if (collection.products.some((cp: CollectionProduct) => cp.product.id === productId)) {
          // return collection.name;

          return collection.products.filter((cp: CollectionProduct) => cp.product.id === productId)
              .flatMap((cp: CollectionProduct) => (
                  {
                    boutique: boutique.name,
                    collection: collection.name,
                    colors: cp.colors.map((color: CollectionProductColor) => color.colorImages.color)
                  }))
        } else {
          return null;
        }
      }).filter(n => n)
    ).filter(n => n);

export default boutiqueSlice.reducer;










