import React, {useState, useEffect, useMemo} from 'react';
import {closestCenter, pointerWithin, DndContext} from '@dnd-kit/core';
import Box from "@mui/material/Box";
import ProductSelection from "../selection/ProductSelection";
import {Boutique} from "./Boutique";
import {AppDispatch, RootState} from "../../app/store";
import {useDispatch, useSelector} from "react-redux";
import {
  addProductToCollection, getBoutiques,
  storeBoutiqueInfo, updateBoutiques
} from "./boutiqueSlice";
import {openSnackBar} from "../global/globalSlice";
import {Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import LogoForm from "../logos/LogoForm";
import {
  editedLogoSelector,
  editLogo, getlogos, hasAutomaticLogoSelector,
  logosLoadedSelector,
  logosSelector,
  newLogo,
  setLogos
} from "../logos/logosSlice";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {FormattedMessage, useIntl} from "react-intl";
import LogosList from "../logos/LogosList";
import {CollectionProductConfig} from "./CollectionProductConfig";
import {useAppSelector} from "../../app/hooks";
import {cable} from "../../app/cable";
import {updateBoutique} from "../../app/boutiqueAPI";
import {getSelection, SelectedProduct, setSelectedProducts} from "../selection/selectionSlice";
import {getAccessToken} from "../../app/sessionAPI";
import {ROLE_BOUTIQUE} from "../sessions/sessionSlice";

export function Dashboard() {

  const intl = useIntl();
  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const userBoutique = currentUser && currentUser.role !== undefined &&
      (currentUser.role >= ROLE_BOUTIQUE || localStorage.getItem("iprsnt") === '1')
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch = useDispatch<AppDispatch>();
  const boutiquesLoaded = useAppSelector((state: RootState) => state.boutique.loaded);
  const currentBoutique = useSelector((state: RootState) => state.boutique.currentBoutique);

  ///////////////////////////////////////////////////////
  // Logos
  ///////////////////////////////////////////////////////
  const logos = useSelector(logosSelector);
  const hasAutomaticLogo = useSelector(hasAutomaticLogoSelector);
  const logosLoaded = useSelector(logosLoadedSelector);
  const editedLogo = useSelector(editedLogoSelector);


  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !logosLoaded) {
      // console.log("Dashboard useEffect dispatching getlogos because accessToken = ", accessToken)
      dispatch(getlogos())
    }

    if (accessToken && accessToken !== 'undefined' && !boutiquesLoaded) {
      console.log("Dashboard useEffect dispatching getBoutiques because accessToken = ", accessToken)
      dispatch(getBoutiques());
    }

  }, [dispatch, accessToken]);

  const subscribeToLogos = (current_user_id: number) => {
    cable.subscriptions.create(
      { channel: "LogosChannel" },
      { received: (payload:any) => {
          if (payload.user_id === current_user_id) {
            console.log("%cDashboard received logo broadcast with accessToken = " + payload.accessToken, "color: blue");
            if (payload.originAccessToken === accessToken) {
              console.log("%cDashboard received logo broadcast with own accessToken, not doing anything ...", "color: red");
            } else dispatch(setLogos(payload.logos));
          }
        }
      }
    );
  }

  const subscribeToBoutiques = (current_user_id: number) => {
    cable.subscriptions.create(
        { channel: "BoutiquesChannel" },
        { received: (payload:any) => {
            if (payload.user_id === current_user_id) {
              // console.log("%cDashboard received boutiques broadcast with accessToken = " + payload.accessToken, "color: blue");
              if (payload.originAccessToken === accessToken) {
                // console.log("%cDashboard received boutiques broadcast with own accessToken, not doing anything ...", "color: red");
              } else dispatch(updateBoutiques(payload.boutiques));
            }
          }
        }
    );
  }

  useEffect(() => {
    if (accessToken && currentUser && currentUser?.id !== undefined) {
      subscribeToLogos(currentUser.id);
      subscribeToBoutiques(currentUser.id);
      // subscribeToSelection(currentUser.id);
    }
  }, [currentUser]);


  const productConfigShown = useAppSelector((state) => state.boutique.currentProductConfig)

  const productConfigDialog = productConfigShown && currentBoutique && (
    <CollectionProductConfig show={true}
                             collectionProduct={productConfigShown}
                             logos={logos}
                             boutique={currentBoutique} />
  );

  function handleDragEnd(event:any) {
    const {over, active} = event;
    if (over) {
      switch (active.data.current.type) {
        case "product":
          dispatch(addProductToCollection({
            product: active.data.current.product,
            colors: active.data.current.colors,
            otherVersions: active.data.current.otherVersions,
            collectionId: over.data.current.collection_id
          }));

          break;

        case "logo":
          // console.log("Dropping logo " + JSON.stringify(active.data.current) + " on drop target " + JSON.stringify(over.data.current));
          dispatch(storeBoutiqueInfo({field: 'logoId', value: active.data.current.logo.id}))
          break;


        default:
          console.log("Dropping " + JSON.stringify(active.data.current) + " on drop target " + JSON.stringify(over.data.current));
      }
    }
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  if (currentUser === undefined || currentUser.id === undefined) {
    return (<div/>);
  }

  if (!userBoutique) {
    return (
      <Typography variant='h5' sx={{textAlign: 'center', marginTop: '20px'}}>
        <FormattedMessage id="dashboard.not-opened" />
      </Typography>
    )
  }

  return(
    <DndContext collisionDetection={pointerWithin} onDragEnd={handleDragEnd}>
      <div className='catalog'>
        <Box sx={{ display: 'flex', p: 1, borderRadius: 1 }} >
          <Box sx={{p: 1, m: 0, zIndex: 1}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={intl.formatMessage({ id: "selection.menu"})} {...a11yProps(0)} />
                <Tab label={intl.formatMessage({ id: "logos.menu"})}  {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ProductSelection title={false} catalogView={false} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LogosList logos={logos} />
              {!!editedLogo || ( !hasAutomaticLogo &&
				          <div className='add-new-logo'>
					          <IconButton sx={{fontSize:'1.2rem',color: '#A0A0A0'}} onClick={() => dispatch(editLogo(newLogo))}>
						          <AddIcon />
						          <FormattedMessage id="logos.add-new" />
					          </IconButton>
				          </div>)}
            </TabPanel>
          </Box>
          <Box sx={{flexGrow: 1, p: 1, mr: 1}}>
            {(editedLogo && <LogoForm />) || <Boutique />}
          </Box>
        </Box>
      </div>
      {productConfigDialog}
    </DndContext>
  )
}
