import React from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {
  currentBrand,
  selectBrand, selectPrimaryColor, selectSecondaryColor,
} from "./catalogSlice";
import {ProductBrands} from "../../app/catalog";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText, ListSubheader} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useIntl} from "react-intl";



export const BrandMenuItem = (brand:string, index: number) => {

  const categoryDelimiter = (brand: string) => {
    switch(brand) {
      case 'Eldera':
        return <ListSubheader className='brand-delimiter'>Equipementiers</ListSubheader>

      case 'Asquith & Fox':
        return <ListSubheader className='brand-delimiter'>Général</ListSubheader>

      case 'DAGOBA':
        return <ListSubheader className='brand-delimiter'>Technique</ListSubheader>

      case 'BagBase':
        return <ListSubheader className='brand-delimiter'>Accessoires</ListSubheader>

    }
  }

  const elt = []
  elt.push(categoryDelimiter(brand))
  elt.push(<MenuItem key={`brand${index+1}`} value={brand}>{brand}</MenuItem>)
  return elt
}


export function SelectBrand() {

  const intl = useIntl();
  const brandSelected = useAppSelector(currentBrand);
  const dispatch = useDispatch();

  const changeBrand = (event: SelectChangeEvent) => {
    dispatch(selectBrand(event.target.value))
  };

  return (
    <FormControl sx={{ ml: 1, minWidth: 100, maxWidth: 110, fontSize: 13 }} size="small" className='select-brand select-tool'>
      <FormHelperText className={`title-select ${brandSelected !== 'all' ? 'active' : ''}`} sx={{ml:0}}>
        {intl.formatMessage({id: 'catalog.filter-by-brand'})}
      </FormHelperText>
      <Select className={`select-brand ${brandSelected !== 'all' ? 'active' : ''}`} value={brandSelected} onChange={changeBrand}>
        <MenuItem key='brand-all' value='all'>{intl.formatMessage({id: 'catalog.brand-all'})}</MenuItem>
        {ProductBrands.map((brand,i) => BrandMenuItem(brand, i))}
      </Select>
    </FormControl>
  )

}
