import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {
  currentStatusIdSelector,
  currentWorkshopSelector,
  IWorkshopStatus,
  productionOrdersSelector, selectStatus
} from "./productionSlice";

const ProductionStatuses = () => {
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const currentStatusId = useSelector(currentStatusIdSelector);
  const productionOrders = useSelector(productionOrdersSelector);
  const dispatch = useDispatch();

  const statusCard = (workshopStatus: IWorkshopStatus) => {
    if (productionOrders) {
      // Sum the number of articles with the statusId of the current status
      const nbArticles = productionOrders.reduce((nb, order) => (
        nb + order.items
          .filter((item) => item.statusId === workshopStatus.statusId)
          .reduce((nb, item) => (nb + item.quantity), 0)
      ), 0)

      const borderColor = currentStatusId === workshopStatus.statusId ? '#A0A0A0' : 'white'

      return (
        <Box key={`prod-status-${workshopStatus.statusId}`} className='status'
            style={{
              backgroundColor: workshopStatus.bgColor,
              color: workshopStatus.fgColor,
              border: `5px solid ${borderColor}`,
              opacity: nbArticles > 0 ? 1 : 0.2
            }}
            onClick={() => dispatch(selectStatus(workshopStatus.statusId))}>
          { nbArticles > 0 && <Box>{nbArticles}</Box>}
          <Box className='title'>
            {workshopStatus.statusName}
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box>
      {currentWorkshop && currentWorkshop.statuses.length > 0 &&
        <Box className="statuses">
          {currentWorkshop.statuses.map((status) => statusCard(status))}
        </Box>
      }
    </Box>
  );
}

export default ProductionStatuses;

