import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  cancelEmailChangeRequest,
  confirmPasswordToken,
  confirmUserEmail, createUser,
  getCurrentUser,
  loginWithEmailAndPassword, loginWithToken, logoutUserOnServer,
  removeSessionTokens,
  requestMagicLink, requestNewConfirmation, requestPasswordLink, storeSessionTokens,
  updateUserProfile
} from "../../app/sessionAPI";
import {RootState, AppThunk} from "../../app/store";
import {openSnackBar} from "../global/globalSlice";
import {FormattedMessage} from "react-intl";
import {SelectedProductPayload} from "../selection/selectionSlice";

export const ROLE_BOUDIST = 0
export const ROLE_BOUTIQUE = 5

export const ROLE_ADMIN = 10

export interface User {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: number;
  production?: boolean;
  locale?: string;
  color1?: string;
  color2?: string;
  hasPassword?: boolean;
  createdAt?: string;
  confirmedAt?: string;
  unconfirmed_email?: string;
  updatedAt?: string;
  iprsnt?: boolean;
}

export interface UserLoginData {
  email: string;
  password: string;
}

export interface IProfileUpdate {
  firstName?: string;
  lastName?: string;
  email?: string;
  locale?: string;
  color1?: string;
  color2?: string;
  password?: string;
  needPassword?: boolean;
  currentPassword?: string;
}

export interface UserConfirmEmailData {
  token: string;
}

interface AuthState {
  currentUser?: User;
  loading: boolean;
  waiting: boolean;
  confirming: boolean;
  error: boolean;
  errorMessages: string[];
  successMessage: string;
  expiresIn?: number;
  expiresAt?: number;
  tokenType?: string;
  currentRoute?: string;
  showingSpeedySignup: boolean;
  signUpPayload?: SelectedProductPayload;
}

const initialState: AuthState = {
  loading: false,
  waiting: false,
  confirming: false,
  error: false,
  errorMessages: [],
  successMessage: "",
  expiresIn: undefined,
  tokenType: undefined,
  showingSpeedySignup: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export interface ISignupPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  locale: string;
  color1: string;
  color2: string;
  selection?: SelectedProductPayload;
}


////////////////////////////////////////////////////////////////////////////////////////////////////
// Signup
////////////////////////////////////////////////////////////////////////////////////////////////////
export const signUpUser = createAsyncThunk(
  "session/signUpUser",
  async (payload: ISignupPayload, thunkAPI) => {
    const response = await createUser(payload);
    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Login by email & password
////////////////////////////////////////////////////////////////////////////////////////////////////
export const loginUserByEmail = createAsyncThunk(
  "session/loginUserByEmail",
  async (payload: UserLoginData, {rejectWithValue}) => {
    const loginResponse = await loginWithEmailAndPassword(
      payload.email,
      payload.password
    );
    if (loginResponse.access.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(loginResponse.access);
    }

    // console.log("loginUserByEmail with loginResponse = " + JSON.stringify(loginResponse));
    storeSessionTokens(loginResponse.access.access_token, loginResponse.access.refresh_token);
    if (loginResponse.iprsnt) {
      localStorage.setItem("iprsnt", '1')
    }

    const userResponse = await getCurrentUser();
    if (userResponse.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(userResponse.data);
    }
    const response = {
      ...loginResponse.access,
      ...userResponse,
    };
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Reload user
////////////////////////////////////////////////////////////////////////////////////////////////////

export const reloadUser = createAsyncThunk(
  "session/reloadUser",
  async (_, {rejectWithValue}) => {
    const response = await getCurrentUser();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Update profile
////////////////////////////////////////////////////////////////////////////////////////////////////

export const updateProfile = createAsyncThunk(
  "session/updateProfile",
  async (profileUpdate: IProfileUpdate, {rejectWithValue}) => {
    const response = await updateUserProfile(profileUpdate);

    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Magic link
////////////////////////////////////////////////////////////////////////////////////////////////////

export const sendMagicLink = createAsyncThunk(
  "session/sendMagicLink",
  async (payload: {email: string, locale: string}, {rejectWithValue}) => {
    const response = await requestMagicLink(payload.email, payload.locale);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const loginUserByToken = createAsyncThunk(
  "session/loginUserByToken",
  async (token: string, {rejectWithValue}) => {
    const loginResponse = await loginWithToken( token);
    if (loginResponse.access.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(loginResponse.access);
    }

    console.log("loginUserByToken with loginResponse = " + JSON.stringify(loginResponse));
    storeSessionTokens(loginResponse.access.access_token, loginResponse.access.refresh_token);

    const userResponse = await getCurrentUser();
    if (userResponse.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(userResponse.data);
    }
    const response = {
      ...loginResponse.access,
      ...userResponse,
    };
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Confirmations
////////////////////////////////////////////////////////////////////////////////////////////////////

export const newConfirmEmailLink = createAsyncThunk(
  "session/newConfirmEmailLink",
  async (_, thunkAPI) => {
    const response = await requestNewConfirmation();

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const confirmEmail = createAsyncThunk(
  "session/confirmEmail",
  async (token: string, thunkAPI) => {
    const response = await confirmUserEmail(token);

    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: "success", message: <FormattedMessage id="session.email-confirmed" />}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const cancelEmailChange = createAsyncThunk(
  "session/cancelEmailChange",
  async (_, thunkAPI) => {
    const response = await cancelEmailChangeRequest();

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


////////////////////////////////////////////////////////////////////////////////////////////////////
// Password reset
////////////////////////////////////////////////////////////////////////////////////////////////////

export const sendPasswordLink = createAsyncThunk(
  "session/sendPasswordLink",
  async (payload: {email: string, locale: string | undefined}, {rejectWithValue}) => {
    const response = await requestPasswordLink(payload.email, payload.locale);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const confirmPassword = createAsyncThunk(
  "session/confirmPassword",
  async (token: string, thunkAPI) => {
    const response = await confirmPasswordToken(token);

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // thunkAPI.dispatch(openSnackBar({severity: "success", message: <FormattedMessage id="session.email-confirmed" />}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Logout
////////////////////////////////////////////////////////////////////////////////////////////////////

export const logoutUser = createAsyncThunk(
  "session/logoutUser",
  async (_, {rejectWithValue}) => {
    const response = await logoutUserOnServer();
    // if response has errors rejectwithvalue
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

const setSpeedySignupClosed = (state: AuthState) => {
  state.showingSpeedySignup = false;
  state.error = false;
  state.waiting = false;
  state.errorMessages = [];
}

const setCurrentUser = (state: AuthState, payload: any) => {
  // console.log("setCurrentUser with payload = ", JSON.stringify(payload));
  state.currentUser = {
    id: payload.id,
    firstName: payload.first_name,
    lastName: payload.last_name,
    email: payload.email,
    role: payload.role,
    production: payload.production,
    locale: payload.locale,
    color1: payload.color1,
    color2: payload.color2,
    hasPassword: payload.has_password,
    createdAt: payload.created_at,
    confirmedAt: payload.confirmed_at,
    unconfirmed_email: payload.unconfirmed_email,
    updatedAt: payload.updated_at,
    iprsnt: payload.iprsnt,
  } as User;
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    openSpeedySignup: (state, action ) => {
      state.signUpPayload = action.payload;
      state.showingSpeedySignup = true;
      state.error = false;
      state.waiting = false;
      state.errorMessages = [];
    },
    closeSpeedySignup: (state) => {
      setSpeedySignupClosed(state);
    },
    resetErrorState: (state) => {
      state.error = false;
      state.errorMessages = [];
    }
  },
  extraReducers: (builder) => {
    builder
////////////////////////////////////////////////////////////////////////////////////////////////////
// Signup
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(signUpUser.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(signUpUser.fulfilled, (state, action: any) => {
        setSpeedySignupClosed(state);
        state.tokenType = action.payload.boudist.token_type;
        setCurrentUser(state, action.payload.boudist);
        storeSessionTokens(action.payload.boudist.access_token, action.payload.boudist.refresh_token);

      })
      .addCase(signUpUser.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Login by email & password
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(loginUserByEmail.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByEmail.fulfilled, (state, action: any) => {
        // console.log("loginUserByEmail.fulfilled with payload = ", JSON.stringify(action.payload));
        state.expiresIn = action.payload.expires_in;
        setCurrentUser(state, action.payload)
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByEmail.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["invalid-credentials"];
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Reload user
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(reloadUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(reloadUser.fulfilled, (state, action: any) => {
        setCurrentUser(state, action.payload)

        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(reloadUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = ["bad-token"];
        removeSessionTokens();
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Update profile
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(updateProfile.pending, (state) => {
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateProfile.fulfilled, (state, action: any) => {
        state.tokenType = action.payload.token_type;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateProfile.rejected, (state, action: any) => {
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Magic link
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(sendMagicLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
        state.successMessage = "";
      })
      .addCase(sendMagicLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
        state.successMessage = "A connection link has been sent to you. Please check your inbox"
      })
      .addCase(sendMagicLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["email-not-found"];
        state.successMessage = "";
      })
      .addCase(loginUserByToken.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByToken.fulfilled, (state, action: any) => {
        // console.log("loginUserByToken.fulfilled with payload = ", JSON.stringify(action.payload));
        state.expiresIn = action.payload.expires_in;
        setCurrentUser(state, action.payload)
        // storeSessionTokens(action.payload.access_token, action.payload.refresh_token);

        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByToken.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["invalid-token"];
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// Confirmations
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(newConfirmEmailLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(newConfirmEmailLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(newConfirmEmailLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = action.payload.error;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.confirming = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(confirmEmail.fulfilled, (state, action: any) => {
        state.confirming = false;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(confirmEmail.rejected, (state, action: any) => {
        state.confirming = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(cancelEmailChange.pending, (state) => {
        state.confirming = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(cancelEmailChange.fulfilled, (state, action: any) => {
        state.confirming = false;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(cancelEmailChange.rejected, (state, action: any) => {
        state.confirming = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// Password reset
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(sendPasswordLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(sendPasswordLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
      })
      .addCase(sendPasswordLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["email-not-found"];
      })
      .addCase(confirmPassword.pending, (state) => {
        state.confirming = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(confirmPassword.fulfilled, (state, action: any) => {
        state.confirming = false;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(confirmPassword.rejected, (state, action: any) => {
        state.confirming = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Logout
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(logoutUser.fulfilled, (state, action: any) => {
        state.currentUser = {
          id: undefined,
          email: undefined,
          role: undefined,
          production: undefined,
          hasPassword: undefined,
          createdAt: undefined,
          confirmedAt: undefined,
          updatedAt: undefined,
        };
        state.tokenType = undefined;
        removeSessionTokens();

        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(logoutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })
    ;
  },
});

export const {resetErrorState, openSpeedySignup, closeSpeedySignup} = sessionSlice.actions;

export const speedySignupShowing = (state: RootState) => state.session.showingSpeedySignup;
export const signUpPayload = (state: RootState) => state.session.signUpPayload;

export default sessionSlice.reducer;

export const currentUserSelector = (state: RootState) => state.session.currentUser;
