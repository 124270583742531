
export const redStyle = "color: red;";
export const greenStyle = "color: green;";
export const blueStyle = "color: blue;";
export const orangeStyle = "color: orange;";
export const purpleStyle = "color: purple;";
export const boldStyle = "font-weight: bold;";
//   console.log("%cFinal for " + product.sku + " " +  variant.friendlyColor + " total score " + score, redStyle);

export function arrayMoveMutable(array: Array<any>, fromIndex:number, toIndex:number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array: Array<any>, fromIndex:number, toIndex:number) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export function changedProperty(oldObj: any, newObj: any) {
  const changed = [];
  for (const prop in oldObj) {
    if (oldObj[prop] !== newObj[prop]) {
      return prop;
    }
  }
  return null;
}

export function changedProperties(oldObj: any, newObj: any) {
  const changed = [];
  for (const prop in oldObj) {
    if (oldObj[prop] !== newObj[prop]) {
      changed.push(prop);
    }
  }
  return changed;
}


export function getFirstAndLastNElements(array: any[], N: number, middleValue: string): any[] {
  const uniqueArray = Array.from(new Set(array)); // Remove duplicates

  if (uniqueArray.length <= 2 * N) {
    return uniqueArray; // Return the same array if size is less than 2 * N
  }

  const firstElements = uniqueArray.slice(0, N);
  const lastElements = uniqueArray.slice(-N);

  if (uniqueArray.length > 2 * N) {
    return firstElements.concat(middleValue, lastElements);
  }

  return firstElements.concat(lastElements);
}

export const getBase64 = (file: File) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

const hexToRgb = (hex: string)=> {
  // Remove the hashtag if it exists
  hex = hex.replace(/^#/, '');

  // Convert the hexadecimal color to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r}, ${g}, ${b})`;
}

export const darkenColor = (hexColor: string, factor: number) => {
  const rgbColor = hexToRgb(hexColor);

  // Parse the RGB values
  const match = /rgb\((\d+), (\d+), (\d+)\)/.exec(rgbColor);
  if (!match) {
    throw new Error('Invalid color format');
  }

  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);

  // Calculate the darker color by reducing each RGB component
  const newR = Math.max(0, r * factor);
  const newG = Math.max(0, g * factor);
  const newB = Math.max(0, b * factor);

  // Return the new color in the RGB format
  return `rgb(${newR}, ${newG}, ${newB})`;
}
