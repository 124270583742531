import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {
  currentWorkshopSelector,
  deliveryItemsSelector,
  IDeliveryItem,
  ISelectedDelivery,
  IWorkshopDelivery,
} from "./productionSlice";
import {Grid} from "@mui/material";

const ProductionDeliveries = () => {
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const deliveryItems = useSelector(deliveryItemsSelector);

  const [currentDeliveryCode, setCurrentDeliveryCode] = useState<string | undefined>(undefined);

  const currentDelivery = currentWorkshop?.deliveries.find((delivery) => delivery.deliveryCode === currentDeliveryCode)
  const displayedDeliveryItems = deliveryItems?.find((delivery) => delivery.code === currentDeliveryCode)


  useEffect(() => {
    if (currentWorkshop && currentWorkshop.deliveries && !currentDeliveryCode) {

      // find the first delivery with no deliveredAt date
      const undeliveredDelivery = currentWorkshop.deliveries
        .find((delivery) => !delivery.deliveredAt)

      setCurrentDeliveryCode(undeliveredDelivery?.deliveryCode || deliveryItems.slice(-1)[0].code)
    }
  }, [currentWorkshop, deliveryItems, currentDeliveryCode]);


  const colorForDelivery = (delivery: IWorkshopDelivery) => delivery.deliveredAt ? '#C0F0C0' : '#E0E0E0';

  const deliveryItem = (item: IDeliveryItem, index:number) => {
    return(
      <Grid item xs={12} sm={6}>
        <Box className='delivery-item' key={`delivery-item-${index}`}>
          <Box className='infos'>
            <Box className='title' sx={{textWrap:'nowrap'}}>
              {item.quantity > 1 && <span className='qty'>{item.quantity} x </span>}
              {item.title}
            </Box>
            <Box className='size'>Taille: {item.size}</Box>
            <Box className='color'>{item.color}</Box>
            <Box className='brand'>{item.brand}</Box>
            <Box className='sku'>{item.sku}</Box>
          </Box>
          <Box className='photo'>
            <img src={item.photo} />
            <Box className='orders'>
              {item.order}
            </Box>
          </Box>
          <Box className='qr-code'>
            <img src={item.qrCode} />
            <Box className='orders'>
              {item.order}
            </Box>
          </Box>
        </Box>
      </Grid>
    )
  }

  const deliveryCard = (delivery: IWorkshopDelivery) => {
    const items = deliveryItems
      .find((deliveryWithItems) => deliveryWithItems.code === delivery.deliveryCode);

    const nbItems = items?.nbItems || 0;
    const borderColor = currentDeliveryCode === delivery.deliveryCode ? '#A0A0A0' : 'white'

    return (
      <Box key={`delivery-${delivery.deliveryCode}`} className='delivery'
          style={{
            backgroundColor: colorForDelivery(delivery),
            border: `5px solid ${borderColor}`
          }}
          onClick={() => setCurrentDeliveryCode(delivery.deliveryCode)}
      >
        <Box>{nbItems}</Box>
        <Box className='supplier'>
          {delivery.supplier}
        </Box>
        <Box className='date'>
          {new Date(delivery.procureDate).toLocaleString('fr', {
            month: "short", day: "numeric",
            hour: "numeric", minute: "numeric"
          })}
        </Box>
      </Box>
    )
  }

  return(
      <Box className='production-deliveries'>
        { currentWorkshop &&
          <Box className="deliveries">
            {currentWorkshop.deliveries.map((delivery) => deliveryCard(delivery))}
          </Box>
        }
        {currentDeliveryCode && currentDelivery && displayedDeliveryItems &&
          <Box className='delivery-items'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className='delivery-title'
                     style={{backgroundColor: colorForDelivery(currentDelivery)}}>
                  <Box className='procure-date'>
                    {new Date(currentDelivery.procureDate).toLocaleString('fr', {
                      month: "short", day: "numeric",
                      hour: "numeric", minute: "numeric"
                    })}
                  </Box>
                  <Box className='supplier'>
                    {currentDelivery.supplier}
                  </Box>
                  <Box className='procure-status'>
                    <Box className='status'>
                      {currentDelivery.status || 'Non livré'}
                    </Box>
                    <Box className='procure-date'>
                      {currentDelivery.deliveredAt && new Date(currentDelivery.deliveredAt).toLocaleString('fr', {
                        month: "short", day: "numeric",
                        hour: "numeric", minute: "numeric"
                      })}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {displayedDeliveryItems.items.map((item, i) => deliveryItem(item, i))
              }
            </Grid>
          </Box>
        }
      </Box>
    )
}

export default ProductionDeliveries;

