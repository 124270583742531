import React, {useState} from 'react';
import {ILogoInfos, LogoVersion} from "../logos/logosSlice";
import {ImageAddon} from "../selection/selectionSlice";
import {AddonInfos, PersoAddonPrice} from "./boutiqueSlice";
import {FormattedMessage, FormattedNumber} from "react-intl";
import SelectAddonLogo from "./SelectAddonLogo";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useDroppable} from "@dnd-kit/core";

export default function BoutiqueLogo(props: {logoId: Number | null}) {

  const logos = useSelector((state : RootState) => state.logos.allLogos);
  const logo = props.logoId ? logos.find((l) => l.id === props.logoId) : null;

  const {isOver: isOverLogo, setNodeRef: setNodeRefDropLogo} = useDroppable({
    id: 'boutique-logo',
    data: {
      accepts: 'logo'
    }
  });

  const styleDropLogo = {
    border: isOverLogo ? '1px #80A080 solid' : '',
  };

  return (
    <div className={`logo ${logo && logo.for_dark_bg >= 1 ? 'dark-bg' : ''}`}
         ref={setNodeRefDropLogo} style={styleDropLogo}>
      {
        ((logo && logo.logo_url !== null && logo.logo_url !== '') &&
						<img src={logo.logo_url} alt={logo.name} width='64'/>) ||
					<div className="no-logo">
						<div className="icon">
							<ImageSearchIcon/>
						</div>
					</div>
      }
    </div>
  );

}
