import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {deleteLogo, getBoudistLogos, updateLogo} from "../../app/logosAPI";
import {updateBoutique} from "../../app/boutiqueAPI";
import {openSnackBar} from "../global/globalSlice";
import {BoutiqueInfos, BoutiquesState, saveBoutique} from "../dashboard/boutiqueSlice";
import {useAppSelector} from "../../app/hooks";
import {FormattedMessage} from "react-intl";


export interface LogoVersion {
  id: number;
  name: string;
  width: number;
  height: number;
  sizeCode: number;
  priceCents: number;
}

export const logoUsageExplanation = (logo: ILogoInfos) =>
  logo.usage.map((boutiqueUsage) =>
    `<h3>${boutiqueUsage.boutique}</h3>${boutiqueUsage.collections
      .map((collectionUsage) =>
        `<h4>${collectionUsage.collection}</h4>${collectionUsage.products
          .map((productUsage) =>
            `<h5>${productUsage.product}</h5>${Array.from(new Set(productUsage.colors // remove duplicates
              .map((colorUsage) =>
                `<h6>${colorUsage.color}</h6>`
              ))).join('')}`
          ).join('')}`
      ).join('')}`
  ).join('');

export const logoUsageExplanationHTML = (logo: ILogoInfos) =>
    logoUsageExplanation(logo).replace(/\n/g, '<br/>')

// returns the list of product and colors that use that logo version with the logo version id
export const logoVersionUsageProducts = (logo: ILogoInfos, logoVersionId: number) =>
    logo.usage.flatMap((boutiqueUsage) => boutiqueUsage.collections
        .flatMap((collectionUsage) => collectionUsage.products
            .filter((productUsage) => productUsage.colors
                .some((colorUsage) => colorUsage.logoVersionId === logoVersionId))
            .flatMap((productUsage) => (
              {
                product: productUsage.product,
                colors: productUsage.colors
                    .filter((colorUsage) => colorUsage.logoVersionId === logoVersionId)
                    .map((colorUsage) => colorUsage.color)
              })
            ))).map((pc) => `<li>${pc.product} (${pc.colors.join(', ')})</li>`).join('')

// const resulat = [
//   {"product": "T-Shirt Action", "colors": ["Rouge/Blanc", "Noir/Jaune Fluo"]},
//   {"product": "Veste BR11", "colors": ["Rouge/Blanc"]},
//   {"product": "Débardeur Racing", "colors": ["Rouge", "Émeraude"]},
//   {"product": "Ensemble Atlanta","colors": ["Rouge/Blanc"]
// }]

export interface IColorLogoUsage {
  color: string;
  logoVersionId: number;
}
export interface IProductLogoUsage {
  product: string;
  colors: IColorLogoUsage[];
}

export interface ICollectionLogoUsage {
  collection: string;
  products: IProductLogoUsage[];
}
export interface IBoutiqueLogoUsage {
  boutique: string;
  collections: ICollectionLogoUsage[];
}
export interface ILogoInfos {
  id: number;
  name:string;
  automated: boolean;
  for_dark_bg: number;
  logo_url: string;
  file: File | string | null;
  versions: LogoVersion[];
  usage: IBoutiqueLogoUsage[];
  boutiques: number[];
}

export const NEW_LOGO_NAME = '???';

export const newLogo = {
  id: 0,
  name: NEW_LOGO_NAME,
  automated: false,
  for_dark_bg: 0,
  logo_url: '',
  file: null,
  versions: [],
  boutiques: [],
}

export interface LogosState {
  allLogos: ILogoInfos[];
  loaded: boolean;
  editedLogo: ILogoInfos | null;
  hasChanges: boolean;
  imageChanged: boolean;
  saving: boolean;
  error: boolean;
}

const initialState: LogosState = {
  allLogos: [],
  loaded: false,
  editedLogo: null,
  hasChanges: false,
  imageChanged: false,
  saving: false,
  error: false
};


export const getlogos = createAsyncThunk(
  "logos/getLogos",
  async (_, {rejectWithValue}) => {

    const response = await getBoudistLogos();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const saveLogo = createAsyncThunk(
  "logos/saveLogo",
  async (payload: ILogoInfos, thunkAPI) => {

    const response = await updateLogo(payload);

    if (response.error) {
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: <FormattedMessage id="logo.not-saved" />}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: 'success', message: <FormattedMessage id="logo.saved" />}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }

);

export const removeLogo = createAsyncThunk(
  "logos/removeLogo",
  async (payload: number, thunkAPI) => {

    const response = await deleteLogo(payload);

    if (response.error) {
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: <FormattedMessage id="logo.not-deleted" />}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: 'success', message: <FormattedMessage id="logo.deleted" />}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

const updateHasChanges = (state: LogosState) => {

  if (state.editedLogo !== null && state.editedLogo.id !== null) {
    const currentLogoId = state.editedLogo.id;
    const logoIndex = state.allLogos.findIndex((l) => l.id === currentLogoId);

    if (logoIndex !== -1) {
      // console.log('%cComparing edited logo to logo index ' + logoIndex, 'color:white; background-color:black');
      state.hasChanges = JSON.stringify(state.editedLogo) !== JSON.stringify(state.allLogos[logoIndex]);
      state.imageChanged = state.editedLogo.logo_url !== state.allLogos[logoIndex].logo_url;
    } else {
      // console.log('%cSetting hasChanges to false because ' + JSON.stringify(state.editedLogo), 'color:white; background-color:black');
      state.hasChanges = true;
      state.imageChanged = true;
    }
  } else {
    // console.log('%cSetting hasChanges to false because ' + JSON.stringify(state.editedLogo), 'color:white; background-color:black');
    state.hasChanges = false;
    state.imageChanged = false;
  }
}

const storeLogo = (state: LogosState, logo: ILogoInfos) => {

  // console.log('In logosSlice, store logo', JSON.stringify(logo));
  if (logo.id !== null) {

    state.editedLogo = logo;

    const logoIndex = state.allLogos.findIndex((l) => l.id === logo.id);
    if (logoIndex !== -1) {
      state.allLogos[logoIndex] = logo;
    } else {
      state.allLogos.push(logo);
    }
  }
  state.hasChanges = false;
  state.imageChanged = false;
}


export const logosSlice = createSlice({
  name: "logos",
  initialState,
  reducers: {
    setLogos: (state, action) => {
      // console.log('setLogos', JSON.stringify(action.payload));
      const currentEditedLogoId = state.editedLogo?.id;
      state.allLogos = action.payload;
        if (currentEditedLogoId !== undefined) {
            state.editedLogo = state.allLogos.find((logo) => logo.id === currentEditedLogoId) || null;
        }
    },
    editLogo: (state, action) => {
      state.editedLogo = action.payload;
      state.hasChanges = false;
      state.imageChanged = false;
    },
    storeLogoInfo: (state, action) => {
      if (state.editedLogo !== null) {
        (state.editedLogo[action.payload.field as keyof ILogoInfos] as any) = action.payload.value;
        updateHasChanges(state);
      }
    },
    clearLogos: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getlogos.pending, (state) => {
        // console.log("getSelection pending");
        // state.saving = true;
      })
      .addCase(getlogos.fulfilled, (state, action: any) => {
        // console.log("getSelection fulfilled with " + action.payload.length + " products");
        state.allLogos = action.payload;
        state.loaded = true;
        state.error = false;
      })
      .addCase(getlogos.rejected, (state, action: any) => {
        // console.log("getSelection rejected");
        state.loaded = false;
        state.error = true;
      })
      .addCase(saveLogo.fulfilled, (state, action: any) => {
        // console.log("%cSaveLogo fulfilled with " + JSON.stringify(action.payload), 'color:white; background-color:darkgreen');
        state.saving = false;
        storeLogo(state, action.payload);
      })
      .addCase(saveLogo.pending, (state, action: any) => {
        // console.log("%cSaveLogo pending", 'color:white; background-color:orange');
        state.saving = true;
      })
      .addCase(saveLogo.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
        // console.log("saveLogo rejected");
      })
      .addCase(removeLogo.fulfilled, (state, action: any) => {
        state.allLogos = action.payload;
        state.error = false;
        state.editedLogo = null;
        state.hasChanges = false;
        state.imageChanged = false;
      })
      .addCase(removeLogo.rejected, (state, action: any) => {
        // console.log("removeLogo rejected");
      })
      .addCase(removeLogo.pending, (state, action: any) => {
        // console.log("removeLogo pending");
      })

  }
});

export const {
  setLogos,
  editLogo,
  storeLogoInfo,
  clearLogos
} = logosSlice.actions;

export default logosSlice.reducer;

export const hasAutomaticLogoSelector = ((state: RootState) => state.logos.allLogos.some((logo) => logo.automated));
export const editedLogoSelector = ((state: RootState) => state.logos.editedLogo);
// export const editingLogoSelector = createSelector((state: RootState) => state.logos.editedLogo,
//   (editedLogo) => editedLogo !== null);


// the logo is deletable if there is at least another logo that is not automated
export const logoDeletableSelector = ((state: RootState) => state.logos.allLogos
    .filter((logo) => logo.id !== state.logos.editedLogo?.id)
    .some((logo) => !logo.automated));


export const editedLogoHasChangesSelector = ((state: RootState) => state.logos.hasChanges);
export const editedLogoImageChangedSelector = ((state: RootState) => state.logos.imageChanged);
export const editedLogoSavingSelector = ((state: RootState) => state.logos.saving);
export const logosSelector = ((state: RootState) => state.logos.allLogos);
export const logosLoadedSelector = ((state: RootState) => state.logos.loaded);
// export const logosSelector = createSelector((state: RootState) => state.logos.logos, (logos) => logos);

