import React from 'react';
import Box from "@mui/material/Box";
import {IProductionStatusColors, ISelectedOrder, productionOrdersSelector} from "./productionSlice";
import ProductionItem from "./ProductionItem";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import {useSelector} from "react-redux";

function ProductionOrder(props: {
  order?: ISelectedOrder,
  itemId?: number,
  statusId?: number,
  fullScreen: boolean
}) {

  const {order, itemId, statusId, fullScreen} = props

  const productionOrders = useSelector(productionOrdersSelector);

  let thisOrder = order;

  if (!thisOrder && productionOrders) {
    thisOrder = productionOrders.find((order) => order
      .items.some((item) => item.id === itemId))

  }

  // console.log("Rendering ProductionItem of " + order.orderNumber)

  const deliveryModeIcon = (deliveryMode: string) => {
    switch (deliveryMode) {
      case 'boutique':
        return <BusinessIcon fontSize='small' />;
      case 'pickup':
        return <LocalShippingIcon fontSize='small' />;
      case 'home':
        return <HomeIcon fontSize='small' />;
      default:
        return null;
    }
  }

  if (!thisOrder) {
    return null;
  }

  const orderNumber = thisOrder.orderNumber;

  return (
    <Box className='production-item'>
      <Box className='production-order'>
        <Box className='order-number'>
          {orderNumber}
        </Box>
        <Box className='order-recipient'>
          <Box className='order-boutique'>
            {thisOrder.boutique.name} ({thisOrder.boutique.id})
          </Box>
          <Box className='order-address'>
            <Box>{deliveryModeIcon(thisOrder.delivery.mode)}</Box>
            <Box>{thisOrder.delivery.address}</Box>
          </Box>
        </Box>
        <Box className='order-date'>
          {thisOrder.orderDate}
        </Box>
      </Box>
      {thisOrder.items
        .filter((item) => (statusId === undefined) ||  item.statusId === statusId)
        .map((item, index) =>
        <ProductionItem key={`${orderNumber}-${index}`} item={item}
                        selected={item.id === itemId}
                        first={index === 0} fullScreen={fullScreen} />)
      }
    </Box>
  )
}

export default ProductionOrder;