import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput, Paper,
  Typography
} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {RootState} from "../../app/store";
import {resetErrorState, ISignupPayload, signUpUser} from "./sessionSlice";
import {useIntl} from "react-intl";
import {I18nContext} from "../locale/LocaleWrapper";
import {useAppSelector} from "../../app/hooks";
import {primaryColor, secondaryColor} from "../catalog/catalogSlice";

function Signup() {
  const intl = useIntl();
  const context = useContext(I18nContext);
  const waiting = useSelector((state: RootState) => state.session.waiting);
  const primColor = useAppSelector(primaryColor);
  const secColor = useAppSelector(secondaryColor);

  const emailRef = useRef<HTMLInputElement>();
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  // const passwordConfirmationRef = useRef<HTMLInputElement>();
  const errorMessages = useSelector((state: RootState) => state.session.errorMessages);

  const [errors, setErrors] = useState<Array<string>>([])
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openForSignup = true;

  useEffect(() => {
    emailRef?.current?.focus();
    if (errorMessages.length > 0) {
      setErrors(errorMessages.map((message: string) => intl.formatMessage({id: 'error.' + message})));
      dispatch(resetErrorState());
    }
  }, [errorMessages])

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrors([]);
    if (emailRef?.current === undefined || emailRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "session.type-email"})])
    }

    const payload: ISignupPayload = {
      email: emailRef.current.value,
      firstName: firstNameRef?.current?.value || "",
      lastName: lastNameRef?.current?.value || "",
      password: passwordRef?.current?.value || "",
      locale: context?.locale,
      color1: primColor,
      color2: secColor,
    }
    const response = await dispatch(signUpUser(payload)) as any;

    if (response.type === 'session/signUpUser/fulfilled') {
      navigate("/");
    }
  }

  const emailLabel = intl.formatMessage({id: "session.email"})
  const firstNameLabel = intl.formatMessage({id: "account.first-name"})
  const lastNameLabel = intl.formatMessage({id: "account.last-name"})
  const passwordLabel = intl.formatMessage({id: "session.password"})

  const passwordInput = <OutlinedInput
    id="password" type={showPassword ? 'text' : 'password'} label={passwordLabel} inputRef={passwordRef}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={() => setShowPassword(!showPassword)}
          edge="end">
            {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    } />;

  return (
    <section style={{marginTop:"2em"}}>
      <Container maxWidth="md">
        <Card sx={{boxShadow:1, maxWidth: 'md'}}>
          <CardContent>
            <Container maxWidth="sm">
              <Typography variant="h4" color="text.primary" gutterBottom>
                {intl.formatMessage({id: "session.signup"})}
              </Typography>
              {errors.length > 0 ?
                <Alert severity="error" aria-live="assertive" className='alert'>
                  <AlertTitle>{intl.formatMessage({id: "global.error"})}</AlertTitle>
                  {errors.map((error, index) => {
                    return <p key={`alert-${index}`}>
                      {error}
                    </p>
                  })}
                </Alert>
              : <></>}
              {openForSignup &&
                <form onSubmit={handleSubmit} className='session-form'>
                <FormGroup row={true} id="email-group" sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <InputLabel required htmlFor="email" id="email-label">{emailLabel}</InputLabel>
                    <OutlinedInput id="email" type="email" label={emailLabel} inputRef={emailRef}/>
                    <FormHelperText id="email-helper-text">{intl.formatMessage({id: "session.email-privacy"})}</FormHelperText>
                  </FormControl>
                </FormGroup>
	              <Paper variant="outlined" sx={{p:2, mt:3, backgroundColor: '#FCFCFF'}}>
		              <FormGroup row={true} id="first-name-group" sx={{marginTop: "1em"}}>
			              <Typography variant="h6" color="text.primary" gutterBottom sx={{marginTop: "-1em"}}>
                      {intl.formatMessage({id: "session.optional-info"})}
			              </Typography>
			              <FormControl fullWidth>
				              <InputLabel htmlFor="first-name" id="first-name-label">{firstNameLabel}</InputLabel>
				              <OutlinedInput id="first-name" label={firstNameLabel} inputRef={firstNameRef} />
			              </FormControl>
		              </FormGroup>
		              <FormGroup row={true} id="last-name-group" sx={{marginTop: "1em"}}>
			              <FormControl fullWidth>
				              <InputLabel htmlFor="last-name" id="last-name-label">{lastNameLabel}</InputLabel>
				              <OutlinedInput id="last-name" label={lastNameLabel} inputRef={lastNameRef} />
			              </FormControl>
		              </FormGroup>
		              <FormGroup row={true} id="password-group" sx={{marginTop: "1em"}}>
			              <FormControl fullWidth>
				              <InputLabel htmlFor="password" id="password-label">{passwordLabel}</InputLabel>
                      {passwordInput}
                      <FormHelperText>{intl.formatMessage({id: "session.password-min-chars"}, {min: 8})}</FormHelperText>
			              </FormControl>
		              </FormGroup>
                </Paper>
                {/*<FormGroup row={true} id="password-confirmation-group" sx={{marginTop: "1em"}}>*/}
                {/*  <FormControl fullWidth>*/}
                {/*    <InputLabel required htmlFor="password-confirmation" id="password-confirmation-label">{intl.formatMessage({id: "session.password_confirmation"})}</InputLabel>*/}
                {/*    {passwordConfirmationInput}*/}
                {/*  </FormControl>*/}
                {/*</FormGroup>*/}
                <FormGroup row={true} id="submit-group" sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <Button 
                      disabled={waiting}
                      variant="contained" 
                      color="primary" 
                      type="submit" 
                      id="submit-button">{intl.formatMessage({id: "session.signup"})}</Button>
                  </FormControl>
                </FormGroup>
              </form>
              }
              {openForSignup ? '' : intl.formatMessage({id: "session.not-opened"})}
            </Container>
          </CardContent>
          <Divider light={false} />
          <CardActions sx={{marginTop: "1em", justifyContent: 'center', fontSize: "1.3em"  }} disableSpacing >
            <Box>
              {intl.formatMessage({id: "session.already"})} <Link to="/login">{intl.formatMessage({id: "session.login"})}</Link>
            </Box>
          </CardActions>
        </Card>
      </Container>

    </section>
  )
}

export default Signup