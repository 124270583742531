import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import { QrReader } from 'react-qr-reader';
import Box from "@mui/material/Box";
import Result from "@zxing/library/esm/core/Result";
import {BrowserQRCodeReader} from "@zxing/browser";
import IconButton from "@mui/material/IconButton";
import {closeFullScreen, openInFullScreen} from "../../global/globalSlice";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {CardMedia} from "@mui/material";


export function ReadQrCode() {

  const dispatch = useDispatch();

  const scanResult = (result?: Result | undefined | null,
                      error?: Error | undefined | null,
                      codeReader?: BrowserQRCodeReader) => {
    if (result) {
      console.log("Result => " + result.getText())

      dispatch(closeFullScreen())

      const targetURL = result.getText()
      // get the objectId from the target url which ends with /objectId
      const objectId = parseInt(targetURL.substring(targetURL.lastIndexOf('/') + 1))

      // can be found in the model of the workshop box
      if (targetURL.includes('workshop_box')) {
        dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: objectId}))
      } else if (targetURL.includes('item')) {
        dispatch(openInFullScreen({name: 'ProductionItem', displayedId: objectId}))
      }
    }

    if (error) {
      // console.log("Error => " + error)
    }
  }

  return (
    <Box className='read-qr-code'>
      <Box className='title'>Veuillez scanner un QR code</Box>
      <CardMedia className='scan-video'>
        <video
          style={{width: '100%', height: '90%'}}
          id="qr-reader"
          autoPlay={true}
          controls={false}
          muted={true}
        />
      </CardMedia>

      <QrReader
        scanDelay={500}
        onResult={scanResult}
        videoId="qr-reader"
        constraints={{facingMode: "environment"}}
      />
    </Box>
  );

}

export function ScanQRCode() {

  const dispatch = useDispatch();

  return (
    <IconButton size='large' sx={{mb: -3, mr: 1}} aria-label="scan" onClick={() =>
      dispatch(openInFullScreen({name: 'ReadQrCode', displayedId: null}))
    }>
      <QrCodeScannerIcon />
    </IconButton>
  )
}