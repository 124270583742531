import React, {useState, useEffect } from 'react';
import {Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {currentWorkshopSelector, IWorkshopBox} from "./productionSlice";

function WorkshopBoxDetails(props: {
  workshopBoxId: number
}) {

  const {workshopBoxId} = props;

  return (
    <Box className='box-details' sx={{
      display: 'flex',
      // flexDirection: 'column',  p: 0, pr:0, mr: 0,
      width: '100%', height: '100%',
      border: '1px solid black',
    }}>
      <div className='box-name'>
        Bac : {workshopBoxId}
      </div>
    </Box>
  )
}

export default WorkshopBoxDetails;