import React, {useState, useEffect } from 'react';
import {Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {currentWorkshopSelector, IWorkshopBox} from "./productionSlice";
import {openInFullScreen} from "../../global/globalSlice";
import {AppDispatch} from "../../../app/store";

function WorkshopBoxes() {

  const dispatch = useDispatch<AppDispatch>();
  const currentWorkshop = useSelector(currentWorkshopSelector);

  const openWorkshopBox = (boxId: number) => {
    dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: boxId}))
  }

  const workshopBoxCard = (box: IWorkshopBox) => {
    return(
      <Box className='workshop-box' onClick={() => openWorkshopBox(box.id)}>
        <div className='box-name'>
          {box.name}
        </div>
        <div className='box-qr-code'>
          <img src={box.qrCode} />
        </div>
      </Box>
    )
  }

  return (
    <Box className='workshop-boxes'>
      <Box sx={{p: 1}}>
        <Grid container spacing={2}>
          {currentWorkshop?.boxes.map((workshopBox) =>
              <Grid item xs={12} sm={6} md={4} lg={3} key={`box-${workshopBox.id}`}>
                {workshopBoxCard(workshopBox)}
              </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default WorkshopBoxes;