import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {SelectedProduct, SelectionPayloadItem} from "../features/selection/selectionSlice";
import {BoutiqueInfos} from "../features/dashboard/boutiqueSlice";
import {ILogoInfos} from "../features/logos/logosSlice";

const ALL_LOGOS_URL = "/boudists/all_logos";
const UPDATE_LOGO_URL = "/boudists/update_logo";
const DELETE_LOGO_URL = "/boudists/delete_logo";

export async function getBoudistLogos() {
  return axios
    .get(ALL_LOGOS_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateLogo(logo: ILogoInfos) {
  console.log("In logosAPI, updateLogo", JSON.stringify(logo));
  const data = {
    logo: logo,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_LOGO_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function deleteLogo(logoId: number) {
  const data = {
    logo_id: logoId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DELETE_LOGO_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}