import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPerson} from "@fortawesome/free-solid-svg-icons/faPerson";
import {faPersonDress} from "@fortawesome/free-solid-svg-icons/faPersonDress";
import {faChildReaching} from "@fortawesome/free-solid-svg-icons/faChildReaching";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {FormControl, FormHelperText, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {ILogoInfos} from "./logosSlice";

export function SelectLogoBg(props: {logo:ILogoInfos, onSetBg:Function, editable:boolean}) {

  const {logo, onSetBg, editable} = props;
  const [logoBg, setLogoBg] = useState(logo.for_dark_bg);

  useEffect(() => {
    setLogoBg(logo.for_dark_bg);
  }, [logo.for_dark_bg]);

  const handleLogoBg = (
    event: React.MouseEvent<HTMLElement>,
    newBg: number | 0,
  ) => {
    setLogoBg(newBg);
    onSetBg(newBg);
  };

  console.log("SelectLogoBg " + logo.name + " logoBg = " + logoBg)

  return (
    <FormControl sx={{ m: 1, minWidth: 400, mt:'10px', mb:'20px' }} size="small" className='select-logo-bg'>
      <FormHelperText sx={{ml:0, fontSize: '1em', fontWeight: 'bold'}}>
        <FormattedMessage id="logos.select-bg-title" /> :
      </FormHelperText>
      <ToggleButtonGroup value={logoBg} exclusive disabled={!editable} onChange={handleLogoBg} sx={{justifyContent: 'center'}}>
        <ToggleButton value={2}>
          <FormattedMessage id="logos.select-bg-all" />
        </ToggleButton>
        <ToggleButton value={0}>
          <FormattedMessage id="logos.select-bg-white" />
        </ToggleButton>
        <ToggleButton value={1}>
          <FormattedMessage id="logos.select-bg-dark" />
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  )
}
