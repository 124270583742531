import React, {useState, useEffect } from 'react';
import {ILogoInfos, editLogo, LogoVersion} from "./logosSlice";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {ImageAddon} from "../selection/selectionSlice";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";


function LogoVersionSelect(props: {
  imageAddon: ImageAddon,
  logo: ILogoInfos,
  versionId: number,
  setVersionId: (logoId: number, logoVersionId: number) => void
}) {

  const {imageAddon, logo, versionId, setVersionId} = props;

  const [selectedVersion, setSelectedVersion] = useState<number>(versionId);

  const handleVersionChange = (event: SelectChangeEvent<number>) => {
    event.stopPropagation();   // prevent the click on the version to trigger also a logo choice with old default version
    setSelectedVersion(event.target.value as number);
    setVersionId(logo.id, event.target.value as number);
  }

  // console.log("Render LogoVersionSelect for logo " + logo.id + " with version " + selectedVersion);

  const logoClass = logo.for_dark_bg >= 1 ? 'dark-bg' : '';

  return (
    <div className='select-logo'>
      <div className={logoClass}>
        <BoudaToolTip arrow placement="top" title={logo.name}>
          <img src={logo.logo_url} className='select-logo' />
        </BoudaToolTip>
      </div>
      <div>
        <Select SelectDisplayProps={{
          style: { paddingTop: 4, paddingBottom: 4, paddingLeft: 4, paddingRight: 20, fontSize: '0.8em' } }}

                className={'select-logo-version'}
                size="small" value={selectedVersion} onChange={handleVersionChange}
                onClick={(e) => { e.stopPropagation(); }}
                renderValue={value => {
                  // console.log("renderValue => ", value);
                  const version = logo.versions.find((version) => version.id === value) as LogoVersion;
                  return version ? version.name : '';
                }}>
          {logo.versions.map((version: LogoVersion) => {
            return (
              <MenuItem key={`${version.id}`} value={version.id}>
                {version.name} ({version.priceCents/100} €)
              </MenuItem>
            );
          })}
        </Select>
      </div>

    </div>
  )

}

export default LogoVersionSelect;