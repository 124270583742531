import React from 'react';
import {CircularProgress, Grid} from "@mui/material";
import ColorTag from "../catalog/ColorTag";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  AddonInfos,
  CollectionProduct, CollectionProductColor,
  openProductConfig,
  removeProductFromCollection
} from "./boutiqueSlice";
import {ILogoInfos, logosSelector} from "../logos/logosSlice";
// import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {useDroppable} from "@dnd-kit/core";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {BoudaToolTip} from "../utils/BoudaToolTip";

function CollectionProductCard(props: {
  id: string,
  index: number,
  collectionId: number,
  collectionProduct: CollectionProduct,
  attachedLeft: boolean,
  attachedRight: boolean
}) {

  const boutiqueHasChanges = useSelector((state : RootState) => state.boutique.hasChanges);

  const {id, index, collectionId, collectionProduct, attachedLeft, attachedRight} = props;
  const {product, version, customTitle, colors, hasChanges, productId, imageUpdating} = collectionProduct;

  const uniqId = collectionId + '-' + (productId || '') + '-' + version;

  const logos = useSelector(logosSelector);
  const dispatch = useDispatch<AppDispatch>();

  const firstColor = colors[0].colorImages;
  const imgScaleDown = product.imgScaleDown

  const {isOver: isOverDropProduct, setNodeRef: setNodeRefDropProduct} = useDroppable({
    id: 'drop-product-' + product.id,
    data: {
      product: product,
      collectionId: collectionId,
      accepts: 'logo'
    }
  });

  const styleDropProduct = {
    border: isOverDropProduct ? '1px #00A000 solid' : '',
  };

  // const {attributes, listeners, setNodeRef: setNodeRefSortProduct, transform, transition} = useSortable(
  //   {
  //     id: id,
  //     data: {
  //       type: 'collectionProduct',
  //       collectionId: collectionId,
  //       index: index,
  //     }
  //   });

  // const styleSortProduct = {
  //   transform: CSS.Transform.toString(transform),
  //   transition
  // };

  if (collectionProduct.colors.length == 0) return null;

  const handleRemove = () => {
    dispatch(removeProductFromCollection({
      collectionId: collectionId,
      id: product.id,
      productId: productId,
      version: version}))
  }


  const productLogo = (logo: ILogoInfos | undefined, i:number) => {
    if (logo == undefined) return null;

    return (
      <div key={`${uniqId}-L${i}`} className={`logo ${logo.for_dark_bg >= 1 ? 'dark-bg' : ''}`}>
        {
          ((logo.logo_url !== null && logo.logo_url !== '') &&
				      <img src={logo.logo_url} alt={logo.name} width='16'/>) ||
			      <div className="no-logo">
				      <div className="icon">
					      <ImageSearchIcon/>
				      </div>
			      </div>
        }
      </div>
    );
  }

  // console.log('render in collection ' + collectionId + ' product ' + product.title + ' with first color ' + collectionProduct.colors[0].colorImages.color);

  // le premier div id={`P${product.id.toString()}`}

  const productTitle = (customTitle || product.title) + ((version > 1) ? ` (v${version})` : '');


  return (
    // <div className="col-product" ref={setNodeRefSortProduct} style={styleSortProduct} >

    <BoudaToolTip title={productTitle} placement="top" arrow>
      <div className="col-product" >
        {/*<IconButton {...attributes} {...listeners}*/}
        {/*<IconButton sx={{position: 'absolute', left: '-10px', top: '-7px', color: '#A0A0A0', cursor:'move'}}>*/}
        {/*  <DragIndicatorIcon sx={{width: '0.8em', height: '0.8em'}} />*/}
        {/*</IconButton>*/}
        <IconButton aria-label="close" onClick={() => handleRemove()}
                    sx={{position: 'absolute', right: '-2px', top: '-5px', size:'small', color: '#A0A0A0', zIndex:2}}>
          <DeleteIcon sx={{width: '0.8em', height: '0.8em',}} />
        </IconButton>
        <div className={`product-tbn ${hasChanges ? 'has-changes' : ''}`} ref={setNodeRefDropProduct} style={styleDropProduct}>
          <div className='first-img' onClick={() => dispatch(openProductConfig(collectionProduct))}>
            {firstColor && firstColor.images.length > 0 &&
            <img src={firstColor.images[0].boutiqueUrl || firstColor.images[0].url}
                 style={{width:`${100 - imgScaleDown}%`,marginTop:`${imgScaleDown/2}%`,marginBottom:`${imgScaleDown/2}%`}} />
            }
            {!boutiqueHasChanges && imageUpdating && <div className="product-overlay">
              <div className="spinner">
                <CircularProgress color="inherit" />
              </div>
            </div>
            }
          </div>
          <div className="product-footer">
            <div className='logos'>
              {collectionProduct.colors.map((color: CollectionProductColor) =>
                color.logoAddons.map((addon: AddonInfos) => addon.logoId)
              ).flat().filter((v,i,a)=>a.indexOf(v)==i). // unique
              map((logoId: number, i) => productLogo(logos.find((logo: ILogoInfos) => logo.id === logoId), i))
              }
            </div>
            <div className='all-colors'>
              {colors.map((collectionProductColor: CollectionProductColor) => {
                const otherColor = collectionProductColor.colorImages;
                return (otherColor &&
                    <ColorTag key={`${uniqId}-h1${otherColor.hexColor1}-h2${otherColor.hexColor2}`}
                              color1={otherColor.hexColor1} color2={otherColor.hexColor2}
                              friendlyColor={otherColor.friendlyColor} active={false} size='small' />
                )
              })}
            </div>
          </div>
          {attachedLeft && <div className="attached-left">
	          <LinkIcon sx={{width: '1.3em', height: '1.3em'}} />
	        </div>}
          {attachedRight && <div className="attached-right">
	          <LinkIcon sx={{width: '1.3em', height: '1.3em'}} />
          </div>}
        </div>
      </div>
    </BoudaToolTip>
  )

}

export default CollectionProductCard;