import React, {useEffect} from 'react';
import {useAppSelector} from "../../app/hooks";
import {
  eraseSelection,
  getSelection,
  LoadedSelection,
  SelectedProduct,
  SelectedProducts, setSelectedProducts,
  UniqueProducts
} from "./selectionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import Box from "@mui/material/Box";
import {CircularProgress, Grid} from "@mui/material";
import SelectedProductCard from "./SelectedProductCard";
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";
import DeleteIcon from "@mui/icons-material/Delete";
import {hideBackDrop, openConfirmDialog, openSnackBar, showBackDrop} from "../global/globalSlice";
import {cable} from "../../app/cable";

function ProductSelection(props:{title:boolean, catalogView: boolean}) {

  const intl = useIntl();

  const { title, catalogView} = props;

  const products = useAppSelector(UniqueProducts);
  const selectionLoaded = useAppSelector((state: RootState) => state.selection.loaded);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !selectionLoaded) {
      console.log("ProductSelection useEffect... dispatch getSelection");
      dispatch(getSelection());
      // console.log("ProductSelection useEffect... dispatch getBoutiques");
      // dispatch(getBoutiques());
    }
  }, [dispatch, accessToken]);

  ////////////////////////////////////////////////////////////////////////////////////
  // Receive selection broadcasts in case the user is logged in on multiple devices
  const subscribeToSelection = (current_user_id: number) => {
    cable.subscriptions.create(
        { channel: "SelectionChannel" },
        { received: (payload:any) => {
            if (payload.user_id === current_user_id) {
              // console.log("%cProductSelection received selection broadcast with accessToken = " + payload.originAccessToken, "color: blue");
              if (payload.originAccessToken === accessToken) {
                // console.log("%cProductSelection received selection broadcast with own accessToken, not doing anything ...", "color: red");
              } else dispatch(setSelectedProducts(payload));
            }
          }
        }
    );
  }

  // useEffect(() => {
  //   if (accessToken && accessToken !== 'undefined' && currentUser && currentUser?.id !== undefined) {
  //     subscribeToSelection(currentUser.id);
  //   }
  // }, [currentUser]);
  ////////////////////////////////////////////////////////////////////////////////////

  async function handleEmptySelection() {
    dispatch(showBackDrop())

    const response = await dispatch(eraseSelection()) as any;

    if (response.type === 'selection/eraseSelection/fulfilled') {
      dispatch(openSnackBar({severity: "success", message: intl.formatMessage({id: "selection.emptied"})}));
    }

    dispatch(hideBackDrop())
  }

  const emptySelection = () => {
    dispatch(openConfirmDialog({
      title: intl.formatMessage({ id: "selection.empty-title"}, {name: 'toto'}),
      message: intl.formatMessage({ id: "selection.empty-question"}),
      confirm: intl.formatMessage({ id: "selection.empty-confirm"}),
      action: handleEmptySelection
    }));
  }

  console.log("ProductSelection rendering... with loaded = ", selectionLoaded);

  return(
    <Box sx={{width: '180px'}}>
      <Grid container spacing={1} className='selection'>
        {title &&
          <Grid item xs={12} className='selection-header'>
            <div className='title'>
	          <FormattedMessage id="selection.my-selection" />
            </div>
            {products && products.length > 0 &&  <DeleteIcon className='empty-button' fontSize='small' onClick={emptySelection} />}
          </Grid>
        }
        <>
          {accessToken && !selectionLoaded &&
	          <div className='empty-selection'>
		          <FormattedMessage id="selection.loading" /><br/><br/>
		          <CircularProgress color="inherit" size={60} />
            </div>
            ||
            (accessToken && products && products.length > 0 && products.map(p =>
              <SelectedProductCard key={p.product.id} selectedProduct={p} catalogView={catalogView} />
            )) ||
              <div className='empty-selection'>
                <FormattedMessage id="selection.selection-explanation" />
              </div>
          }
        </>
      </Grid>
    </Box>

  )
}

export default ProductSelection;
