import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
  colorsForStatusIdSelector,
  IArticleLogo,
  IArticlePerso,
  IPrintInfos,
  IProductionStatusColors,
  ISelectedItem
} from "./productionSlice";
import {darkenColor} from "../../../app/utils";
import {Collapse} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
import {openInFullScreen} from "../../global/globalSlice";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../app/store";

function ProductionItem(props: {
  item: ISelectedItem,
  selected: boolean,
  first: boolean,
  fullScreen: boolean
}) {

  const dispatch = useDispatch<AppDispatch>();
  const {item, selected, first, fullScreen} = props

  const colors = useSelector(state => colorsForStatusIdSelector(state, item.statusId));

  const [expanded, setExpanded] = useState(fullScreen && selected);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (colors === undefined) {
    return null;
  }

  const bgColor = item.quantity > 1 ? darkenColor(colors.bgColor, 0.5) : colors.bgColor

  const textDetails = (perso: IArticlePerso) => {
    return(<>
      <Box className='first-line'>
        <Box className='name'>{perso.name}</Box>
        <Box className='content'>
          <Box className='value'>
            {perso.value}
          </Box>
          <Box className='color'>
            <Box className='color-box' sx={{bgColor: perso.textInfos?.fontColor || 'black'}}></Box>
            {perso.textInfos?.fontColor}
          </Box>
        </Box>
      </Box>
      <Box className='second-line'>
        <a href={perso.textInfos?.fontFile} target="_blank" rel="noreferrer">
          <FileDownloadIcon fontSize='small' />
          {perso.textInfos?.fontName}
        </a>
      </Box>
    </>)

  }

  const printInfos = (infos: IPrintInfos) => {
    if (!infos.name) return null;

    return(<>
      <a href={infos.file} target="_blank" rel="noreferrer">
        <FileDownloadIcon fontSize='small' />
        {infos.name}
      </a>
    </>)
  }

  const logoDetails = (logo: IArticleLogo) => {
    return(<>
      <Box className='first-line'>
        <Box className='name'>
          <Box className='title'>
            {logo.name}
          </Box>
          <Box className='technique'>
            {logo.logoInfos?.technique}
          </Box>
        </Box>
        <Box className='content logo' sx={{bgcolor: logo.bgColor}}>
          <img src={logo.logoInfos?.image} alt={logo.name} />
        </Box>
      </Box>
      {logo.logoInfos &&
        <Box className='second-line'>
          {[logo.logoInfos.print,
            logo.logoInfos.print2,
            logo.logoInfos.print3,
            logo.logoInfos.print4,
            logo.logoInfos.print5].map((print, index) =>
              <Box key={`print-${index}`} className='print'>
                {printInfos(print)}
              </Box>
          )}
        </Box>
      }
    </>)
  }

  return (
    <Box className={`article ${expanded ? 'expanded' : ''} ${first ? 'first' : ''}`}>
      <Box className='article-title'>
        <Box className='left'>
          <Box className='qty' sx={{color: colors.fgColor, bgcolor: bgColor}}>
            {item.quantity}
          </Box>
          <Box className='title' onClick={handleExpandClick}>
            {item.article.variant} [<span className='ref'>{item.article.ref}</span>]
            {item.article.productionNotes &&
              <Box className='notes'>
                {item.article.productionNotes}
              </Box>
            }
          </Box>
        </Box>
        {!fullScreen &&
          <IconButton size='large' sx={{m:0, p:0}} aria-label="scan" onClick={() =>
            dispatch(openInFullScreen({name: 'ProductionItem', displayedId: item.id}))
          }>
            <ZoomOutMapIcon />
          </IconButton>
        }
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box className='article-details'>
          <Box className='preview'>
            <img src={item.article.preview} />
          </Box>
          <Box className='markings'>

            <Box className='logos'>
              {item.article.persos.map((perso, index) =>
                <Box key={`perso-${index}`} className='marking'>
                  {perso.type === 'text' ? textDetails(perso) :
                    logoDetails({
                      name: perso.name,
                      logoInfos: perso.logoInfos,
                      bgColor: perso.bgColor
                    } as IArticleLogo)}
                </Box>
              )}
            </Box>

            <Box className='logos'>
              {item.article.logos.map((logo, index) =>
                <Box key={`logo-${index}`} className='marking'>
                  {logoDetails(logo)}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}


export default ProductionItem;