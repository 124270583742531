import React from "react";

const AccessorySvg = (height: number) => {

  return (
      <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M9.1,50.4c2.3,2,5.8,1.8,7.9-0.5l0.7-0.7c-1.5-2.2-3.6-4.4-6.2-5.8c-1.2-0.6-2.2-0.9-3-1.1l-0.3,0.4 c-0.7,1-1.1,2.2-1.1,3.3C7.1,47.7,7.8,49.3,9.1,50.4L9.1,50.4z"/>
          <path d="M19.2,47.6l7.7-8.3c0.9-1,1.9-1.9,2.9-2.7c-1.4-4.4-1.3-8.1,0.3-10.9c1.5-2.7,4.1-3.8,5.2-4.2C35,20.9,35,20.2,35,19.5 l0-3.4H21.5v5.7c0,1.7-0.5,3.3-1.5,4.7l-10.1,14c0.7,0.2,1.6,0.5,2.5,1C14.2,42.5,16.8,44.3,19.2,47.6L19.2,47.6z"/>
          <polygon points="35.1,10.4 21.5,10.4 21.5,13.9 35,13.9 35.1,10.4 		"/>
          <path d="M35.1,6.6c0-1-0.8-1.8-1.8-1.8h-10c-1,0-1.8,0.8-1.8,1.8v1.6h13.6L35.1,6.6L35.1,6.6z"/>
          <path d="M31.9,26.8c-1.2,2.1-1.3,4.9-0.3,8.4c0.4-0.3,0.8-0.6,1.3-0.9c2.6-1.6,5.7-5,3.1-10.4c-0.1-0.1-0.1-0.2-0.2-0.3 C35,23.9,33.1,24.8,31.9,26.8L31.9,26.8z"/>
          <path d="M54.9,16.4c0-1-0.8-1.8-1.8-1.8h-10c-1,0-1.8,0.8-1.8,1.8V18h13.6L54.9,16.4L54.9,16.4z"/>
          <polygon points="54.9,20.1 41.3,20.1 41.3,23.7 54.8,23.7 54.9,20.1 		"/>
          <path d="M36.8,59.7l0.7-0.7c-1.5-2.2-3.6-4.4-6.2-5.8c-1.2-0.6-2.2-0.9-3-1.1L28,52.5c-0.7,1-1.1,2.2-1.1,3.3c0,1.6,0.7,3.2,2,4.4 C31.3,62.1,34.8,61.9,36.8,59.7L36.8,59.7z"/>
          <path d="M32.1,51.2c1.9,1,4.5,2.8,6.9,6.2l7.7-8.3c0.9-1,1.9-1.9,2.9-2.7c-1.4-4.4-1.3-8.1,0.3-10.9c1.5-2.7,4.1-3.8,5.2-4.2 c-0.1-0.7-0.2-1.4-0.2-2.1l0-3.4H41.3v5.7c0,1.7-0.5,3.3-1.5,4.7l-10.1,14C30.4,50.4,31.2,50.7,32.1,51.2L32.1,51.2z"/>
          <path d="M51.7,36.6c-1.2,2.1-1.3,4.9-0.3,8.4c0.4-0.3,0.8-0.6,1.3-0.9c2.6-1.6,5.7-5,3.1-10.4c-0.1-0.1-0.1-0.2-0.2-0.3 C54.8,33.6,52.9,34.5,51.7,36.6L51.7,36.6z"/>
        </g>
      </svg>
  );
}


export default AccessorySvg;

