import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {RootState} from "../../app/store";
import {getAccessToken} from "../../app/sessionAPI";

function PrivateRoute(props: {children: any}) {
  const loading = useSelector((state: RootState) => state.session.loading);
  const location = useLocation();
  const fromLocation = (location.state as any)?.from;
  const previousLocation = fromLocation ? fromLocation : { pathname: '/login'};

  const accessToken = getAccessToken();

  if (accessToken) {
    return props.children;
  } else if (loading) {
    return <p>Loading private route...</p>;
  } else if (!accessToken && !loading) {
    return <Navigate to={previousLocation} state={{from: location}} replace/>;
  } else {
    return <p>Something went wrong</p>;
  }
}

export default PrivateRoute