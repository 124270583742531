import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {RootState} from "../../app/store";
import {getAccessToken} from "../../app/sessionAPI";

function PublicOnlyRoute(props: {children: any}) {
  const accessToken = getAccessToken();
  const loading = useSelector((state : RootState) => state.session.loading);
  const location = useLocation();
  const fromLocation = (location.state as any)?.from;
  const previousLocation = fromLocation ? fromLocation : { pathname: '/'};

  // console.log("PublicOnlyRoute with accessToken= " + accessToken)

  if (!accessToken && !loading) {
    return props.children;
  } else if (loading) {
    return <p>Loading public route...</p>;
  } else if (accessToken && !loading) {
    return <Navigate to={previousLocation} state={{from: location}} replace/>;
  } else {
    return <p>Something went wrong</p>;
  }
}

export default PublicOnlyRoute