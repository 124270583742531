import React from "react";

const SweatsuitSvg = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M41.8,29.2c-0.2,0-7.7,0.2-9.8,0.2c-2.1-0.1-9.5-0.2-9.8-0.2c-0.3,0-2.4,9-2.6,10.4
				c-0.2,1.4-1.1,18.8,0,21.2c0,0,0,0,0,0.1c0.4,0.8,1.5,1.4,2.9,1.4h2.6c1,0,1.9-0.5,2.1-1.1l5.2-20.3l4.5,20.3
				c0.1,0.7,1,1.1,2.1,1.1h2.5c1.3,0,2.5-0.6,2.9-1.4c0,0,0,0,0-0.1c1.1-2.5,0.2-19.9,0-21.2C44.1,38.2,42.1,29.2,41.8,29.2
				L41.8,29.2z"/>
          <path d="M47.3,29.9h0.2c1.5,0,2.7-0.8,2.6-1.7v0c0-0.4,0-0.7-0.1-1.1L50,26c-0.1-2.1-0.3-4.3-0.4-6.4
				c0-0.7,0-3.9-0.3-5.1c-0.1-0.8-0.2-1.5-0.3-2.1l-0.3-1.5c-0.1-0.2-0.1-0.5-0.2-0.7l-0.1-0.3l-0.7-1.2l0,0l0,0l0,0c0,0,0,0,0,0
				l0,0c-0.3-0.5-0.7-0.9-1.2-1.3c-0.3-0.2-1.9-0.8-3.6-1.5c-0.1,0.3-0.3,0.6-0.6,1l-2.7,2.9c-0.3,0.3-0.6,0.6-1,0.8v3v5.1
				c0,0.8-1.1,1.4-2.4,1.4c-1.2,0-2.1-0.7-2.1-1.4v-5.1v-1.3c-0.7,0.1-1.3,0.1-2,0.1c-0.7,0-1.4-0.1-2.1-0.2v1.4v5.1
				c0,0.8-1.1,1.4-2.4,1.4c-1.2,0-2.1-0.7-2.1-1.4v-5.1v-3.1c-0.3-0.2-0.6-0.5-0.9-0.8L21.9,7c-0.3-0.3-0.5-0.7-0.6-1
				c-1.7,0.6-3.4,1.3-3.7,1.5c-0.5,0.4-0.9,0.8-1.2,1.3l-0.4,0.7h0c0,0,0,0,0,0l0,0v0c-0.3,0.5-0.5,1-0.6,1.6l-0.2,0.7c0,0,0,0,0,0
				c0,0,0,0,0,0l0,0c-0.1,0.4-0.2,0.8-0.2,1.3c-0.1,0.5-0.1,1-0.2,1.5c-0.1,1.2-0.3,4.4-0.3,5.1c-0.1,2.1-0.3,4.2-0.4,6.3L14,26.9
				l0,0c0,0.4-0.1,0.9-0.1,1.3v0c-0.1,0.9,1.1,1.7,2.6,1.7h0.2c1.4,0,2.5-0.6,2.6-1.5l0.2-1.3l0.1-0.9l0.3-2.2l0.3-2.3l1.3-7.1l0,0
				c0,0.1,0,0.2,0,0.3v5v6.7V27c0,0.3,0.4,0.5,0.8,0.5H23h18h0.6c0.4,0,0.8-0.2,0.8-0.5v-0.4v-7.8v-3.7l1.3,6.6l0.2,1.1l0.4,3.1
				l0.1,0.7l0.2,1.7C44.8,29.2,45.9,29.9,47.3,29.9L47.3,29.9z"/>
          <path d="M38.5,5.3c0,0.1-0.1,0.2-0.1,0.2l-1,1v0l-0.1,0.1l-0.9,1l-0.8,0.8c-0.3,0.3-0.8,0.6-1.3,0.8
				c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.7,0.2-1.1,0.3c-0.3,0-0.6,0.1-0.9,0.1c-0.4,0-0.8,0-1.2-0.1c-0.3-0.1-0.7-0.1-1-0.3
				c-0.1,0-0.1,0-0.2-0.1c-0.5-0.2-1-0.5-1.3-0.8l-0.4-0.4l-0.4-0.4l-1-1h0l0,0l-1-1c-0.1-0.1-0.1-0.2-0.1-0.3
				c0-0.2,0.1-0.3,0.3-0.5c0.2-0.1,0.4-0.2,0.7-0.2h11c0.3,0,0.5,0.1,0.7,0.2C38.5,5,38.6,5.2,38.5,5.3L38.5,5.3z M37.5,2.9h-11
				c-2.4,0-4.1,1.3-4,2.6c0,0.4,0.2,0.7,0.5,1l2.3,2.4v0l0.4,0.5c0.3,0.3,0.7,0.6,1.1,0.9v1.2v2.2v5.1c0,0.1,0.1,0.2,0.2,0.3
				c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.7-0.5v-5.1V12v-1c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0c0.5,0.2,0.9,0.3,1.4,0.4
				c1.4,0.3,2.8,0.3,4.2,0c0.5-0.1,1-0.2,1.5-0.4v1v1.7v5.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.7-0.5
				v-5.1v-2.2v-1.2c0.4-0.3,0.8-0.6,1.2-0.9l0.3-0.3l0.1-0.1L41,6.5c0.3-0.3,0.5-0.7,0.5-1c0,0,0,0,0,0C41.7,4.2,40,2.9,37.5,2.9
				L37.5,2.9z"/>
        </g>
      </svg>
  );
}


export default SweatsuitSvg;

