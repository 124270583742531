import React from "react";
import {BoudaToolTip} from "../utils/BoudaToolTip";

const BestSellerSvg = (props: {height: string, left: string, top:string, activated:boolean,
  onClick: (e:React.MouseEvent) => void}) => {

  const {height, left, top,activated} = props

  return (
    <BoudaToolTip title="Best Seller" placement="bottom">
    <svg version="1.1" viewBox="0 0 70 70" className='best-seller' onClick={(e) => props.onClick(e)}
         style={{'height': height, 'position': 'absolute', 'left': left, 'top': top, 'opacity': activated ? 1 : 0.2}}>
          <path d="M50,28.7H38.1c-0.5,0-0.9-0.4-1-0.9c-0.1-1,0.1-2.1,0.5-3.1c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.5,0.3-1,0.4-1.6
	c0.1-1.1-0.1-2.2-0.5-3.2c-1-2.3-2.2-2.3-2.6-2.3c-0.2,0-0.4,0-0.5,0.1c0.3,3.8-0.7,7.6-2.9,10.8l-3.2,4.7c0,0,0,0,0,0.1v11.8
	c0.1,0.5,0.4,0.9,0.8,1.3c0.4,0.3,0.8,0.4,1.3,0.4l14.2-0.1c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.6-0.4-1.2-1-1.4
	c-0.5-0.1-0.8-0.6-0.7-1.1c0.1-0.5,0.5-0.9,1-0.8H47c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.8-0.6-1.4-1.4-1.4l0,0
	c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.2c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.8-0.6-1.5-1.5-1.5c0,0,0,0,0,0c-0.6,0-1-0.4-1-1
	c0-0.5,0.3-0.9,0.8-1h0.2H50c0.8,0,1.4-0.7,1.4-1.4c0,0,0,0,0,0c0-0.4-0.1-0.7-0.4-1C50.8,28.9,50.4,28.7,50,28.7z"/>
          <path d="M58.9,32.2c-2.1-1.5-2.9-4.3-1.8-6.7c0.8-1.7,0-3.7-1.7-4.5c-0.4-0.2-0.7-0.3-1.1-0.3c-2.6-0.2-4.7-2.3-4.9-4.9
	c-0.2-1.9-1.8-3.3-3.7-3.1c-0.4,0-0.8,0.1-1.1,0.3l0,0c-2.4,1.1-5.2,0.3-6.7-1.8c-1.1-1.5-3.2-1.9-4.8-0.8c-0.3,0.2-0.6,0.5-0.8,0.8
	c-1.5,2.2-4.3,2.9-6.7,1.8c-1.7-0.8-3.7-0.1-4.5,1.6c-0.2,0.4-0.3,0.8-0.3,1.2c-0.2,2.6-2.3,4.7-4.9,4.9c-1.9,0.2-3.3,1.8-3.1,3.7
	c0,0.4,0.1,0.8,0.3,1.2c1.1,2.4,0.3,5.2-1.8,6.7c-1.5,1.1-1.9,3.2-0.8,4.8c0.2,0.3,0.5,0.6,0.8,0.8c2.1,1.5,2.9,4.3,1.8,6.7
	c-0.8,1.7-0.1,3.7,1.7,4.5c0.4,0.2,0.7,0.3,1.1,0.3c2.6,0.2,4.7,2.3,4.9,4.9c0.2,1.9,1.8,3.3,3.7,3.1c0.4,0,0.8-0.1,1.1-0.3
	c2.4-1.1,5.2-0.3,6.7,1.8c1.1,1.5,3.2,1.9,4.7,0.8c0.3-0.2,0.6-0.5,0.8-0.8c1.5-2.1,4.3-2.9,6.7-1.8c1.7,0.8,3.8,0,4.6-1.7
	c0.2-0.3,0.3-0.7,0.3-1.1c0.2-2.6,2.3-4.7,4.9-4.9c1.9-0.2,3.3-1.8,3.1-3.7c0-0.4-0.1-0.8-0.3-1.1l0,0c-1.1-2.4-0.3-5.2,1.8-6.7
	c1.5-1.1,1.9-3.2,0.8-4.8C59.5,32.8,59.2,32.5,58.9,32.2L58.9,32.2z M51.1,33.4c0.7,1.4,0.5,3-0.6,4.1c-0.2,0.2-0.5,0.4-0.8,0.6
	c1,1.6,0.6,3.7-1,4.7c-0.3,0.2-0.7,0.4-1,0.4c0.2,0.5,0.3,0.9,0.3,1.5c0,0.9-0.3,1.8-1,2.4c-0.6,0.7-1.5,1-2.4,1l-14.3,0.1
	c-0.9,0-1.8-0.3-2.5-0.8c-0.4,0.7-1.2,1.1-2,1.1h-7.3c-0.6,0-1-0.4-1-1V31.3c0-0.6,0.4-1,1-1H26c0.5,0,0.9,0.1,1.3,0.4l2.7-3.9
	c2-3,2.9-6.6,2.5-10.2c-0.1-0.4,0.1-0.8,0.5-1c0.6-0.3,1.4-0.5,2.1-0.5c1.3,0,3.2,0.6,4.5,3.5c0.5,1.3,0.7,2.6,0.6,4
	c-0.1,0.7-0.2,1.5-0.5,2.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.4-0.3,0.9-0.3,1.4H50c0.9,0,1.8,0.3,2.4,1c0.6,0.6,1,1.5,1,2.4
	C53.5,31.5,52.5,32.9,51.1,33.4z"/>
          <path d="M26.4,32.5c-0.1-0.1-0.2-0.2-0.4-0.2h-6.3v14.2H26c0.2,0,0.4-0.2,0.4-0.4v-1.5c0-0.2,0-0.4,0-0.5V32.5z"/>
          <path d="M65.3,29.8c-1.7-1.2-2.3-3.5-1.4-5.4c1.5-3.2,0-7-3.2-8.5c-0.7-0.3-1.3-0.5-2.1-0.5c-2.1-0.2-3.8-1.9-4-4
	c-0.3-3.5-3.4-6.1-6.9-5.8c-0.7,0.1-1.4,0.3-2.1,0.5c-1.9,0.9-4.2,0.3-5.4-1.5c-0.6-0.8-1.4-1.5-2.3-2C37,2.2,36,2,35,2
	c-2.1,0-4,1-5.2,2.7c-1.2,1.7-3.5,2.3-5.4,1.4c-3.2-1.5-7,0-8.5,3.2c-0.3,0.6-0.5,1.3-0.5,2.1c-0.2,2.1-1.9,3.8-4,4
	c-3.5,0.3-6.1,3.4-5.8,6.9c0.1,0.7,0.3,1.4,0.5,2.1c0.9,1.9,0.3,4.2-1.5,5.4c-2.9,2-3.6,6-1.5,8.9c0.4,0.6,0.9,1.1,1.5,1.5
	c1.7,1.2,2.3,3.5,1.4,5.4c-1.5,3.2-0.1,7,3.1,8.4c0.7,0.3,1.4,0.5,2.1,0.6c2.1,0.2,3.8,1.9,4,4c0.3,3.5,3.4,6.1,6.9,5.8
	c0.7-0.1,1.4-0.3,2.1-0.5c1.9-0.9,4.2-0.3,5.4,1.5c2,2.9,6,3.6,8.9,1.6c0.6-0.4,1.1-1,1.6-1.6c1.2-1.7,3.5-2.3,5.4-1.5l0,0
	c3.2,1.5,7,0,8.5-3.2c0.3-0.7,0.5-1.3,0.5-2.1c0.2-2.1,1.9-3.8,4-4c3.5-0.3,6.1-3.4,5.8-6.9c-0.1-0.7-0.3-1.4-0.6-2.1
	c-0.9-1.9-0.3-4.2,1.5-5.4l0,0c2.9-2,3.6-6,1.5-8.9C66.4,30.7,65.9,30.2,65.3,29.8L65.3,29.8z M60,39.5c-1.3,1-1.8,2.7-1.1,4.2l0,0
	c1.2,2.7,0,5.9-2.7,7.2c-0.6,0.3-1.1,0.4-1.8,0.5c-1.6,0.1-3,1.5-3.1,3.1c-0.3,3-2.9,5.2-5.9,4.9c-0.6-0.1-1.2-0.2-1.8-0.5
	c-1.5-0.7-3.3-0.2-4.2,1.2c-1,1.5-2.7,2.3-4.4,2.3l0,0c-1.8,0-3.4-0.9-4.4-2.3c-0.9-1.4-2.7-1.8-4.2-1.2c-2.7,1.3-5.9,0.1-7.2-2.6
	c-0.3-0.6-0.4-1.2-0.5-1.8c-0.1-1.6-1.5-3-3.1-3.1c-3-0.3-5.2-2.9-4.9-5.9c0.1-0.6,0.2-1.2,0.5-1.8c0.7-1.5,0.2-3.3-1.1-4.2
	c-2.4-1.7-3-5.1-1.3-7.6c0.4-0.5,0.8-0.9,1.3-1.3c1.4-1,1.8-2.7,1.1-4.2c-1.3-2.7-0.1-5.9,2.6-7.2c0.6-0.3,1.2-0.4,1.8-0.5
	c1.6-0.1,3-1.5,3.1-3.1c0.3-3,2.9-5.2,5.9-4.9c0.6,0.1,1.2,0.2,1.8,0.5c1.5,0.7,3.3,0.2,4.2-1.1c1.7-2.4,5.1-3,7.6-1.3
	c0.5,0.4,0.9,0.8,1.3,1.3c0.9,1.3,2.7,1.8,4.2,1.2l0,0c2.7-1.3,5.9-0.1,7.2,2.6c0.3,0.6,0.4,1.2,0.5,1.8c0.1,1.6,1.5,3,3.1,3.1
	c3,0.3,5.2,2.9,4.9,5.9c-0.1,0.6-0.2,1.2-0.5,1.8c-0.7,1.5-0.2,3.3,1.2,4.2c2.5,1.7,3.1,5.1,1.3,7.5C61,38.6,60.5,39.1,60,39.5z"/>
      </svg>
    </BoudaToolTip>
  );
}


export default BestSellerSvg;

