import React from 'react';
import {useDispatch} from "react-redux";
import {FormControl, FormHelperText} from "@mui/material";
import TextField from "@mui/material/TextField";
import {selectOrder} from "./productionSlice";

export function SearchOrder() {

  const dispatch = useDispatch();

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = event.target.value;

    console.log("search: " + search);

    if (search !== null && (search.length >= 5 || search.length === 0)) {
      dispatch(selectOrder(search))
    }
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 150 }} size="small" className='select-tool'>
      <FormHelperText sx={{ml:0}}>Commande</FormHelperText>
      <TextField size="small" sx={{width: '70px'}} onChange={handleSearch}
      />
    </FormControl>
  )
}
