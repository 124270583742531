import React from "react";

const PantsSvg = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M13.2,15.2v4.1c0,0.5,0.4,0.9,0.9,0.9h4.6v-5.8h-4.6C13.6,14.3,13.2,14.7,13.2,15.2L13.2,15.2z"/>
          <path d="M28.7,15.8c0.9,0,1.5,0.6,1.5,1.4c0,0.8-0.7,1.4-1.5,1.4c-0.9,0-1.5-0.6-1.5-1.4C27.1,16.4,27.8,15.8,28.7,15.8L28.7,15.8
			z M35,15.8c0.9,0,1.5,0.6,1.5,1.4c0,0.8-0.7,1.4-1.5,1.4c-0.9,0-1.5-0.6-1.5-1.4C33.4,16.4,34.1,15.8,35,15.8L35,15.8z M44.1,20.1
			v-5.8H19.5v5.8H44.1L44.1,20.1z"/>
          <path d="M50.5,19.2v-4.1c0-0.5-0.4-0.9-0.9-0.9h-4.6v5.8h4.6C50.1,20.1,50.5,19.7,50.5,19.2L50.5,19.2z"/>
          <path d="M6.1,49.3c-0.2,0.7,0.4,1.5,1.1,1.5h20.3c0.5,0,1-0.3,1.1-0.8l0.9-3.1H6.7L6.1,49.3L6.1,49.3z"/>
          <path d="M51,22c-0.1-0.5-0.6-0.9-1.1-0.9H14.1c-0.5,0-1,0.4-1.1,0.9L6.9,45.9h23.2l1.2-3.8c0,0,0,0,0,0c0.2-0.7,1.2-0.7,1.4,0
			c0,0,0,0,0,0l1.2,3.8h23.2L51,22L51,22z"/>
          <path d="M57.3,46.8H34.4l0.9,3.1c0.1,0.5,0.6,0.8,1.1,0.8h20.3c0.8,0,1.3-0.7,1.1-1.5L57.3,46.8L57.3,46.8z"/>
        </g>
      </svg>
  );
}


export default PantsSvg;

