import './app_bar.scss';
import * as React from 'react';
import logo from '../../images/dagoba.png';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { To, useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../../app/store";
import {openSpeedySignup, reloadUser, ROLE_ADMIN, ROLE_BOUTIQUE, updateProfile, User} from "../sessions/sessionSlice";
import {currentFilterKey, filterProducts, selectBrand} from "../catalog/catalogSlice";
import {useAppSelector} from "../../app/hooks";
import {useContext, useEffect, useRef} from "react";
import {I18nContext} from "../locale/LocaleWrapper";
import ReactCountryFlag from "react-country-flag"
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";


const flagStyle = {
  width: '50px',
  fontSize: '1.2rem',
  backgroundColor: '#D0D0D0'
}

const ResponsiveAppBar = () => {

  const intl = useIntl();
  const context = useContext(I18nContext);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const isUserMenuOpen = Boolean(anchorElUser);

  const navigate = useNavigate();
  const loading = useSelector((state : RootState) => state.session.loading);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
      (currentUser.role >= ROLE_BOUTIQUE || localStorage.getItem("iprsnt") === '1')

  const userProduction = loggedUser && currentUser.production

  const userAdmin = loggedUser && currentUser.role === ROLE_ADMIN

  const dispatch = useDispatch<AppDispatch>();
  const currentFilter = useAppSelector(currentFilterKey);

  const accessToken = getAccessToken();


  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !loggedUser) {
      // console.log("ResponsiveAppBar useEffect dispatching reloadUser because accessToken = ", accessToken)
      dispatch(reloadUser());
    }
  }, [dispatch, accessToken, currentUser]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  function handleNavigate(route: To, event: React.MouseEvent) {
    event?.preventDefault();
    navigate(route);
  }

  function handleLogout(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    navigate('/logout');
  }

  function handleUpdateProfileAccount(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    navigate('/profile');
  }

  const isEmpty = function(text: string): boolean {
    return text === null || text.match(/^ *$/) !== null;
  };

  function userDisplay() {
    if (loggedUser) {
      let first_last = currentUser.firstName + ' ' + currentUser.lastName;
      return (isEmpty(first_last) ? currentUser.email : first_last.trim());
    } else {
      return <FormattedMessage id="global.account" />;
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = event.target.value;

    if (search !== null && (search.length > 2 || search.length === 0)) {
      dispatch(filterProducts(search))
    }
  };

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const handleUserMenuClose = () => {
    setAnchorElUser(null);
  };

  const selectLocale = (event: SelectChangeEvent) => {
    if (context !== undefined && context.selectLanguage !== undefined) {
      context.selectLanguage(event.target.value);
    }

    if (loggedUser) {
      dispatch(updateProfile({locale: event.target.value}));
    }

  };


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isUserMenuOpen}
      onClose={handleUserMenuClose}
    >
      <MenuItem onClick={handleUserMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleUserMenuClose}>My account</MenuItem>
    </Menu>
  );

  return (
    <AppBar position="static" className='app-bar'>
      <Toolbar disableGutters>
        <a href="https://www.dagoba.app/" style={{textDecoration: 'none', color: 'white'}}>
          <img src={logo} style={{width:80,marginLeft:6,marginRight:6}} />
        </a>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem key='catalog-2' onClick={(event) => {
              handleCloseNavMenu();
              handleNavigate("/", event);
            }}>
              <Typography textAlign="center">
                <FormattedMessage id="appbar.catalog" />
              </Typography>
            </MenuItem>
            {userBoutique &&
                <MenuItem key='eshop-2' onClick={(event) => {
                  handleCloseNavMenu();
                  handleNavigate("/dashboard", event);
                }}>
                  <Typography textAlign="center">
	                  <FormattedMessage id="appbar.eshop" />
                  </Typography>
                </MenuItem>
            }
            {userAdmin &&
              <MenuItem key='products' onClick={(event) => {
                  handleCloseNavMenu();
                  handleNavigate("/admin/catalog", event);
                }}>
                <Typography textAlign="center">
                  <FormattedMessage id="appbar.products" />
                </Typography>
              </MenuItem>
            }
            {userProduction &&
              <MenuItem key='productions' onClick={(event) => {
                handleCloseNavMenu();
                handleNavigate("/admin/production", event);
              }}>
                <Typography textAlign="center">
                  <FormattedMessage id="appbar.production" />
                </Typography>
              </MenuItem>
            }
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button key='catalog-1' onClick={(event) => handleNavigate("/", event)}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
            <FormattedMessage id="appbar.catalog" />
          </Button>
          {userBoutique &&
            <Button key='eshop-1'
                      onClick={(event) => handleNavigate("/dashboard", event)}
                      sx={{ my: 2, color: 'white', display: 'block' }}>
	          <FormattedMessage id="appbar.eshop" />
            </Button>
          }
          {userAdmin &&
			        <Button key='products-1'
			                onClick={(event) => handleNavigate("/admin/catalog", event)}
			                sx={{ my: 2, color: 'white', display: 'block' }}>
                <FormattedMessage id="appbar.products" />
			        </Button>
          }
          {userProduction &&
            <Button key='production-1'
                    onClick={(event) => handleNavigate("/admin/production", event)}
                    sx={{ my: 2, color: 'white', display: 'block' }}>
              <FormattedMessage id="appbar.production" />
            </Button>
          }

          {/*{accessExpiryAt !== undefined && accessExpiryAt !== null && accessToken !== undefined && accessToken !== null &&*/}
          {/*    refreshToken !== undefined && refreshToken !== null &&*/}
          {/*  <Typography variant="body2" sx={{ my: 3, color: 'white', display: 'block' }}>*/}
          {/*    ({new Date(accessExpiryAt).toLocaleTimeString()}, {accessToken.slice(-10)}, {refreshToken.slice(-10)})*/}
          {/*  </Typography>*/}
          {/*}*/}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              autoFocus
              placeholder={`${intl.formatMessage({ id: `global.search`})} ...`}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => {handleSearch(e)}}
              defaultValue={currentFilter}
              // value={currentFilter}
            />
          </Search>
        </Box>

        {/*<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />*/}
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, cursor: 'pointer' }} onClick={handleOpenUserMenu}>
          {userDisplay()}
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
          <IconButton
            sx={{mr: '0px'}}
            size="medium"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit">
            <AccountCircle fontSize='large' />
          </IconButton>
        </Box>
        <Menu
          sx={{ mt: '35px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {loggedUser && ([
            <MenuItem key='update' onClick={(event) => handleUpdateProfileAccount(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.my-account" /></Typography>
            </MenuItem>,
            <MenuItem key='logout' onClick={(event) => handleLogout(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.logout" /></Typography>
            </MenuItem>
          ]) || ([
            <MenuItem key='login' onClick={(event) => handleNavigate("/login", event)}>
              <Typography textAlign="center"><FormattedMessage id="session.login" /></Typography>
            </MenuItem>,
            <MenuItem key='signup' onClick={(event) => handleNavigate("/signup", event)}>
              <Typography textAlign="center"><FormattedMessage id="session.signup" /></Typography>
            </MenuItem>,
            // <MenuItem key='speedy' onClick={(event) => dispatch(openSpeedySignup())}>
            //   <Typography textAlign="center">Speedy signup</Typography>
            // </MenuItem>
          ])}
        </Menu>
        <Box sx={{ mr: '10px', flexGrow: 0, display: { xs: 'flex' } }}>
          <Select className='select-lang' value={context.locale} onChange={selectLocale} >
            <MenuItem value='en'><ReactCountryFlag countryCode="GB" /></MenuItem>
            <MenuItem value='fr'><ReactCountryFlag countryCode="FR" /></MenuItem>
          </Select>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;