import React, {useState, useEffect } from 'react';
import InlineEdit from "../utils/InlineEdit";
import {
  BoutiqueInfos,
  Collection,
  storeCollectionInfo,
  deleteCollection, CollectionProduct, moveProducts, productAttachedLeft, productAttachedRight
} from "./boutiqueSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {useDroppable} from '@dnd-kit/core';
import {DndContext} from '@dnd-kit/core';
import CollectionProductCard from "./CollectionProductCard";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {CSS} from "@dnd-kit/utilities";
import {ReactSortable} from "react-sortablejs";
import {Checkbox, FormControlLabel} from "@mui/material";
import {FormattedMessage} from "react-intl";


export function CollectionCard(props: {id:string, index: number, boutique: BoutiqueInfos, collection: Collection}) {

  const boutique = props.boutique;
  const collection = props.collection;
  const products = collection.products;

  const collProductUniqueId = (colProduct: CollectionProduct) => `${collection.id}-${colProduct.productId || 'new'}-${colProduct.product.id}-${colProduct.version}`

  interface SortableProduct {
    id: string,
    sourceCollectionID: number,
    collectionProduct: CollectionProduct
  }

  const productList = products.map((col_p) => ({
    id: collProductUniqueId(col_p),
    sourceCollectionID: collection.id,
    collectionProduct: col_p,
    attachedRight: productAttachedRight(collection, col_p),
    attachedLeft: productAttachedLeft(collection, col_p)
  }));

  const [movingIndex, setMovingIndex] = useState(-1);


  const handleSortProducts = (newSortabableProducts: SortableProduct[]) => {

    // check new order before dispatching
    let newOrder = newSortabableProducts.map((sortableProduct) => sortableProduct.id).join('-');
    let oldOrder = productList.map((sortableProduct) => sortableProduct.id).join('-');

    if (newOrder !== oldOrder) {

      // console.log("%cIn collection # New order with newSortabableProducts =>", "color: green");
      // newSortabableProducts.forEach((sortableProduct) => {
      //   console.log(sortableProduct);
      // });

      const movingSortableProduct = newSortabableProducts.find((sortableProduct) => sortableProduct.sourceCollectionID !== collection.id)

      if (movingSortableProduct !== undefined) {
        dispatch(moveProducts({
          collectionId: collection.id,
          oldCollectionId: movingSortableProduct.sourceCollectionID,
          movingCollProduct: movingSortableProduct.collectionProduct,
          collProducts: newSortabableProducts.map((sortableProduct) => sortableProduct.collectionProduct)
        }));
      } else {
        if (movingIndex !== -1 && newSortabableProducts.length === productList.length) {
          dispatch(moveProducts({
            collectionId: collection.id,
            oldCollectionId: collection.id,
            movingCollProduct: productList[movingIndex].collectionProduct,
            collProducts: newSortabableProducts.map((sortableProduct) => sortableProduct.collectionProduct)
          }));
        }
      }
    }
    // else {
    //   console.log("%cNo change in order with moving index " + movingIndex, "color: red");
    // }
  }

  // Drop products inside collection
  const {isOver: isOverstyleProductCollection, setNodeRef: setNodeRefProductCollection} = useDroppable({
    id: 'collection-' + collection.id,
    data: {
      collection_id: collection.id,
      // accepts: ['logo','product']
      accepts: ['product']
    }
  });
  const styleProductCollection = {
    border: isOverstyleProductCollection ? '2px #008000 dashed' : '2px #FFFFFF solid',
  };

  const dispatch = useDispatch<AppDispatch>();

  const setInfos = (field: string, value:string) => {
    console.log("setInfos " + field + " " + value)
    dispatch(storeCollectionInfo({id: collection.id, info: {field: field,value: value}}))
  }

  const changeSeparate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(storeCollectionInfo({id: collection.id, info: {field: 'separate', value: event.target.checked}}))
  }

  // console.log("Render CollectionCard ", collection.id, collection.name, ' separate ', collection.separate, ' hasSiblings ', collection.hasSiblings)

  return(
    <div className="collection" key={`col-${collection.id}`} id={`col-${collection.id}`}>
      <div ref={setNodeRefProductCollection} style={styleProductCollection}>
        <IconButton sx={{position: 'absolute', right: '-4px', top: '0px', color: '#808080'}}
                    onClick={() => dispatch(deleteCollection({collectionId:collection.id}))}>
          <DeleteIcon />
        </IconButton>
        {/*<form>*/}
          <div className='title'>
            <InlineEdit key={`col-${collection.id}-name`} text={collection.name}
                        onSetText={ (value:string) => setInfos('name', value)}/>
          </div>
        {/*</form>*/}
        <ReactSortable group='products'
                       className='products' animation={400} delay={1}
                       easing='cubic-bezier(0.65, 0, 0.35, 1)' dragoverBubble={true}
                       list={productList} setList={handleSortProducts} onChoose={
          (evt) => {
            // console.log("onEnd + " + evt.oldIndex);
            if (evt.oldIndex !== undefined) {
              setMovingIndex(evt.oldIndex);
            }
          }
        }>
          {productList.map((sortableProduct, idx) =>
            <CollectionProductCard key={sortableProduct.id}
                                   id={sortableProduct.id}
                                   index={idx}
                                   collectionId={sortableProduct.collectionProduct.collectionId}
                                   collectionProduct={sortableProduct.collectionProduct}
                                   attachedLeft={sortableProduct.attachedLeft}
                                   attachedRight={sortableProduct.attachedRight}
            />)
          }
        </ReactSortable>
        {(collection.hasSiblings || collection.separate) &&
		        <div className={`separate ${collection.separate ? 'active' : ''}`}>
			        <FormControlLabel sx={{marginRight: '5px'}}
			                          label={<FormattedMessage id="collection.separate" />}
			                          control={<Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='medium'
                                                   checked={collection.separate} onChange={changeSeparate} />}
			        />

		        </div>
        }
      </div>
    </div>
  )
}
