import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import Box from "@mui/material/Box";
import {getAccessToken} from "../../../app/sessionAPI";
import {getItems, itemsLoadedSelector} from "./productionSlice";
import WorkshopBoxes from "./WorkshopBoxes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ProductionOrders} from "./ProductionOrders";
import {CustomButton} from "../../utils/CustomButton";
import {useRouteMatch} from "../../../app/global";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {openInFullScreen} from "../../global/globalSlice";
import {SearchOrder} from "./SearchOrder";
import {SearchBoutique} from "./SearchBoutique";
import {SelectWorkshop} from "./SelectWorkshop";
import ProductionDeliveries from "./ProductionDeliveries";
import {ScanQRCode} from "./ScanQRCode";

const PRODUCTION_VIEWS = [
  {
    name: 'Commandes',
    path: '/admin/production/orders',
  },
  {
    name: 'Bacs',
    path: '/admin/production/boxes',
  },
  {
    name: 'Livraisons',
    path: '/admin/production/deliveries',
  }
]

export function ProductionAdmin() {

  const dispatch = useDispatch<AppDispatch>();

  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const userProduction = currentUser?.production

  const itemsLoaded = useSelector(itemsLoadedSelector);

  useEffect(() => {
    console.log("ProductionAdmin useEffect with accessToken: ", accessToken, " and userProduction: ", userProduction)
    if (accessToken && accessToken !== 'undefined' && userProduction && !itemsLoaded) {
      // console.log("ProductionAdmin dispatching getItems")
      dispatch(getItems())
    }

  }, [dispatch, accessToken, currentUser]);

  const routeMatch = useRouteMatch(PRODUCTION_VIEWS.map((view) => view.path));
  console.log("routeMatch => ", routeMatch?.pattern?.path)

  const currentView = PRODUCTION_VIEWS
    .find((view) => routeMatch?.pattern?.path === view.path)?.name || PRODUCTION_VIEWS[0].name;

  console.log("currentView => ", currentView)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const ProductionViewSelector = () => {
    return (<>
      <CustomButton
        id="basic-button"
        backgroundColor='#D0D0D0'
        textColor='#000000'
        hoverBackgroundColor='#B0B0B0'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={'production-view-selector'}
      >
        {currentView}
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {PRODUCTION_VIEWS.map((view, index) => {
          return (
            <MenuItem key={`view-${index}`} onClick={() => {
              handleClose();
              navigate(view.path.replace('/*', ''))
            }}>
              {view.name}
            </MenuItem>
          )
        })}
      </Menu>
    </>);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const box = searchParams.get('box')

  useEffect(() => {
    if (box) {
      console.log("workbox id => ", box)
      dispatch(openInFullScreen({
        name: 'WorkshopBoxDetails',
        displayedId: parseInt(box),
        param: 'box'
      }))
      setSearchParams([])
    }
  }, [searchParams])

  return(<>
    {itemsLoaded &&
      (<Box className='production-admin'>
        <Box className='production-filter' >
          <ProductionViewSelector/>
          <SearchOrder />
          <SearchBoutique />
          <SelectWorkshop />
          <ScanQRCode />
        </Box>
        {currentView === 'Commandes' && <ProductionOrders/>}
        {currentView === 'Bacs' && <WorkshopBoxes/>}
        {currentView === 'Livraisons' && <ProductionDeliveries />}
      </Box>) ||
      <Box className='loading-production'>
        <Box>
          Récupération des données de production ...
        </Box>
        <CircularProgress color="inherit" size={100} />
      </Box>
    }
  </>)
}
