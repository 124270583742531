import React, {useState, useEffect } from 'react';
import InlineEdit from "../utils/InlineEdit";
import {
  storeBoutiqueInfo,
  resetBoutique,
  saveBoutique,
  addCollection,
  BoutiqueAllColors,
  refreshImageUpdating,
  CollectionProduct,
  Collection,
  moveProducts,
  moveCollections,
  checkSubdomainAvailability
} from "./boutiqueSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {CollectionCard} from "./CollectionCard";
import {CustomButton} from "../utils/CustomButton";
import {Button, CircularProgress} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import {FormattedMessage, useIntl} from "react-intl";
import {cable} from "../../app/cable";
import Backdrop from '@mui/material/Backdrop';
import {ReactSortable} from "react-sortablejs";
import BoutiqueLogo from "./BoutiqueLogo";
import ColorPicker from "../utils/ColorPicker";
import {newConfirmEmailLink} from "../sessions/sessionSlice";
import {openSnackBar} from "../global/globalSlice";

export function Boutique() {

  const intl = useIntl();

  const [showBackDrop, setShowBackDrop] = useState(false);

  const boutique = useSelector((state : RootState) => state.boutique.currentBoutique);
  const boutiqueHasChanges = useSelector((state : RootState) => state.boutique.hasChanges);
  const subdomainTaken = useSelector((state : RootState) => state.boutique.subdomainTaken);

  const dispatch = useDispatch<AppDispatch>();

  interface SortableCollection {
    id: string,
    collection: Collection
  }

  const [collectionList, setCollectionList] = useState<SortableCollection[]>([]);


  useEffect(() => {
    // console.log("Boutique useEffect");
    if (boutique) {
      // console.log("Boutique is defined, filling the collection list with " + boutique.collections.length + " collections");

      setCollectionList(boutique.collections.map((coll) => ({
        id: `collection-${coll.id}`,
        collection: coll
      })));

      if (!boutiqueHasChanges) {
        // console.log("boutique has no changes => remove backDrop");
        setShowBackDrop(false);
      }
    }
  }, [boutique]);

  const handleSortCollections = (newSortabableCollections: SortableCollection[]) => {
    // console.log("handleSortCollections");

    // check new order before dispatching
    let newOrder = newSortabableCollections.map((sortableCollection) => sortableCollection.id).join('-');
    let oldOrder = collectionList.map((sortableCollection) => sortableCollection.id).join('-');

    if (newOrder !== oldOrder) {
      dispatch(moveCollections({
        collCollections: newSortabableCollections.map((sortableCollection) => sortableCollection.collection)
      }));
    }
  }


  const setInfos = (field: string, value:string) => {
    console.log("Setting boutique field " + field + " to " + value);
    dispatch(storeBoutiqueInfo({field: field, value: value}))

    if (boutique && field === 'subdomain' && value.length >= 3) {
      console.log("Checking subdomain availability ... ");
      dispatch(checkSubdomainAvailability({id: boutique.id, subdomain: value}));
    }
  }

  const saveActions = () => {
    return (
      <div className="save-actions">
        <CustomButton onClick={() => dispatch(resetBoutique())}><FormattedMessage id="global.cancel" /></CustomButton>
        <Button variant="contained" color="success" disabled={!saveable}
                onClick={() => {
                  if (boutique) {
                    trySavingBoutique();
                  }
                }}>
          <FormattedMessage id="global.save" />
        </Button>
      </div>
    )
  }

  async function trySavingBoutique() {

    setShowBackDrop(true);

    dispatch(refreshImageUpdating());
    const response = await dispatch(saveBoutique()) as any;

    let message = '', severity = '';

    switch(response.type) {

      case 'boutique/saveBoutique/fulfilled':
        message = "boutique.saved";
        severity = "success";
        break;

      case 'boutique/saveBoutique/rejected':
        message = "boutique.not-saved";
        severity = "error";
        break;
    }

    if (message !== '') {
      dispatch(openSnackBar({severity: severity, message: intl.formatMessage({id: message})}));
    }

    setShowBackDrop(false);
  }

  function hasNewCollection() {
    return boutique && boutique.collections && boutique.collections.find((c) => c.id === 0);
  }

  const handleSetLogo = (logoId: Number | null) => {
    dispatch(storeBoutiqueInfo({field: 'logoId', value: logoId}))
  }

  // console.log("boutique render with subdomainTaken=" + subdomainTaken);

  const subDomainHasError = !!subdomainTaken || (boutique && boutique.subdomain.length < 3) || false;
  const subDomainErrorText = !!subdomainTaken ?
      intl.formatMessage({id: "boutique.subdomain-taken"}) : intl.formatMessage({id: "boutique.min-3-char"});
  const saveable = !subDomainHasError && boutiqueHasChanges;

  return(<>
    {boutique &&
        <div className="boutique">
          {/*<form>*/}
            <div className='boutique-header'>
              <div className="boutique-logo">
	              <BoutiqueLogo logoId={boutique.logoId} />
              </div>
	            <div className="boutique-title">
                <div className='name'>
                  <InlineEdit key='name' text={boutique.name} onSetText={(value: string) => setInfos('name', value)}/>
                </div>
                <div className='subtitle'>
                  <InlineEdit key='subtitle' text={boutique.subtitle}
                              onSetText={(value: string) => setInfos('subtitle', value)}/>
                </div>
              </div>
            </div>
            <div className='infos'>
	            <div className='subdomain'>
                https://<InlineEdit key='subdomain' text={boutique.subdomain}
                                    errorMode={subDomainHasError} errorText={subDomainErrorText}
                            onSetText={(value: string) => setInfos('subdomain', value)}/>.dagoba.app
              </div>
              <div className='acronym'>
                Sigle<br/>(<InlineEdit key='acronym' text={boutique.acronym} maxWidth={5}
                            onSetText={(value: string) => setInfos('acronym', value)}/>)
              </div>
              { BoutiqueAllColors(boutique).map((color, index) => {
                return(
                  <div className='color' key={index}>
                    <FormattedMessage id="color.color-number" values={{number: index + 1}} /><br/>
                    <ColorPicker key={`bc-color${index+1}`} propertyName={`color${index+1}`} displayColor={true}
                                 whiteAllowed={index !== 0} color={color} setColor={setInfos} opened={false} />
                  </div>
                )})}
            </div>
          {/*</form>*/}
            {collectionList.length > 0 &&
              // <ReactSortable group='collections'
              //                className='collections' animation={400} delay={1}
              //                easing='cubic-bezier(0.65, 0, 0.35, 1)'
              //                list={collectionList} setList={handleSortCollections}>
<>
                {collectionList.map((sortableCollection, idx) =>
                  <CollectionCard id={sortableCollection.id} key={`ccard-${sortableCollection.id}`} index={idx}
                                  boutique={boutique} collection={sortableCollection.collection} />
                )}
</>
              // </ReactSortable>
            }
          <>
          {!hasNewCollection() &&
            <div className='add-collection'>
              <IconButton sx={{fontSize:'1.2rem',color: '#A0A0A0'}} onClick={() => dispatch(addCollection())}>
                <AddIcon /> <FormattedMessage id="collection.new" />
              </IconButton>
            </div>}
          </>
          <>
            {boutiqueHasChanges && saveActions()}
          </>  
        </div>
      }
      <Backdrop sx={{ color: '#fff', zIndex: 1}} open={showBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )

}
