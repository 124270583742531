import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  ILogoInfos,
  storeLogoInfo,
  saveLogo,
  removeLogo,
  editLogo,
  editedLogoSelector,
  editedLogoSavingSelector,
  editedLogoHasChangesSelector,
  NEW_LOGO_NAME,
  editedLogoImageChangedSelector,
  logoDeletableSelector, logoUsageExplanation, logoUsageExplanationHTML
} from "./logosSlice";
import {Button, CircularProgress, Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import {CustomButton} from "../utils/CustomButton";
import DialogContentText from "@mui/material/DialogContentText";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {ImageType} from "react-images-uploading/dist/typings";
import InlineEdit from "../utils/InlineEdit";
import {openConfirmDialog} from "../global/globalSlice";

import WhiteBgArticle from './img/white_bg_article.jpg';
import DarkBgArticle from './img/dark_bg_article.jpg';
import {FormattedMessage, useIntl} from "react-intl";
import {SelectLogoBg} from "./SelectLogoBg";
import LogoVersionsCard from "./LogoVersionsCard";
import LogoCard from "./LogoCard";
import Box from "@mui/material/Box";
import {BoudaToolTip} from "../utils/BoudaToolTip";

const maxFileSize = 990000; // 990K

function LogoForm() {

  const editedLogo = useSelector(editedLogoSelector);
  const logoDeletable = useSelector(logoDeletableSelector);
  const logoHasChanges = useSelector(editedLogoHasChangesSelector);
  const logoImageChanged = useSelector(editedLogoImageChangedSelector);
  const saving = useSelector (editedLogoSavingSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [nameError, setNameError] = useState(false);

  const [images, setImages] = React.useState([] as ImageType[]);

  const intl = useIntl();

  useEffect(() => {
    if (editedLogo && editedLogo.logo_url !== null && editedLogo.logo_url !== '' && editedLogo.logo_url !== 'new') {
      setImages([{dataURL: editedLogo.logo_url}]);
    }
  }, [editedLogo, logoHasChanges]);

  const logoChanged = () => (editedLogo && logoHasChanges);

  const logoIsUsed = (editedLogo && editedLogo.id !== 0 && editedLogo.usage.length > 0);

  const logoNameChangePrompt = intl.formatMessage({ id: "logos.give-name"});
  const editedLogoName = (editedLogo && editedLogo.name !== NEW_LOGO_NAME) ? editedLogo.name : logoNameChangePrompt;
  const logoNameDefined = () => (editedLogo && editedLogo.name !== NEW_LOGO_NAME && editedLogo.name !== logoNameChangePrompt);

  const setInfos = (field: string, value:string|boolean|number|null) => {
    console.log("setInfos with field " + field + " and value " + value);
    dispatch(storeLogoInfo({field: field,value: value}))
    if (field === 'name' && value !== NEW_LOGO_NAME && value !== logoNameChangePrompt) {
      setNameError(false);
    }
  }

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    if (imageList.length > 0) {
      if (imageList[0].dataURL !== undefined) {
        setInfos('logo_url', 'new');
      } else {
        setInfos('logo_url', '');
      }
    } else {
      setInfos('logo_url', 'none');
    }
    // console.log("%cSetting the state to images= " + JSON.stringify(imageList), "color:green");
    setImages(imageList);
  };

  const handleSave = () => {

    if (editedLogo && logoNameDefined()) {
      let saved_logo;

      if (images.length > 0) {
        if (images[0].file !== undefined || images[0].dataURL !== undefined) {
          // console.log("%csaving logo with new image " + JSON.stringify(images[0]), "color:red");

          const filePassed = images[0].dataURL;
          // console.log("%cfile passed " + JSON.stringify(filePassed), "color:purple");
          saved_logo = {...editedLogo, file: filePassed} as ILogoInfos;
        } else {
          // console.log("saving logo with same image");
          saved_logo = {...editedLogo, file: null} as ILogoInfos;
        }
      } else {
        // console.log("saving logo without image");
        saved_logo = {...editedLogo, file: null, logo_url: 'none'} as ILogoInfos;
      }
      // setImages([]);
      // console.log("%cBefore dispatch save logo with infos " + JSON.stringify(saved_logo), "color:blue");
      dispatch(saveLogo(saved_logo))

    } else {
      // console.log("Cannot save logo without name")
      setNameError(true);
    }
  }

  const handleCancel = () => {
    dispatch(editLogo(null))
  }

  const handleDelete = () => {
    if (editedLogo && logoDeletable) {
      dispatch(openConfirmDialog({
        title: intl.formatMessage({ id: "logos.delete-title"}, {name: editedLogo.name}),
        message: intl.formatMessage({ id: "logos.delete-question"}),
        confirm: intl.formatMessage({ id: "logos.delete-confirm"}),
        action: () => {
          if (editedLogo) {
            dispatch(removeLogo(editedLogo.id));
          }
        }}
      ));
    }
  }

  const setLogoBg = (newBg:number) => {
    setInfos('for_dark_bg', newBg);
  }

  const logoActions = () => {
    if (logoChanged()) {
      return (
        <div className="save-actions">
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.cancel" />
          </CustomButton>
          {saving ? <CircularProgress size={24} color='success' /> :
            <Button variant="contained" color="success" onClick={handleSave}>
              <FormattedMessage id="global.save" />
            </Button>
          }
        </div>
      )

    } else {
      return (
        <div className="save-actions">
          {editedLogo && editedLogo.id !== 0 && logoDeletable &&
            <BoudaToolTip key={`remove-logo-${editedLogo.id}`} placement="bottom-end" sx={{textAlign: 'center'}}
                          title={logoIsUsed ? intl.formatMessage({id: "logos.non-deletable"}) : ''} arrow >
              <span>
                <Button disabled={!!logoIsUsed} variant="contained" color="error" onClick={handleDelete} autoFocus>
                  <FormattedMessage id="global.delete" />
                </Button>
              </span>
            </BoudaToolTip>
          }
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.close" />
          </CustomButton>
        </div>
      )
    }
  }

  const logImageURL = (url:string) => {
    // console.log(timestamp + " %cRendering with image URL => " + url, "color:black;background-color:lightblue");
    return true;
  }

  console.log('Render LogoForm with logoImageChanged=' + logoImageChanged + ' and editedLogo.id = ' + JSON.stringify(editedLogo));

  return (
    editedLogo &&
      <div className="logo-form">
        <div className='name'>
	        <InlineEdit key='name' text={editedLogoName} errorMode={nameError}
                        onSetText={(value: string) => setInfos('name', value)}/>
        </div>

        <div className='logo-edit'>
          <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={1}
            maxFileSize={maxFileSize}
            acceptType={['png']}
          >
            {({
                imageList,
                onImageUpload,
                errors,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps
              }) => (<>
              <Grid container spacing={1} className='logos'>

                <Grid item xs={12} sm={6} className='image-wrapper'>

                  { editedLogo.automated && <div className='open-file-explanation'>
                    <TransitEnterexitIcon /> <FormattedMessage id="logos.open-file-explanation" />
                  </div>}

                  <div className={`dropzone ${editedLogo.for_dark_bg >= 1 ? 'dark-bg' : ''}`}>
                    {imageList.length == 0 &&
                      <button className='load-logo' style={isDragging ? {backgroundColor: "#ddffdd"} : undefined}
                              onClick={onImageUpload} {...dragProps}>
                          <FormattedMessage id="logos.click-or-drop" />
                      </button>
                    }
                    <>
                      {imageList.map((image, index) => (
                        <div key={`${editedLogo.id}-${index}`} className='image-item'>
                          {image.dataURL && logImageURL(image.dataURL) && <>
                            <img key={`${editedLogo.id}-${index}-${image.dataURL.slice(-20)}`} src={image.dataURL}/>
                            <div className="image-change">
                              <IconButton onClick={() => onImageUpdate(index)} sx={{size:'small'}}>
                                <ImageSearchIcon />
                              </IconButton>
                            </div>
                            {!editedLogo.automated &&
                            <div className="image-delete">
                              <IconButton onClick={() => onImageRemove(index)} sx={{size: 'small'}}>
                                <DeleteIcon/>
                              </IconButton>
                            </div>
                            }
                          </>
                          }
                        </div>
                      ))}
                    </>
                  </div>
                  {errors && <div className='errors'>
                    {errors.acceptType && <span><FormattedMessage id="logos.type-not-allowed" /></span>}
                    {errors.maxFileSize && <span><FormattedMessage id="logos.max-file-size" values={{size: maxFileSize / 1000}} /></span>}
                    {errors.resolution && <span><FormattedMessage id="logos.required-resolution" /></span>}
			            </div>}
                </Grid>

                <Grid item xs={12} sm={6} className='logo-versions'>
                  {
                    editedLogo.id === 0 && logoImageChanged &&
                    <div className='logo-versions-header'>
                      <FormattedMessage id="logos.save-to-calculate-versions" />
                    </div>
                  }
                  {editedLogo.id !== 0 && <>
                      <div className='logo-versions-header'>
                        <FormattedMessage id="logos.calculated-versions" />
                      </div>
                      <LogoVersionsCard logo={editedLogo} />
                      {logoIsUsed && <>
                        <div className='logo-versions-header'>
                          <FormattedMessage id="logos.used-versions" />
                        </div>
                          <div className='usage'
                               dangerouslySetInnerHTML={{ __html: logoUsageExplanationHTML(editedLogo) }} />
                        </>
                      }
                  </>}
                </Grid>
              </Grid>

              <Box className='logo-select-bg'>
                {editedLogo.id !== 0 && <div>
                  <SelectLogoBg logo={editedLogo} onSetBg={setLogoBg} editable={!editedLogo.automated || logoImageChanged} />
                </div>}
              </Box>

              <Grid container spacing={1} className='logos'>
                {imageList[0] &&
                  <Grid container spacing={1} className='logo-previews'>
                    <Grid item xs={12} sm={6} className='preview-article'>
                      <div className='preview-logo-title'>
                        <FormattedMessage id="logos.rendering-white-bg" />
                      </div>
                      <img src={WhiteBgArticle}/>
                      <div className='logo-overlay'>
                        <img src={imageList[0].dataURL}/>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} className='preview-article'>
                      <div className='preview-logo-title'>
                        <FormattedMessage id="logos.rendering-dark-bg" />
                      </div>
                      <img src={DarkBgArticle}/>
                      <div className='logo-overlay'>
                        <img src={imageList[0].dataURL}/>
                      </div>
                    </Grid>

                  </Grid>
                }

              </Grid>
            </>)}
          </ImageUploading>
        </div>
        {logoActions()}
      </div>
  )

}

export default LogoForm;