import axios from "./axios";

const PRODUCTION_ITEMS_URL = "/production/items";

export async function getProductionItems() {
  return axios
      .get(PRODUCTION_ITEMS_URL)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
}
