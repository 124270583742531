import React from "react";

const TShirtSvg = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M13.3,30.8c0.9-7.3,1.3-16-0.8-22.1C12.3,8,12,7.4,11.8,6.9c-2.4,1.5-4.2,4-4.7,6.9c-1,5-2,10-3.1,14.9
					c0.8,0.6,2.1,1.4,3.9,1.8C10.2,31.2,12.1,31,13.3,30.8L13.3,30.8z"/>
          <path d="M16,17.7c0.1,3.4,0,7.2-0.5,11.5v28.4c0,1.4,1.1,2.5,2.5,2.5h28.1c1.4,0,2.5-1.1,2.5-2.5V30
					c-0.5-4.5-0.7-8.7-0.6-12.3c0.2-4,0.7-7.4,1.7-10.1C50,7,50.2,6.5,50.4,6l-1.9-0.8l-2.9-1.2l0,0c-0.3-0.1-0.7-0.2-1-0.2
					c0,0,0,0,0,0h-3.1l-5.1,8.9c-0.9,1.6-2.5,2.5-4.3,2.5c-1.8,0-3.4-0.9-4.3-2.5l-5.1-8.9h-3.1c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0.1
					c-0.2,0-0.4,0.1-0.6,0.2l-2.9,1.2L13.6,6c0.2,0.5,0.4,1,0.6,1.6C15.2,10.3,15.8,13.7,16,17.7L16,17.7z"/>
          <path d="M50.7,30.7c1.2,0.2,3,0.3,5.1-0.3c1.8-0.4,3.2-1.2,4.1-1.7c0,0,0,0,0,0l-3.1-14.9c-0.6-2.9-2.3-5.3-4.7-6.9
					C52,7.4,51.7,8,51.5,8.6C49.4,14.8,49.8,23.5,50.7,30.7L50.7,30.7z"/>
          <path d="M29.4,11.7c0.8,1.3,2.1,1.5,2.6,1.5c0.5,0,1.8-0.1,2.6-1.5l4.6-7.9l0-0.1H24.8l0,0.1L29.4,11.7L29.4,11.7z"
          />
        </g>
      </svg>
  );
}


export default TShirtSvg;

