import React from "react";

const Headwear = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M52.9,20.3c0-1.3-1.1-2.4-2.4-2.4c-0.7,0-1.2,0.3-1.7,0.7c0.8,0.5,2.3,1.6,3.7,2.8
		C52.8,21.1,52.9,20.7,52.9,20.3z M21,24.2c-3.1,1.2-10.8,2.2-14.8,2.2c-4.1,0-1,4.9,1.7,7.1c11.8,9.4,24.1,6.7,27.2,5.8
		C26.3,31.8,22.5,26.8,21,24.2z M56.2,26.7c-1.3-1.8-2.6-3.3-4-4.6c-0.9-0.8-1.9-1.5-2.7-2.1c-0.4-0.3-1.1-0.8-1.1-0.8
		c-9-5.3-17.9-1.4-20-0.4c-2.4,1.1-5.6,4.4-6.3,5c0.4,0.6,0.8,1.5,1.6,2.4c8.8,10.2,12.7,12.7,12.7,12.7
		c11.1,8.5,19.4,10.4,20.2,10.1C57.7,48.4,63.5,38.4,56.2,26.7z"/>
        </g>
      </svg>
  );
}


export default Headwear;

