import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET, ModelInfo} from "./global";
import {BoutiqueInfos, CollectionProduct, RequestPreviewData} from "../features/dashboard/boutiqueSlice";
import {IProductSearch} from "./catalog";


const BOUTIQUES_URL = "/boudists/boutiques";
const UPDATE_BOUTIQUE_URL = "/boudists/update_boutique";
const UPDATE_COLLECTION_URL = "/boudists/update_collection";
const PREVIEW_BOUTIQUE_PRODUCT_URL = "/boudists/preview_boutique_product";
const CHECK_SUBDOMAIN_AVAILABILITY_URL = "/boudists/check_subdomain_availability";

export async function fetchBoutiques() {
  return axios
    .get(BOUTIQUES_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function getSubdomainAvailability(payload:{ id: number | null, subdomain:string }) {

  // console.log("Calling boutiqueAPI getSubdomainAvailability");
  const data = {
    boutique_id: payload.id,
    subdomain: payload.subdomain,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .put(CHECK_SUBDOMAIN_AVAILABILITY_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateBoutique(boutique: BoutiqueInfos) {
  const data = {
    boutique: boutique,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //   },
  // };

  return axios
    .patch(UPDATE_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function patchCollectionInfo(accessToken: string, collection_id:number|null, info: ModelInfo) {
  const data = {
    id: collection_id,
    field: info.field,
    value: info.value,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .patch(UPDATE_COLLECTION_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function previewBoutiqueProduct(requestInfos: RequestPreviewData) {
  const data = {
    infos: requestInfos,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //   },
  // };

  return axios
    .patch(PREVIEW_BOUTIQUE_PRODUCT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
