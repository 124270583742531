import React from 'react';
import Box from "@mui/material/Box";
import ProductionStatuses from "./ProductionStatuses";
import {
  currentColorsSelector,
  currentStatusIdSelector,
  ISelectedOrder,
  productionOrdersSelector
} from "./productionSlice";
import ProductionOrder from "./ProductionOrder";
import {useSelector} from "react-redux";

export function ProductionOrders() {

  const productionOrders = useSelector(productionOrdersSelector);
  const currentStatusId = useSelector(currentStatusIdSelector);

  return(
    <Box className='production-orders'>
      <ProductionStatuses />
      <Box sx={{display: 'flex', flexDirection: 'column', pl: 1, pr:0, m:0, width:'100%'}}>
        <Box className='productionList'
          // sx={{height: 'calc(100vh - 238px)', width: '100%',}}
        >
          {productionOrders && productionOrders.length > 0 &&
            productionOrders
              .filter((order: ISelectedOrder) => order.items
                .filter((item) => item.statusId === currentStatusId).length > 0)
              .map((order: ISelectedOrder, index) =>
                <ProductionOrder key={`production-${index}`} order={order} statusId={currentStatusId}
                                 fullScreen={false} />
              )
          }
        </Box>
      </Box>
    </Box>
  )
}
