import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import produce from "immer";
import {RootState} from "../../app/store";
import {AlertColor} from "@mui/material";

// export type AlertColor = 'success' | 'info' | 'warning' | 'error';

export interface ISnackBarState {
  snackBarOpened: boolean;
  snackBarSeverity: AlertColor;
  snackBarMessage: string;
  noAutoClose: boolean;
}

export interface IConfirmDialogState {
  dialogOpened: boolean;
  dialogTitle: string;
  dialogMessage: string;
  dialogConfirm: string;
  dialogAction: (() => void) | null;
}

export interface IFullScreenComponent {
  name: string;
  displayedId: number;
  param: string;
}

export interface IGlobalState {
  fullScreenState: IFullScreenComponent | null;
  snackBarState: ISnackBarState;
  confirmDialogState: IConfirmDialogState;
  backDropState: {
    showBackDrop: boolean,
  };
}
const initialState: IGlobalState = {
  fullScreenState: null,
  snackBarState: {
    snackBarOpened: false,
    snackBarSeverity: "success",
    snackBarMessage: "",
    noAutoClose: false
  },
  confirmDialogState: {
    dialogOpened: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogConfirm: "",
    dialogAction: null
  },
  backDropState: {
    showBackDrop: false,
  },
};


export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    openInFullScreen: (state, action) => {
      console.log("openInFullScreen with action: ", JSON.stringify(action))
      state.fullScreenState = action.payload;
    },
    closeFullScreen: (state) => {
      state.fullScreenState = null;
    },
    showBackDrop: (state) => {
      state.backDropState.showBackDrop = true;
    },
    hideBackDrop: (state) => {
      state.backDropState.showBackDrop = false;
    },
    openSnackBar: (state, action) => {
      state.snackBarState.snackBarOpened = true;
      state.snackBarState.snackBarSeverity = action.payload.severity;
      state.snackBarState.snackBarMessage = action.payload.message;
      state.snackBarState.noAutoClose = action.payload.noAutoClose;
    },
    closeSnackBar: (state) => {
      state.snackBarState.snackBarOpened = false;
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialogState.dialogOpened = true;
      state.confirmDialogState.dialogTitle = action.payload.title;
      state.confirmDialogState.dialogMessage = action.payload.message;
      state.confirmDialogState.dialogConfirm = action.payload.confirm;
      state.confirmDialogState.dialogAction = action.payload.action;
    },
    closeConfirmDialog: (state) => {
      state.confirmDialogState.dialogOpened = false;
      state.confirmDialogState.dialogTitle = '';
      state.confirmDialogState.dialogMessage = '';
      state.confirmDialogState.dialogConfirm = '';
      state.confirmDialogState.dialogAction = null;
    },
  }
});

export const {
  openInFullScreen,
  closeFullScreen,
  showBackDrop,
  hideBackDrop,
  openSnackBar,
  closeSnackBar,
  openConfirmDialog,
  closeConfirmDialog,
} = globalSlice.actions;


export const showBackDropState = (state: RootState) => state.global.backDropState.showBackDrop;

export const snackBarState = (state: RootState) => state.global.snackBarState;
export const confirmDialogState = (state: RootState) => state.global.confirmDialogState;

export const fullScreenComponent = (state: RootState) => state.global.fullScreenState;

export default globalSlice.reducer;








