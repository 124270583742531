import React from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {currentStyle,selectStyle } from "./catalogSlice";
import {ProductStyles} from "../../app/catalog";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useIntl} from "react-intl";

export function SelectStyle() {

  const intl = useIntl();
  const styleSelected = useAppSelector(currentStyle);
  const dispatch = useDispatch();

  const changeStyle = (event: SelectChangeEvent) => {
    dispatch(selectStyle(event.target.value))
  };

  return (
    // <FormControl sx={{ ml: 1, minWidth: 100, mt:'-27px' }} size="small" className='select-tool'>
    <FormControl sx={{ ml: 1, minWidth: 100 }} size="small" className='select-tool'>
      <FormHelperText className={`title-select ${styleSelected !== 'all' ? 'active' : ''}`}sx={{ml:0}}>
        {intl.formatMessage({id: 'catalog.filter-by-style'})}
      </FormHelperText>
      <Select value={styleSelected} onChange={changeStyle} className={`select-style ${styleSelected !== 'all' ? 'active' : ''}`}>
        <MenuItem key='style-all' value='all'>{intl.formatMessage({id: 'catalog.style-all'})}</MenuItem>
        {ProductStyles.map((style,i) => (
          <MenuItem key={`style${i+1}`} value={style}>{intl.formatMessage({id: `catalog.style-${style. toLowerCase()}`})}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )

}
