import React from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {currentCategory, selectCategory} from "./catalogSlice";
import {CatalogCategories} from "../../app/catalog";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";

export function SelectCategory() {

  const currentCategoryValue = useAppSelector(currentCategory);
  const dispatch = useDispatch();

  const changeCategory = (event: SelectChangeEvent) => {
    dispatch(selectCategory(event.target.value))
  };

  return (
    // <FormControl sx={{ m: 1, minWidth: 150, mt:'-20px' }} size="small" className='select-tool'>
    <FormControl sx={{ m: 1, minWidth: 150 }} size="small" className='select-tool'>
      <FormHelperText sx={{ml:0}}>Filtrer par categorie</FormHelperText>
      <Select value={currentCategoryValue || 'all'} onChange={changeCategory}>
        <MenuItem key='cat-all' value='all'>Toutes</MenuItem>
        {CatalogCategories.map((category, i) => (
          <MenuItem key={`category${i+1}`} value={category.code}><FormattedMessage id={`category.${category.code}`} /></MenuItem>
        ))}
      </Select>
    </FormControl>
  )


}
