import React from "react";

const PantsSvg = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M44.3,7.5c0.2,0,0.4,0,0.6,0V5c0-0.7-0.5-1.2-1.2-1.2H20.3c-0.7,0-1.2,0.5-1.2,1.2v2.6c0.2,0,0.4,0,0.6,0
					H44.3L44.3,7.5z"/>
          <path d="M19.4,17.8c3.4-0.7,4.9-2.9,5.5-4c0.2-0.4,0.7-0.6,1.2-0.5l0.2,0.1c0.6,0.2,0.9,0.9,0.6,1.4
					c-0.3,0.5-0.7,1.2-1.3,1.9c-1,1.2-2.9,2.7-5.8,3.3c-0.6,0.1-1.1-0.3-1.2-0.9l0-0.2C18.5,18.4,18.9,17.9,19.4,17.8L19.4,17.8z
					 M37.7,13.3l0.2-0.1c0.5-0.1,1,0.1,1.2,0.5c0.6,1.2,2.1,3.3,5.5,4c0.5,0.1,0.9,0.6,0.8,1.1l0,0.2c-0.1,0.6-0.6,1-1.2,0.9
					c-2.9-0.6-4.8-2.1-5.8-3.3c-0.6-0.7-1-1.4-1.3-1.9C36.8,14.2,37.1,13.5,37.7,13.3L37.7,13.3z M17.3,12.2l0.4,47.2
					c0,0.7,0.6,1.3,1.4,1.3h5.1c0.7,0,1.3-0.5,1.3-1.2l5-35.4c0.2-1.7,2.7-1.7,2.9,0l5.4,35.4c0.1,0.7,0.7,1.2,1.3,1.2h5.1
					c0.8,0,1.4-0.6,1.4-1.4V12.1c0-1.3-1.1-2.3-2.3-2.3H19.7C18.4,9.8,17.3,10.9,17.3,12.2L17.3,12.2z"/>
        </g>
      </svg>
  );
}


export default PantsSvg;

