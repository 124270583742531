import axios from "axios";
import {
  getAccessToken, getRefreshToken, removeAccessToken,
  requestNewRefreshToken,
  storeSessionTokens
} from "./sessionAPI";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

customFetch.interceptors.request.use(
  async (config) => {
    const token = getAccessToken() || "";
    if (token && config && config.headers) {
      config.headers["Authorization"] = ` bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      removeAccessToken();

      // console.log(error.response.status + " error, trying to refresh token...")

      const refreshToken = getRefreshToken();

      if (refreshToken && refreshToken !== "undefined") {
        const resp = await requestNewRefreshToken(refreshToken);

        // console.log("%crefresh token response: " + JSON.stringify(resp), "color: red");

        if (resp.access.error) {
          // console.log("refresh token error, redirecting to login page...")
          window.location.href = "/login";
        } else {
          // console.log("%crefresh token succeeded, new tokens are stored in local storage", "color: green");
          storeSessionTokens(resp.access.access_token, resp.access.refresh_token)

          customFetch.defaults.headers.common[
              "Authorization"
              ] = `Bearer ${resp.access_token}`;

          return customFetch(originalRequest);
        }

      } else {
        // console.log("refresh token not found, redirecting to login page...")
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default customFetch;


