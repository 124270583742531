import React, {useState, useEffect } from 'react';
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store";
import {ILogoInfos, editLogo} from "./logosSlice";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {useDraggable} from "@dnd-kit/core";

function LogoCard(props: {logo: ILogoInfos, editable: boolean}) {

  const {logo, editable} = props;
  const dispatch = useDispatch<AppDispatch>();

  const logoClass = logo.for_dark_bg >= 1 ? 'dark-bg' : '';

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: 'logo-' + logo.id,
    data: {
      type: 'logo',
      logo: logo
    }
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  return (
    // <Grid item xs={12} sm={6} key={logo.id} className='logo-container' ref={setNodeRef} style={style}>
    <Grid item xs={12} sm={6} className='logo-container' ref={setNodeRef} style={style}>
      <IconButton {...attributes} {...listeners}
                  sx={{position: 'absolute', left: '-2px', top: '1px', color: '#A0A0A0', cursor:'grab'}}>
        <DragIndicatorIcon sx={{width: '0.8em', height: '0.8em',}} />
      </IconButton>
      <div className={`logo ${logoClass}`} onClick={() => {
        if (editable) {
          dispatch(editLogo(logo));
        }
      }}>
        <BoudaToolTip arrow placement="top" title={logo.name}>
          {
            ((logo.logo_url !== null && logo.logo_url !== '') &&
				        <img src={logo.logo_url} alt={logo.name} />) ||
			        <div className="no-logo">
				        <div className="icon">
					        <ImageSearchIcon />
				        </div>
			        </div>
          }
        </BoudaToolTip>
      </div>
    </Grid>
  )

}

export default LogoCard;