import Button, {ButtonProps} from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// export const CustomButton = styled(Button)({
//   backgroundColor: 'black',
//   color: 'white',
//   '&:hover': {
//     backgroundColor: 'grey',
//   },
// }) as typeof Button;

interface CustomButtonProps extends ButtonProps {
  textColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

export const CustomButton = styled(Button)<CustomButtonProps>(
  ({ theme, textColor, backgroundColor, hoverBackgroundColor }) => ({
    color: textColor || 'white',
    backgroundColor: backgroundColor || 'black',
    '&:hover': {
      backgroundColor: hoverBackgroundColor || 'grey',
    },
  })
);

// export default CustomButton;