import React, {useState, useEffect } from 'react';
import ProductCard from "../catalog/ProductCard";
import {useDraggable} from '@dnd-kit/core';
import {productVariantFromColor, ProductColorImages, SelectedProduct} from "./selectionSlice";
import {Grid} from "@mui/material";
import {openProduct} from "../productDetails/productDetailsSlice";
import ColorTag from "../catalog/ColorTag";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {IProduct} from "../../app/product";
import Box from "@mui/material/Box";
import GenderVersion from "../catalog/GenderVersion";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {useAppSelector} from "../../app/hooks";
import {productBoutiquesAndCollectionsSelector} from "../dashboard/boutiqueSlice";

function SelectedProductCard(props: {selectedProduct: SelectedProduct, catalogView: boolean}) {

  const {selectedProduct, catalogView} = props;

  const productBoutiquesAndCollections = useAppSelector((state) => productBoutiquesAndCollectionsSelector(state, selectedProduct.product.id));

  const assigned = productBoutiquesAndCollections.length > 0;

  const [displayedProduct, setDisplayedProduct] = useState<IProduct>(selectedProduct.product);

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: 'product-' + selectedProduct.product.id,
    data: {
      type: 'product',
      product: selectedProduct.product,
      colors: selectedProduct.colors,
      otherVersions: selectedProduct.otherVersions ?
          selectedProduct.otherVersions.map(cp => ({product: cp.product, colors: cp.colors})) : []
    }
  })

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  const dispatch = useDispatch<AppDispatch>();

  const displayedProductColors = () => {
    if (displayedProduct == selectedProduct.product || selectedProduct.otherVersions === undefined) {
      return selectedProduct.colors;
    } else {
      const displayedColors = selectedProduct.otherVersions
        .find(connectedSelProduct => connectedSelProduct.product == displayedProduct)?.colors;

      return displayedColors || selectedProduct.colors
    }
  }

  const firstVariant = productVariantFromColor(displayedProduct, displayedProductColors()[0]?.color);

  const imgScaleDown = displayedProduct.imgScaleDown

  if (selectedProduct.colors.length == 0) return null;

  const hoverGenderCallback = (product: IProduct, hovering: boolean) => {
    if (hovering) {
      setDisplayedProduct(product)
    } else {
      setDisplayedProduct(selectedProduct.product)
    }
  }

  function otherSelectedVersions() {
    if (!selectedProduct.otherVersions || selectedProduct.otherVersions.length === 0) return null;

    return(
      <Box className='otherSelectedVersions' >
        {selectedProduct.otherVersions.map(connectedSelProduct =>
          <GenderVersion key={connectedSelProduct.product.id} product={connectedSelProduct.product} hoverCallback={hoverGenderCallback} />)}
      </Box>
    )
  }

  // console.log("Rendering SelectedProductCard " + selectedProduct.product.title)

  return (
    <Grid item xs={12} sm={6} className={`sel-product ${(assigned && !catalogView) ? 'assigned' : ''}`}
          key={selectedProduct.product.id} ref={setNodeRef} style={style}>
      {!catalogView && <IconButton className='grab' {...attributes} {...listeners}
                  sx={{position: 'absolute', left: '-2px', top: '1px', color: '#A0A0A0', cursor:'grab'}}>
        <DragIndicatorIcon sx={{width: '0.8em', height: '0.8em',}} />
      </IconButton>
      }
      <div className="product-tbn" onClick={() => dispatch(openProduct(displayedProduct))}>
        <BoudaToolTip title={displayedProduct.title} arrow placement="left">
          <div className='first-img'>
            {firstVariant &&
              <img src={firstVariant.images[0]}
                   style={{width:`${100 - imgScaleDown}%`,marginTop:`${imgScaleDown/2}%`,marginBottom:`${imgScaleDown/2}%`}} />
            }
          </div>
        </BoudaToolTip>
        {otherSelectedVersions()}
        <div className='other-img'>
          {displayedProductColors().map((colorImages:ProductColorImages) => {
            const variant = productVariantFromColor(displayedProduct, colorImages.color);
            return (variant &&
                <ColorTag key={`${variant.hexColor1}-${variant.hexColor2}`}
                          color1={variant.hexColor1} color2={variant.hexColor2}
                          active={false} size='small' />
            )
          })}
        </div>
      </div>

    </Grid>
  )

}

export default SelectedProductCard;