import React from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {
  currentGender,
  selectGender,
} from "./catalogSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPerson} from "@fortawesome/free-solid-svg-icons/faPerson";
import {faPersonDress} from "@fortawesome/free-solid-svg-icons/faPersonDress";
import {faChildReaching} from "@fortawesome/free-solid-svg-icons/faChildReaching";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {FormControl, FormHelperText, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useIntl} from "react-intl";

export function SelectGender() {

  const intl = useIntl();

  const gender = useAppSelector(currentGender);
  const dispatch = useDispatch();

  const handleGender = (
    event: React.MouseEvent<HTMLElement>,
    newGender: number | 0,
  ) => {
    dispatch(selectGender(newGender))
  };

  return (
    // <FormControl sx={{ m: 1, minWidth: 150, mt:'-20px' }} size="small" className='select-gender'>
    // <FormControl sx={{ ml: 1, mt:'-27px' }} size="small" className='select-gender'>
    <FormControl sx={{ ml: 1, }} size="small" className='select-gender'>
      <FormHelperText className={`title-select ${gender !== 0 ? 'active' : ''}`} sx={{ml:0}}>{intl.formatMessage({ id: 'gender.articles_all_genders'})}</FormHelperText>
      <ToggleButtonGroup value={gender} exclusive onChange={handleGender}>
        <ToggleButton value={0}>
          <BoudaToolTip arrow placement="bottom" title={intl.formatMessage({ id: 'gender.articles_for_all'})}>
          <span>
            <FontAwesomeIcon icon={faPerson} size='2x' className='adult' />
            <FontAwesomeIcon icon={faPersonDress} size='2x' className='adult' />
            <FontAwesomeIcon icon={faChildReaching} size='1x' className='child' />
          </span>
          </BoudaToolTip>
        </ToggleButton>

        <ToggleButton value={2} className={`tgbut ${gender == 2 ? 'active' : ''}`}>
          <BoudaToolTip arrow placement="bottom" title={intl.formatMessage({ id: 'gender.articles_for_women'})}>
            <FontAwesomeIcon icon={faPersonDress} size='2x' className='adult' />
          </BoudaToolTip>
        </ToggleButton>
        <ToggleButton value={3} className={`tgbut ${gender == 3 ? 'active' : ''}`}>
          <BoudaToolTip arrow placement="bottom" title={intl.formatMessage({ id: 'gender.articles_for_children'})}>
            <FontAwesomeIcon icon={faChildReaching} size='2x' className='child' />
          </BoudaToolTip>
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>

  )
}
