import React from "react";

const BagSvg = (height: number) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
        <g className="icon">
          <path d="M15.5,23.9c0-1.2,0.8-2.2,1.9-2.5c0,0.5,0,0.9,0,1.4c0,0.5,0.3,0.8,0.7,0.8c0.4,0,0.6-0.4,0.6-0.8
					c0-0.5,0-1,0-1.4c1.2,0.3,2,1.3,2,2.6c0,1.4-1.2,2.6-2.6,2.6C16.6,26.5,15.5,25.4,15.5,23.9L15.5,23.9z M22,10.9
					c2.3-3.4,5.5-5.5,8.7-5.8c3.5-0.3,7,1.4,9.8,4.7c1.6,1.9,2.8,4.3,3.6,6.9H19.4C20,14.6,20.9,12.7,22,10.9L22,10.9z M48.4,23.9
					c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6c0-1.2,0.7-2.1,1.8-2.5c0,0.5,0,0.9,0,1.4c0,0.4,0.3,0.8,0.6,0.8l0,0
					c0.4,0,0.7-0.3,0.7-0.8c0-0.5,0-1,0-1.5C47.4,21.6,48.4,22.6,48.4,23.9L48.4,23.9z M43.1,40.7c-0.4,0.4-1,0.7-1.6,0.8v1.3
					c0,0.1,0,0.2-0.1,0.2l-1.2,2c0,0-0.1,0-0.1,0l-1.2-2c0-0.1-0.1-0.2-0.1-0.2v-1.3H23c-1.6,0-2.9-1.3-2.9-2.9
					c0-1.6,1.3-2.9,2.9-2.9H41c1.6,0,2.9,1.3,2.9,2.9C43.9,39.5,43.6,40.2,43.1,40.7L43.1,40.7z M46.1,59.8l10.1-27.5l-1.9-13.5
					c-0.2-1.2-1.2-2.1-2.4-2.1h-6.5c-0.8-3-2.2-5.8-4-8.1c-3-3.6-6.9-5.5-10.8-5.1c-3.6,0.3-7.1,2.7-9.7,6.4c-1.4,2-2.4,4.4-3,6.9
					h-6c-1.2,0-2.2,0.9-2.4,2.1l-1.9,13l10.4,27.9H46.1L46.1,59.8z"/>
          <path d="M8.8,59.8h7.3l-8.9-24l-2.7,19C4.2,57.4,6.2,59.8,8.8,59.8L8.8,59.8z"/>
          <path d="M55.2,59.8c2.6,0,4.7-2.3,4.3-4.9l-2.6-18.5l-8.6,23.5H55.2L55.2,59.8z"/>
          <path d="M41.5,37.4H22.5c-0.5,0-1,0.4-1,1v0.5c0,0.5,0.4,1,1,1h16.4v-0.8c0-0.1,0.1-0.2,0.2-0.2h2.2
					c0.1,0,0.2,0.1,0.2,0.2v0.8h0c0.5,0,1-0.4,1-1v-0.5C42.4,37.9,42,37.4,41.5,37.4L41.5,37.4z"/>
        </g>
      </svg>
  );
}


export default BagSvg;

