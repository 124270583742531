import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {SelectGender} from "../../catalog/SelectGender";
import {SelectBrand} from "../../catalog/SelectBrand";
import {SelectCategory} from "../../catalog/SelectCategory";
import {SelectStyle} from "../../catalog/SelectStyle";

export default function CatalogSearch() {
  const dispatch = useDispatch();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 1 }} >
      <SelectCategory />
      <SelectStyle />
      <SelectBrand />
      <SelectGender />
    </Box>
  );
}