import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {getProductionItems} from "../../../app/productionAPI";

export interface ICustomer {
  id: number;
  email: string;
}

export interface IDeliveryInfos {
  deliveryOk: boolean;
  address: string;
  mode: string;
}

export interface IPrintInfos {
  name: string;
  file: string;
  raster?: boolean;
  stats?: string;
}

export interface ILogoInfos {
  image: string;
  technique: string;
  print: IPrintInfos;
  print2: IPrintInfos;
  print3: IPrintInfos;
  print4: IPrintInfos;
  print5: IPrintInfos;
}

export interface IPersoInfos {
  fontName: string;
  fontColor: string;
  fontFile: string;
}

export interface IArticleLogo {
  name: string;
  logoInfos: ILogoInfos;
  bgColor: string;
}

export interface IArticlePerso {
  type: string;
  name: string;
  value?: string;
  textInfos?: IPersoInfos;
  logoInfos?: ILogoInfos;
  bgColor?: string;
}

export interface IArticle {
  ref: string;
  variant: string;
  dagobaTitle: string;
  brand: string;
  preview: string;
  supplierTitle: string;
  supplierColor: string;
  supplierSize: string;
  supplierPhoto: string;
  deliveryCode: string;
  productionNotes: string;
  logos: IArticleLogo[];
  persos: IArticlePerso[]
}

export interface IProductionItem {
  id: number;
  qr_code: string;
  orderNumber: string;
  orderDate: string;
  boutique: IProductionBoutique;
  article: IArticle;
  quantity: number;
  statusId: number;
  customer: ICustomer;
  delivery: IDeliveryInfos
}

export interface IWorkshopStatus {
  statusId: number;
  statusName: string;
  statusCode: string;
  bgColor: string;
  fgColor: string;
}

export interface IWorkshopBox {
  id: number;
  name: string;
  qrCode: string;
  position: number;
}

export interface IWorkshopDelivery {
  deliveryCode: string;
  supplier: string;
  procureDate: Date;
  carrier: string;
  status: string;
  deliveredAt: Date;
}

export interface IWorkshop {
  name: string;
  statuses: IWorkshopStatus[];
  prodItems: IProductionItem[];
  boxes: IWorkshopBox[];
  deliveries: IWorkshopDelivery[];
}

export interface IProductionStatusColors {
  bgColor: string;
  fgColor: string;
}

export interface IProductionBoutique {
  id: number;
  name: string;
}

export interface ProductionState {
  workshops: IWorkshop[];
  currentWorkshop: string;
  currentStatusId: number,
  currentColors: IProductionStatusColors,
  currentOrder: string;
  selectedOrders: ISelectedOrder[];
  loadedBoutiques: IProductionBoutique[];
  selectedBoutiques: IProductionBoutique[];
  currentDelivery: string;
  selectedDeliveries: ISelectedDelivery[]
  loaded: boolean;
  shownItem: IProductionItem | null;
  hasChanges: boolean;
  saving: boolean;
  error: boolean;
}

const initialState: ProductionState = {
  workshops: [],
  currentWorkshop: '',
  currentStatusId: 0,
  currentColors: {bgColor: '', fgColor: ''},
  currentOrder: '',
  selectedOrders: [],
  loadedBoutiques: [],
  selectedBoutiques: [],
  currentDelivery: '',
  selectedDeliveries: [],
  loaded: false,
  shownItem: null,
  hasChanges: false,
  saving: false,
  error: false
};

const SEARCH_STATUS = 'En production';

export interface ISelectedItem {
  id: number;
  qr_code: string;
  article: IArticle;
  quantity: number;
  statusId: number;
}

export interface ISelectedOrder {
  orderNumber: string;
  orderDate: string;
  boutique: IProductionBoutique;
  customer: ICustomer;
  delivery: IDeliveryInfos;
  nbItems: number;
  items: ISelectedItem[];
}

export interface IDeliveryItem {
  itemId: number;
  qrCode: string;
  order: string;
  quantity: number;
  sku: string;
  title: string;
  color: string
  size: string;
  brand: string;
  photo: string;
}

export interface ISelectedDelivery {
  code: string;
  nbItems: number;
  items: IDeliveryItem[];
}

const nbArticlesForStatus = (state: ProductionState, statusId: number) => state.selectedOrders
  .reduce((nb, order) => (nb + order.items
      .filter((item) => item.statusId === statusId).length),
    0)

const colorsForStatus = (state: ProductionState, statusId: number): IProductionStatusColors => {
  const status = state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)
    ?.statuses.find((status) => status.statusId === statusId);
  return status ? {bgColor: status.bgColor, fgColor: status.fgColor} : {bgColor: '', fgColor: ''};
}

const setCurrentWorkshop = (state: ProductionState, workshopName: string) => {
  // console.log("Setting workshop to " + workshopName)

  state.currentWorkshop = workshopName;

  const workshop = state.workshops
    .find((workshop) => workshop.name === workshopName);

  if (workshop) {
    // console.log("Found workshop " + workshop.name + " with " + workshop.prodItems.length + " items");

    filterItems(state, workshop);

    const status = workshop.statuses
      .find((status) => nbArticlesForStatus(state, status.statusId) > 0);

    if (status) {
      // console.log("Setting status to " + status.statusName);

      state.currentStatusId = status.statusId;
      state.currentColors = {bgColor: status.bgColor, fgColor: status.fgColor};
    }
  }
}

const deliveryItemFromProductionItem = (item: IProductionItem): IDeliveryItem => (
  {
    itemId: item.id,
    qrCode: item.qr_code,
    order: item.orderNumber,
    quantity: item.quantity,
    sku: item.article.ref,
    title: item.article.supplierTitle,
    color: item.article.supplierColor,
    size: item.article.supplierSize,
    brand: item.article.brand,
    photo: item.article.supplierPhoto,
  })


// find orders with status 'En production'
// for each order, find items with status 'En production'
const filterItems = (state: ProductionState, workshop?: IWorkshop) => {

  // console.log("Filtering orders for workshop " + (workshop ? workshop.name : state.currentWorkshop));

  let filteredWorkshop = workshop || state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)

  if (filteredWorkshop === undefined) {
    // console.log("No workshop found for " + state.currentWorkshop);
    return []
  }

  // Filter orders with search bar values
  const filteredOrders = filteredWorkshop.prodItems
    .filter((item) => !state.currentOrder || item.orderNumber == state.currentOrder)
    .filter((item) => state.selectedBoutiques.length === 0 ||
      state.selectedBoutiques.find((boutique) => boutique.id === item.boutique.id))

  // Reduce orders with items
  const ordersWithItems = filteredOrders.reduce((ordersWithItems, item) => {
      const order = ordersWithItems
        .find((order) => order.orderNumber === item.orderNumber);
      if (order) {
        order.nbItems += item.quantity;
        order.items.push({
          id: item.id,
          qr_code: item.qr_code,
          article: item.article,
          quantity: item.quantity,
          statusId: item.statusId
        });
      } else {
        ordersWithItems.push({
          orderNumber: item.orderNumber,
          orderDate: item.orderDate,
          boutique: item.boutique,
          customer: item.customer,
          delivery: item.delivery,
          nbItems: item.quantity,
          items: [{
            id: item.id,
            qr_code: item.qr_code,
            article: item.article,
            quantity: item.quantity,
            statusId: item.statusId
          }]
        });
      }
      return ordersWithItems;
    }, [] as ISelectedOrder[]);

  state.selectedOrders = ordersWithItems || [];

  const selectedDeliveryItems = filteredOrders
    .reduce((deliveryWithItems, item) => {
      const delivery = deliveryWithItems
        .find((delivery) => delivery.code === item.article.deliveryCode);

      if (delivery) {
        delivery.nbItems += item.quantity;
        delivery.items.push(deliveryItemFromProductionItem(item));

      } else {
        deliveryWithItems.push({
          code: item.article.deliveryCode,
          nbItems: item.quantity,
          items: [deliveryItemFromProductionItem(item)]
        });
      }
      return deliveryWithItems;
    }, [] as ISelectedDelivery[]);

  state.selectedDeliveries = selectedDeliveryItems || [];
}

export const getItems = createAsyncThunk(
  "logos/getLogos",
  async (_, {rejectWithValue}) => {

    const response = await getProductionItems();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    selectWorkshop: (state, action) => {
      setCurrentWorkshop(state, action.payload);
    },
    selectOrder: (state, action) => {
      state.currentOrder = action.payload;
      filterItems(state);
    },
    selectStatus: (state, action) => {
      state.currentStatusId = action.payload;
      state.currentColors = colorsForStatus(state, action.payload);
    },
    selectBoutiques: (state, action) => {
      state.selectedBoutiques = action.payload;
      filterItems(state);
    },
    selectDelivery: (state, action) => {
      state.currentDelivery = action.payload;

    },
    showItem: (state, action) => {
      state.shownItem = action.payload;
    },
    clearItems: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        // console.log("getSelection pending");
        // state.saving = true;
      })
      .addCase(getItems.fulfilled, (state, action: any) => {
        // console.log("getItems fulfilled with " + action.payload.length + " products");
        state.workshops = action.payload.workshops;
        state.loadedBoutiques = action.payload.boutiques;
        setCurrentWorkshop(state, action.payload.workshops[0].name);
        state.loaded = true;
        state.error = false;
      })
      .addCase(getItems.rejected, (state, action: any) => {
        // console.log("getSelection rejected");
        state.loaded = false;
        state.error = true;
      })
  }
});

export const {
  selectWorkshop,
  selectOrder,
  selectStatus,
  selectBoutiques,
  showItem,
  clearItems
} = productionSlice.actions;

export default productionSlice.reducer;

export const workshopListSelector = ((state: RootState) => state.production
  .workshops.map(workshop => workshop.name));

export const currentWorkshopSelector = ((state: RootState) => state.production
  .workshops.find(workshop => workshop.name === state.production.currentWorkshop));
export const currentStatusIdSelector = ((state: RootState) => state.production.currentStatusId);
export const currentColorsSelector = ((state: RootState) => state.production.currentColors);

export const productionOrdersSelector = ((state: RootState) => state.production.selectedOrders);
export const shownItemSelector = ((state: RootState) => state.production.shownItem);

export const itemsLoadedSelector = ((state: RootState) => state.production.loaded);

export const boutiquesSelector = ((state: RootState) => state.production.loadedBoutiques);

export const workshopBoxesSelector = ((state: RootState) => state.production.workshops
  .map(workshop => workshop.boxes));

export const deliveryItemsSelector = ((state: RootState) => state.production.selectedDeliveries);

export const colorsForStatusIdSelector = createSelector(
  [
    state => state.production,
    (state: RootState, statusId: number) => statusId
  ],
  (productionState, statusId) => colorsForStatus(productionState, statusId)
);