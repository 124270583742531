import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// const TooltipColor = '#404040'
const TooltipColor = 'rgba(0,0,0,0.7)'

export const BoudaToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
    color: TooltipColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: TooltipColor,
    fontSize: 18,
    color: 'white',
    padding: '5px 10px',
    // textAlign: 'center',
    whiteSpace: 'pre-line'
    // border: '1px solid white',
  },
}));