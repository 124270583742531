import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../app/store";
import {
  cancelEmailChange,
  confirmEmail,
  confirmPassword, loginUserByToken,
  newConfirmEmailLink,
  sendMagicLink, sendPasswordLink,
  signUpUser,
  updateProfile
} from "./sessionSlice";
import {Controller, useForm} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {openConfirmDialog, openSnackBar} from "../global/globalSlice";
import {useIntl} from "react-intl";
import TextField from "@mui/material/TextField";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import {CustomButton} from "../utils/CustomButton";
import {getAccessToken} from "../../app/sessionAPI";
import {flexbox} from "@mui/system";
import {removeLogo} from "../logos/logosSlice";
import {BoudaToolTip} from "../utils/BoudaToolTip";

function UpdateProfile() {
  const intl = useIntl();
  const {
    control,
    register,
    getValues,
    formState,
    handleSubmit, reset
  } = useForm();
  const {errors, dirtyFields, isSubmitting} = formState;
  const isAnyFieldDirty = Object.keys(dirtyFields).some((field) => dirtyFields[field]);

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const errorMessages = useSelector((state: RootState) => state.session.errorMessages);
  const confirming= useSelector((state: RootState) => state.session.confirming);
  const waiting = useSelector((state: RootState) => state.session.waiting);

  const [otherErrors, setOtherErrors] = useState<Array<string>>([])
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {confirmation_token, password_token} = useParams();

  const resetForm = () => {
    if (currentUser && currentUser.id) {
      reset({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        password: "",
        passwordConfirmation: "",
        currentPassword: ""
      });
    }
  }

  useEffect(() => {
    resetForm()
  }, [reset, currentUser?.id, currentUser?.confirmedAt])

  useEffect(() => {
    if (errorMessages && errorMessages.length > 0) {
      setOtherErrors(errorMessages.map((message: string) =>
        intl.formatMessage({id: 'error.' + message},
          {b: chunks => <b>{chunks}</b>, email: getValues('email') }
        )));
    }
  }, [errorMessages])


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Confirmations
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!confirming && confirmation_token !== undefined) {
      navigate("/profile")
      const response = dispatch(confirmEmail(confirmation_token));
    }
  }, [confirmation_token])

  useEffect(() => {
    if (!confirming && confirmation_token !== undefined && currentUser && currentUser.id && currentUser.confirmedAt) {
      navigate("/profile")
    }
  }, [confirmation_token, currentUser?.id, currentUser?.confirmedAt])

  ////////////////////////////////////////////////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Password reset
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  async function confirmPasswordByToken(password_token: string) {
    const response = await dispatch(confirmPassword(password_token)) as any;

    if (response.type === 'session/confirmPassword/rejected') {

      // if user already logged, we just need to display an error message and put him on update profile page
      if (getAccessToken()) {
        navigate("/profile")
        dispatch(openSnackBar({
          severity: "error",
          message: intl.formatMessage({id: "error.password-token-invalid-signed-in"})
        }));
      } else {
        // if reset password token not good, go back to login page, error message will be displayed there
        navigate("/login")
      }
    }
  }

  useEffect(() => {
    if (!confirming && password_token !== undefined) {
      // arriving on this page with a password token, we need to confirm the password
      confirmPasswordByToken(password_token);
    }
  }, [password_token])

  const definingPassword = !confirming && currentUser && password_token !== undefined;

  ////////////////////////////////////////////////////////////////////////////////////////////////////


  async function submitProfile(profile: any) {
    let preSubmitErrors = [] as Array<string>;

    const needPassword = currentUser?.hasPassword && !definingPassword;
    const shouldUpdatePassword = profile.password !== "";

    if (shouldUpdatePassword && profile.password !== profile.passwordConfirmation) {
      preSubmitErrors.push(intl.formatMessage({id: "session.password-dontmatch"}));
    }

    if (needPassword && profile.currentPassword === "") {
      preSubmitErrors.push(intl.formatMessage({id: "session.current-password-missing"}));
    }

    if (preSubmitErrors.length > 0) {
      // console.log("errors is not empty, aborting submit");
      return setOtherErrors(preSubmitErrors);
    }

    const payload = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      password: profile.password,
      currentPassword: needPassword ? profile.currentPassword : '',
      needPassword: needPassword
    }
    const response = await dispatch(updateProfile(payload)) as any;

    // console.log("Response after submitting the update profile =>", response);
    if (response.error) {
      return(response.error);
    } else {

      dispatch(openSnackBar({
        severity: "success",
        message: intl.formatMessage({id: definingPassword ? "account.password-success" : "account.update-success"})
      }));

      setOtherErrors([])

      // if we were defining a password, we need to change the url to remove the password token
      if (definingPassword) {
        navigate("/profile")
      }
    }

  }

  async function sendNewLink() {
    const response = await dispatch(newConfirmEmailLink()) as any;

    if (response.type === 'session/newConfirmEmailLink/fulfilled') {
      dispatch(openSnackBar({
        severity: "success",
        message: intl.formatMessage({id: "session.new-confirmation-sent"})
      }));
    }
  }

  async function handlePasswordLink() {
    const email = getValues("email")

    if (!email) {
      return setOtherErrors([intl.formatMessage({id: "session.type-email"})])
    }

    if (currentUser) {
      const response = await dispatch(sendPasswordLink({email: email, locale: currentUser.locale})) as any;

      if (response.type === 'session/sendPasswordLink/fulfilled') {
        setOtherErrors([]);
        dispatch(openSnackBar({
          severity: "success",
          message: intl.formatMessage({id: "session.reset-password-sent"},
          {b: chunks => <b>{chunks}</b>, email: email })}))
      }
    }
  }

  async function handleCancelEmailChange() {

    const response = await dispatch(cancelEmailChange()) as any;

    if (response.type === 'session/cancelEmailChange/fulfilled') {
      setOtherErrors([]);
      dispatch(openSnackBar({
        severity: "success",
        message: intl.formatMessage({id: "session.cancel-new-email-done"})}))
    }
  }

  const handleRequestToCancelEmailChange = () => {
    dispatch(openConfirmDialog({
      title: intl.formatMessage({ id: "session.cancel-email-change-title"}, {name: 'toto'}),
      message: intl.formatMessage({ id: "session.cancel-email-change-question"}),
      confirm: intl.formatMessage({ id: "session.cancel-email-change-confirm"}),
      action: () => handleCancelEmailChange()
      }));
  }


  const passwordTitle = () => intl.formatMessage({id: `session.${currentUser?.hasPassword ? 'change' : 'define'}-password`});

  const emailLabel = intl.formatMessage({id: "session.email"})
  const firstNameLabel = intl.formatMessage({id: "account.first-name"})
  const lastNameLabel = intl.formatMessage({id: "account.last-name"})
  const newPasswordLabel = intl.formatMessage({id: "session.new-password"})
  const passwordConfLabel = intl.formatMessage({id: "session.password_confirmation"})
  const currentPasswordLabel = intl.formatMessage({id: "session.current-password"})
  const passwordLabel = (currentUser && currentUser.hasPassword) ? newPasswordLabel : intl.formatMessage({id: "session.password"})


  const verifiedEmail = (currentUser && currentUser.confirmedAt && !dirtyFields.email)
  const verifiedEmailTip = intl.formatMessage({id: `session.${verifiedEmail ? '' : 'un-'}verified-email`})

  // console.log("Render UpdateProfile with currentUser=" + currentUser?.id + " confirming=" + confirming + " confirmation_token=" + confirmation_token + " and password_token=" + password_token);
  
  return (
    <section style={{marginTop:"2em"}}>
      {currentUser && currentUser.id &&
        <Container maxWidth="md">
          <Card sx={{boxShadow:1, maxWidth: 'md'}}>
            <CardContent>
              <Container maxWidth="sm">
                <Typography variant="h4" color="text.primary" gutterBottom>
                  {intl.formatMessage({id: definingPassword ? "session.reset-password" : "session.update-profile"})}
                </Typography>
                {otherErrors.length > 0 ?
                  <Alert severity="error" aria-live="assertive">
                    <AlertTitle>{intl.formatMessage({id: "global.error"})}</AlertTitle>
                    {otherErrors.map((error, index) => {
                      return <p key={`alert-${index}`}>
                        {error}
                      </p>
                    })}
                  </Alert>
                : <></>}
                <form onSubmit={handleSubmit(submitProfile)} className='session-form'>
                  {!definingPassword && <>
                    <FormGroup row={true} id="email-group" sx={{marginTop: "1em"}}>
                      <Controller name="email" control={control} defaultValue="" render={({ field }) =>
                        <TextField {...field} variant="outlined" fullWidth label={emailLabel}
                                   {...register("email", {
                                     required: intl.formatMessage({id: "session.email-not-blank"})
                                   })}
                                   error={!!errors.email} helperText={errors.email?.message as string}
                                  InputProps={{ endAdornment:
                                      <InputAdornment position="end">
                                        <IconButton edge="end" >
                                          <BoudaToolTip title={verifiedEmailTip} placement="top-end">
                                            {verifiedEmail ? <VerifiedUserIcon sx={{color:'green'}} /> : <PrivacyTipIcon sx={{color:'#BE0D00'}} />}
                                          </BoudaToolTip>
                                        </IconButton>
                                      </InputAdornment>
                                  }}
                        />}
                      />
                    </FormGroup>
                    {!currentUser.confirmedAt &&
                      <FormGroup row={true} id="new-confirm-group" sx={{mt: '5px', mb: '25px', justifyContent: 'space-between' }}>
                        <CustomButton disabled={waiting || isSubmitting} variant="contained"
                                      backgroundColor='#D0D0D0' textColor='#000000' hoverBackgroundColor='#B0B0B0'
                                      onClick={() => sendNewLink()} id="new-confirm-button">
                          {intl.formatMessage({id: "session.send-new-confirmation"},{
                                email:  ' : ' + (currentUser.unconfirmed_email || currentUser.email),
                                b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any
                              })
                          }
                        </CustomButton>
                        {currentUser && currentUser.unconfirmed_email && <CustomButton
			                      disabled={waiting || isSubmitting}
			                      variant="contained"
			                      type="reset"
			                      onClick={handleRequestToCancelEmailChange}
			                      id="cancel-button">{intl.formatMessage({id: "global.cancel"})}
	                      </CustomButton>
                        }
                      </FormGroup>
                    }
                    <FormGroup row={true} id="first-name-group" sx={{marginTop: "1em"}}>
                      <Controller name="firstName" control={control} defaultValue="" render={({ field }) =>
                        <TextField {...field} variant="outlined" fullWidth label={firstNameLabel}
                                   {...register("firstName", {
                                     minLength: {
                                        value: 2,
                                        message: intl.formatMessage({id: "account.first-name-min-length"}, {min: 2})
                                     }})}
                                    error={!!errors.firstName} helperText={errors.firstName?.message as string}
                        />}
                      />
                    </FormGroup>
                    <FormGroup row={true} id="last-name-group" sx={{marginTop: "1em"}}>
                      <Controller name="lastName" control={control} defaultValue="" render={({ field }) =>
                        <TextField {...field} variant="outlined" fullWidth label={lastNameLabel}
                                   {...register("lastName")} />}
                      />
                    </FormGroup>
                    <h4>{passwordTitle()}</h4>
                  </>}
                  <FormGroup row={true} id="password-group" sx={{marginTop: "1em"}}>
	                  <Controller name="password" control={control} defaultValue="" render={({ field }) =>
                      <TextField {...field} variant="outlined" type={showPassword ? 'text' : 'password'} fullWidth label={passwordLabel}
                                 {...register("password",{
                                   minLength: {
                                     value: 8,
                                     message: intl.formatMessage({id: "session.password-min-chars"}, {min: 8})
                                   }})} error={!!errors.password}
                                 helperText={(errors.password?.message as string) ||
                                   intl.formatMessage({id: "session.password-min-chars"}, {min: 8})}
                                 InputProps={{ endAdornment:
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" edge="end"
                            onClick={() => setShowPassword(!showPassword)} onMouseDown={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }} />}
	                  />
                  </FormGroup>
                  <FormGroup row={true} id="password-confirmation-group" sx={{marginTop: "1em"}}>
	                  <Controller name="passwordConfirmation" control={control} defaultValue="" render={({ field }) =>
                      <TextField {...field} variant="outlined" type={showPassword ? 'text' : 'password'} fullWidth label={passwordConfLabel}
                                 {...register("passwordConfirmation")} InputProps={{ endAdornment:
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end"
                                        onClick={() => setShowPassword(!showPassword)} onMouseDown={() => setShowPassword(!showPassword)}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }} />}
	                  />
                  </FormGroup>          
                  {!definingPassword && currentUser.hasPassword ?
                    <>
                      <h4>{intl.formatMessage({id: "session.current-password-missing"})}</h4>
                      <FormGroup row={true} id="current-password-group" sx={{marginTop: "1em"}}>

                        <Controller name="currentPassword" control={control} defaultValue="" render={({ field }) =>
                          <TextField {...field} variant="outlined" type={showCurrentPassword ? 'text' : 'password'} fullWidth label={currentPasswordLabel}
                                     {...register("currentPassword", {
                                       required: intl.formatMessage({id: "session.current-password-missing"})
                                     })}
                                     error={!!errors.currentPassword} helperText={errors?.currentPassword?.message as string}
                                    InputProps={{ endAdornment:
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" edge="end"
                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)} onMouseDown={() => setShowCurrentPassword(!showCurrentPassword)}>
                                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                          }} />}
                        />
                      </FormGroup>
                    </> : <></>
                  }
                  <FormGroup row={true} id="submit-group" sx={{marginTop: "1em", justifyContent: 'space-between'}}>
	                  <FormControl>
		                  <CustomButton
				                  disabled={!isAnyFieldDirty || isSubmitting}
				                  variant="contained"
				                  color="secondary"
                          type="reset"
				                  onClick={resetForm}
				                  id="cancel-button">{intl.formatMessage({id: "global.cancel"})}
                      </CustomButton>
	                  </FormControl>
                    <FormControl>
                      <Button
                        disabled={!isAnyFieldDirty || isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                        id="submit-button">{intl.formatMessage({id: "global.save-changes"})}
                      </Button>
                    </FormControl>
                  </FormGroup>
                  {!definingPassword && <FormGroup row={true} sx={{marginTop: "1em"}}>
		                <FormControl fullWidth>
			                <CustomButton
					                disabled={waiting || isSubmitting}
					                variant="contained"
					                backgroundColor='#E0D0E0'
					                textColor='#000000'
					                hoverBackgroundColor='#B0B0B0'
					                onClick={handlePasswordLink}
					                id="send-password">{intl.formatMessage({id: "session.send-password"})}
                      </CustomButton>
		                </FormControl>
	                </FormGroup>}
                </form>
              </Container>
            </CardContent>
          </Card>
        </Container> || // if user is not connected, show the errors why (confirmation failed or password reset failed)
        <Container maxWidth="md">
          <Card sx={{boxShadow:1, maxWidth: 'md'}}>
            {(confirmation_token !== undefined || password_token !== undefined) &&
              <CardContent>
                <Container maxWidth="sm">
                  <Typography variant="h4" color="text.primary" gutterBottom>
                    {intl.formatMessage({
                      id: ((confirmation_token !== undefined) ? "session.confirm-email" : (
                        password_token !== undefined ? "session.reset-password" : "global.unknown-request"
                      ))})}
                  </Typography>
                  {otherErrors.length > 0 &&
                    <Alert severity="error" aria-live="assertive">
                      <AlertTitle>{intl.formatMessage({id: "global.error"})}</AlertTitle>
                      {
                        otherErrors.map((error, index) => <p key={`alert-${index}`}>{error}</p>)
                      }
                    </Alert>
                  }
                </Container>
              </CardContent>
            }
          </Card>
        </Container>
      }
    </section>
  )
}

export default UpdateProfile