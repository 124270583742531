import React, {useState, useEffect, ChangeEvent} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  GENDER_CHILD,
  GENDER_MAN, GENDER_NONE,
  GENDER_UNISEX,
  IProductColorDefinition, IProductImage, ProductImageTemplates,
} from "../../../app/catalog";
import ColorTag from "../../catalog/ColorTag";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {storeDefinitionColor, storeDefinitionSizeDagoba} from "../../catalog/catalogSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {HexCode, ProductColors, TextColor} from "../../../app/color";
import {useIntl} from "react-intl";
import {BoudaToolTip} from "../../utils/BoudaToolTip";
import RecyclingIcon from '@mui/icons-material/Recycling';
import {getBase64} from "../../../app/utils";

export function ProductColorInfos(props: {
  colorDefinition: IProductColorDefinition,
  gender: number | undefined,
  editable: boolean,
  colorIndex: number,
  setActiveColorIndex: (colorIndex: number) => void,
}) {

  const intl = useIntl();

  const {colorDefinition, gender, editable, colorIndex, setActiveColorIndex} = props;
  const dispatch = useDispatch<AppDispatch>();

  // console.log("Render ProductColorInfos " + JSON.stringify(colorDefinition))

  const setColorProp = (propName: string, value:string) => {
    // console.log("Set : " + propName + " to value " + value)

    let newColorDefinition;

    switch(propName) {
      case 'add':
      case 'remove':
        newColorDefinition = {...colorDefinition, [propName]: (value === 'true')};
        break;
        
      default:
        newColorDefinition = {...colorDefinition, [propName]: value};
        break;
    }

    dispatch(storeDefinitionColor(newColorDefinition))
  }

  const toggleImageDelete = (colorIndex: number) => {

    // console.log('toggleImageDelete ' + colorDefinition.supplierColor + ' ' + colorIndex)
    
    const newColorDefinition = {...colorDefinition,
      images: colorDefinition.images.map((image, i) => {
        if (i === colorIndex) {
          return {...image, delete: !image.delete}
        } else {
          return image;
        }
      })}
    dispatch(storeDefinitionColor(newColorDefinition))
  }

  const IMAGE_SIDES = ['X', 'FRT', 'BCK', 'LFT', 'RGT', 'SID', 'TOP', 'FT2', 'BK2', 'SD2'];
  const toggleImageSide = (imgIdx: number) => {

    const newColorDefinition = {...colorDefinition,
      images: colorDefinition.images.map((image, i) => {
        if (i === imgIdx) {
          const current_side = image.side || '-';
          const newSide = IMAGE_SIDES[(IMAGE_SIDES.indexOf(current_side) + 1) % IMAGE_SIDES.length];
          return {...image, side: newSide}
        } else {
          return image;
        }
      })}
    dispatch(storeDefinitionColor(newColorDefinition))
  }


  const toggleImageReplace = (imgIdx: number) => {

    const newColorDefinition = {...colorDefinition,
      images: colorDefinition.images.map((image, i) => {
        if (i === imgIdx) {
          return {...image, replace: !image.replace}
        } else {
          return image;
        }
      })}
    dispatch(storeDefinitionColor(newColorDefinition))
  }

  const add = () => {
    // console.log('add ' + colorDefinition.supplierColor)
    setColorProp('add', colorDefinition.add ? '' : 'true')
  }
  const remove = () => {
    // console.log('remove ' + colorDefinition.supplierColor)
    setColorProp('remove', colorDefinition.remove ? '' : 'true')
  }

  const discontinuedClass = colorDefinition.discontinued ? 'disc' : '';
  const extraClass = colorDefinition.extra ? 'extra' : '';
  const missingClass = colorDefinition.missing ? 'missing' : '';
  const removeClass = colorDefinition.remove ? 'remove' : '';
  const addClass = colorDefinition.add ? 'add' : '';

  const defineHexColors = (color1:string, color2:string) => {

    if (colorDefinition.hexColor1 !== color1) {
      setColorProp('hexColor1', color1);
    }

    if (biColor && colorDefinition.hexColor2 !== color2) {
      setColorProp('hexColor2', color2);
    }
  }

  const textColor1 = TextColor(colorDefinition.color1);
  const textColor2 = TextColor(colorDefinition.color2 || '');

  const imageTemplates = (side: string) => {

    let genderFilter: number;

    switch(gender) {
      case GENDER_NONE:
      case GENDER_UNISEX:
      case GENDER_MAN:
      case GENDER_CHILD:
      case undefined:
        genderFilter = GENDER_UNISEX
        break;

      default:
        genderFilter = gender;
        break;
    }

    return ProductImageTemplates.filter((t) => t.side === side && t.gender === genderFilter);
  }



  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>, imgIdx: number) => {

    // console.log('handleImageUpload ' +imgIdx + " for color " + colorDefinition.supplierColor + ' ' + colorIndex)

    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    // console.log("File uploaded : " + file.name + " " + file.size + " " + file.type);

    getBase64(file)
      .then(result => {
        // console.log("Image encoded into base64")
        const newColorDefinition = {...colorDefinition,
          images: colorDefinition.images.map((image, i) => {
            if (i === imgIdx) {
              // console.log("Adding b64 in color " + colorIndex + " to image " + imgIdx +  " " + image.name)
              return {...image, imgBase64: result}
            } else {
              return image;
            }
          })}
        dispatch(storeDefinitionColor(newColorDefinition))
      })
      .catch(err => {
        console.log("Error encoding into base64 : " + err);
      });
  };

  const addImageButton = (imgIdx: number, loaded:boolean) => {
    return(
      <>
        <input id={`raised-button-file-${colorIndex}`} type="file" accept="image/*" hidden
               onChange={(e) => handleImageUpload(e, imgIdx)} />
        <label htmlFor={`raised-button-file-${colorIndex}`}>
          <AddBoxIcon className={`new ${loaded ? 'loaded' : ''}`} />
        </label>
      </>
      )
  }

  const imageSnippet = (image: IProductImage, imgIdx: number) => {
    return(
      <div key={`img${colorIndex}-${imgIdx}`} className="color-img-item">
        <>
          <BoudaToolTip title={image.name} placement="top">
            {(image.url == 'New' && addImageButton(imgIdx, !!image.imgBase64)) ||
              <img key={image.name} className={image.delete ? 'delete' : ''} src={image.url}
                   onClick={() => {
                     if (editable) {
                       toggleImageDelete(imgIdx);
                     }
                   }}
              />
            }
          </BoudaToolTip>
          {image.side &&
              <div className={`img-side ${image.side == 'X' ? '' : 'selected'} ${image.side.includes('lw') ? 'low' : ''}`} onClick={() => {
                if (editable) {
                  toggleImageSide(imgIdx);
                }
              }}>
                {image.side}
              </div>
          }
        </>
        { editable && image.replacementName &&
            <BoudaToolTip title={image.replacementName} placement="bottom">
              <RecyclingIcon className={image.replace ? 'replace' : ''} onClick={() => {
                if (editable) {
                  toggleImageReplace(imgIdx);
                }
              }} />
            </BoudaToolTip>
        }
      </div>
    )
  }

  const selectImageTemplate = (side: string) =>
    <div key={`ditp${side}`} className="img-select">
      <span className="side">{side[0].toUpperCase()}</span>
      <Select key={`${side}Image`} id={`${side}Image`} className="template-select" size="small"
              value={colorDefinition[`${side}TemplateImage` as keyof IProductColorDefinition] || 0}
              onChange={(e) => setColorProp(`${side}TemplateImage`, e.target.value as string)} >
        <MenuItem key={`no-${colorIndex}`} value={0} style={{height:24}}>
        </MenuItem>
        {imageTemplates(side).map((template  , i) => (
          <MenuItem key={`tpl-${side}-${i}`} value={template.name} style={{height:24}}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
    </div>

  const biColor = !!colorDefinition.hexColor2 ||
    colorDefinition.supplierColor?.includes("/") || colorDefinition.dagobaColor?.includes("/");
  // console.log("biColor " + biColor + " because hexColor2 = " + colorDefinition.hexColor2 + " supplierColor = " + colorDefinition.supplierColor + " dagobaColor = " + colorDefinition.dagobaColor)

  const colorInfosBox = () => (
    <Box key={`pci${colorIndex}`} className={`color-definition ${discontinuedClass} ${extraClass} ${missingClass} ${removeClass} ${addClass}`}
         sx={{p: 0}} onMouseOver={() => setActiveColorIndex(colorIndex)}>

      <ColorTag color1={colorDefinition.hexColor1 || '#FFFFFF'} color2={colorDefinition.hexColor2 || (biColor ? '#000000' : '')}
                active={false} size='big' defineHexColors={defineHexColors} />

      <div className='color-groups'>
        <Select key={`c1-${colorIndex}-cg`} className={`color-group ${textColor1}`} value={colorDefinition.color1 || ''}
                onChange={(e) => {
                    if (colorDefinition.color1 !== e.target.value) {
                      setColorProp('color1', e.target.value);
                    }}}
                style={{"backgroundColor": HexCode(colorDefinition.color1)}}>
          {ProductColors.map(color => (
            <MenuItem key={`c1-${colorIndex}-cg-${color.code}`} value={color.code}
                      style={{backgroundColor: color.hexcode, color:color.textcolor, height:24}}>
              {intl.formatMessage({ id: `color.${color.code}`})}
            </MenuItem>
          ))}
        </Select>

        {biColor && <Select key={`c2-${colorIndex}-cg`} className={`color-group ${textColor2}`} value={colorDefinition.color2 || ''}
                            onChange={(e) => {
                              if (colorDefinition.color2 !== e.target.value) {
                                setColorProp('color2', e.target.value);
                              }}}
                            style={{"backgroundColor": HexCode(colorDefinition.color2)}}>
          {ProductColors.map(color => (
            <MenuItem key={`c2-${colorIndex}-cg-${color.code}`} value={color.code}
                      style={{backgroundColor: color.hexcode, color:color.textcolor, height:24}}>
              {intl.formatMessage({ id: `color.${color.code}`})}
            </MenuItem>
          ))}
        </Select>}
      </div>

      <TextField key={`dagobaColor${colorIndex}`} id="dagobaColor" label="Dagoba Color" size="small"
                 defaultValue={colorDefinition.dagobaColor || colorDefinition.supplierColor || ''}
                 onChange={(e) => setColorProp('dagobaColor', e.target.value)}
      />
      <TextField key={`supplierColor${colorIndex}`} id="supplierColor" label="Supplier Color" size="small"
                 defaultValue={colorDefinition.supplierColor || ''}
                 onChange={(e) => setColorProp('newSupplierColor', e.target.value)}
      />
      <TextField key={`colorCode${colorIndex}`} id="colorCode" label="Code" size="small" sx={{width: '70px', fontSize: 10}}
                 defaultValue={colorDefinition.colorCode || ''}
                 onChange={(e) => setColorProp('colorCode', e.target.value)}
      />
      <TextField key={`colorSKU${colorIndex}`} id="colorSku" label="SKU" size="small" sx={{width: '100px', fontSize: 10}}
                 defaultValue={colorDefinition.colorSku || ''}
                 onChange={(e) => setColorProp('colorSku', e.target.value)}
      />
      <div className='color-imgs'>
        {colorDefinition.images.map((image, imgIdx) => {
          if (image.url) {
            return(imageSnippet(image, imgIdx));
          } else {
            return (
              <BoudaToolTip title={image.name} placement="top-end">
                <div key={`img${colorIndex}-${imgIdx}`} className='color-img-item box'>{imgIdx === 0 ? 'frt' : 'bck'}</div>
              </BoudaToolTip>
            )
          }
        })}
      </div>

      <div className='color-img-templates'>
        {['back', 'right', 'left'].map((side) => selectImageTemplate(side))}
      </div>

      { editable && (
        <BoudaToolTip title={tooltip} placement="top" arrow>
          {colorDefinition.missing ? <AddBoxIcon className='action add' onClick={add}/> :
            <DisabledByDefaultIcon className='action remove' onClick={remove}/>}
        </BoudaToolTip>
        )
      }
    </Box>
  )

  const statusExplanation = () => {
    if (colorDefinition.missing) {
      return intl.formatMessage({ id: 'color.missing' });
    } else if (colorDefinition.extra) {
      return intl.formatMessage({ id: 'color.extra' });
    } else if (colorDefinition.discontinued) {
      return intl.formatMessage({ id: 'color.discontinued' });
    } else {
      return '';
    }
  }

  const tooltip = statusExplanation();

  // const colorInfosBoxWithTooltip = (tooltip: string) => (
  //   <BoudaToolTip title={tooltip} placement="top" arrow>
  //     {colorInfosBox()}
  //   </BoudaToolTip>
  // )

  // return(tooltip ? colorInfosBoxWithTooltip(tooltip) : colorInfosBox());
  return(colorInfosBox());
}
