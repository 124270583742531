import React from "react";
import {CatalogCategories, ICatalogCategory, ICategoryState, ICatalogState} from "../../app/catalog";
import {useDispatch} from "react-redux";
import {categoryStatuses, selectCategory} from "./catalogSlice";
import {useAppSelector} from "../../app/hooks";
import TShirtSvg from "./category/TShirtSvg";
import HoodieSvg from "./category/HoodieSvg";
import JacketSvg from "./category/JacketSvg";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";


const Categories = (props: {catalog: ICatalogState}) => {
  const catalog = props.catalog;
  const dispatch = useDispatch();
  const intl = useIntl();

  const opacity = (categoryInfo: any) => {
    if (categoryInfo) {
      switch (categoryInfo.status) {
        case "Not Fetched":
          return 0.1;
        case "UpToDate":
          return 1;
        case "Loading":
          return 0.5;
        case "Error":
          return 0.2;
        default:
          return 1;
      }
    } else return 0;
  }

  function categoryClass(category: ICatalogCategory) {
    if (category.code === catalog.currentCategory) {
      return "active";
    } else {
      return "";
    }
  }

  const categoryProductCount = (category: ICatalogCategory) => catalog.filteredProducts.filter((p) => p.category === category.code).length;

  const categoryItem = (category: ICatalogCategory) => {
    const productCount = categoryProductCount(category);
    return (
      <li key={category.code} className={`category ${productCount === 0 ? 'empty' : ''} ${categoryClass(category)}`}
          onClick={() => dispatch(selectCategory(category.code))}>
        {category.icon(48)}
        <br/>
        {`${intl.formatMessage({ id: `category.${category.code}-short`})} (${productCount})`}
      </li>
    )
  }

  return (
    // <Box sx={{height: 'calc(100vh)' }} >
    <Box>
      <ul className="categories">
        {CatalogCategories.map((category) => categoryItem(category))}
      </ul>
    </Box>
  );
}

export default Categories;

