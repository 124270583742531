import './App.css';
import {ProductDetails} from "./features/productDetails/ProductDetails";
import React, {useEffect, useRef} from "react";
import {useAppSelector} from "./app/hooks";
import {
  productShownSelector
} from "./features/productDetails/productDetailsSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./app/store";
import {BrowserRouter as Router, Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import AppBar from "./features/appbar/AppBar";
import {
  closeConfirmDialog, closeFullScreen,
  closeSnackBar,
  confirmDialogState, fullScreenComponent,
  showBackDropState,
  snackBarState
} from "./features/global/globalSlice";
import PrivateRoute from "./features/routes/PrivateRoute";
import Logout from "./features/sessions/Logout";
import UpdateProfile from "./features/sessions/UpdateProfile";
import PublicOnlyRoute from "./features/routes/PublicOnlyRoute";
import Login from "./features/sessions/Login";
import Signup from "./features/sessions/Signup";
import {closeSpeedySignup, speedySignupShowing} from "./features/sessions/sessionSlice";
import SpeedySignup from "./features/sessions/SpeedySignup";
import {CatalogCategories} from "./app/catalog";
import {fetchProductsAsync} from "./features/catalog/catalogSlice";
import {
  Alert,
  Button, CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  ThemeProvider
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import Catalog from "./features/catalog/Catalog";
import {Dashboard} from "./features/dashboard/Dashboard";
import DialogContentText from "@mui/material/DialogContentText";
import {CustomButton} from "./features/utils/CustomButton";
import {AdminPage} from "./features/admin/AdminPage";
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "./app/sessionAPI";
import Backdrop from "@mui/material/Backdrop";
import WorkshopBoxDetails from "./features/admin/production/WorkshopBoxDetails";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {CatalogAdmin} from "./features/admin/catalog/CatalogAdmin";
import {ProductionAdmin} from "./features/admin/production/ProductionAdmin";
import {ReadQrCode} from "./features/admin/production/ScanQRCode";
import ProductionOrder from "./features/admin/production/ProductionOrder";
import './features/admin/production/production.scss';

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Roboto Condensed', 'sans-serif'].join(','), // roboto condensed
    fontSize: 15,
    button: {
      textTransform: "none"
    }
  },
});

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = getAccessToken();
  const displayBackDrop = useAppSelector(showBackDropState);
  const productShown = useAppSelector(productShownSelector);
  const showingSpeedySignup = useAppSelector(speedySignupShowing);
  const {snackBarOpened, snackBarSeverity, snackBarMessage, noAutoClose} = useAppSelector(snackBarState);
  const {dialogOpened, dialogTitle, dialogMessage, dialogConfirm, dialogAction} = useAppSelector(confirmDialogState);
  const fullScreen = useAppSelector(fullScreenComponent);

  useEffect(() => {
    CatalogCategories.forEach(category => {
      dispatch(fetchProductsAsync(category.code));
    });
  }, [dispatch]);

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUserNotConfirmed = currentUser !== undefined && currentUser.id !== undefined &&
                      (!!currentUser.unconfirmed_email || !currentUser.confirmedAt);


  const closeDeleteDialogAndAct = (do_action: boolean) => {
    if (do_action && dialogAction) {
      dialogAction();
    }
    dispatch(closeConfirmDialog());
  };

  const confirmDialog = () => {
    return (
      <div>
        <Dialog
          open={dialogOpened}
          onClose={() => closeDeleteDialogAndAct(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => closeDeleteDialogAndAct(false)}>
              <FormattedMessage id="global.cancel" />
            </CustomButton>
            <Button variant="contained" color="error" onClick={() => closeDeleteDialogAndAct(true)} autoFocus>
              {dialogConfirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  type TransitionProps = Omit<SlideProps, 'direction'>;

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" timeout={600} />;
  }

  const fullScreenContent = () => {
    if (!fullScreen) return null;
    switch (fullScreen.name) {
      case 'ReadQrCode':
        return <ReadQrCode />;

      case 'ProductionItem':
        return <ProductionOrder itemId={fullScreen.displayedId} fullScreen={true} />


      case 'WorkshopBoxDetails':
        return <WorkshopBoxDetails workshopBoxId={fullScreen.displayedId}/>;

      default:
        return (
          <div className='full-screen-content'>Unknown full screen component</div>
        );
    }
  }

  const fullScreenWindow = () => {
    if (!fullScreen) return null;

    return(
      <div className='full-screen'>
        {fullScreenContent()}
        <IconButton aria-label="close" onClick={() => dispatch(closeFullScreen())}
                    sx={{position: 'absolute', right: 8, top: 8, color: 'inherit'}}>
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Router>
        {fullScreen && fullScreenWindow() || <>
          <header>
            <AppBar />
          </header>
          <main>
            { loggedUserNotConfirmed &&
                <Link to="/profile" className='no-decoration'>
                  <Alert severity="error" className='account-not-confirmed' >
                    <FormattedMessage id={`session.${!currentUser.unconfirmed_email ? 'not-confirmed' : 'email-not-changed'}`}
                                      values={{b: chunks => <b>{chunks}</b>,
                                        email: currentUser.unconfirmed_email || currentUser.email }}
                    />
                  </Alert>
                </Link>
            }
            <Routes>
              <Route path="/" element={ <Catalog />} />

              <Route path="/signin/:magic_token" element={ <Login />} />
              <Route path="/confirm/:confirmation_token" element={ <UpdateProfile />} />
              <Route path="/password/:password_token" element={ <UpdateProfile />} />
              <Route path="/profile" element={
                <PrivateRoute>
                  <UpdateProfile />
                </PrivateRoute>
              } />
              <Route path="/dashboard/:token" element={ accessToken && <Dashboard/>} />
              <Route path="/dashboard" element={
                <PrivateRoute>
                  {accessToken && <Dashboard/>}
                </PrivateRoute>
              } />
              <Route path="/admin/catalog" element={
                <PrivateRoute>
                  {accessToken && <CatalogAdmin />}
                </PrivateRoute>
              }/>
              <Route path="/admin/production/*" element={
                <PrivateRoute>
                  {accessToken && <ProductionAdmin />}
                </PrivateRoute>
              }/>
              <Route path="/logout" element={
                <PrivateRoute>
                  {accessToken && <Logout/> }
                </PrivateRoute>
              } />
              <Route path="/login" element={
                <PublicOnlyRoute>
                  <Login />
                </PublicOnlyRoute>
              }/>
              <Route path="/signup" element={
                <PublicOnlyRoute>
                  <Signup />
                </PublicOnlyRoute>
              }/>
              <Route path="*" element={
                <p>Sorry this page does not exist !</p>
              }/>
            </Routes>
            {productShown && <ProductDetails product={productShown} />}
            {showingSpeedySignup && <SpeedySignup />}
            <Snackbar open={snackBarOpened} onClose={() => dispatch(closeSnackBar())} autoHideDuration={noAutoClose ? null : 7000}
                      anchorOrigin={{ vertical:'top', horizontal:'center'}} TransitionComponent={TransitionUp} >
              <Alert onClose={() => dispatch(closeSnackBar())} severity={snackBarSeverity ?? 'success'} sx={{ width: '100%' }}>
                {snackBarMessage}
              </Alert>
            </Snackbar>
            {confirmDialog()}
            <Backdrop sx={{ color: '#fff', zIndex: 1}} open={displayBackDrop} >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop sx={{ color: '#fff', zIndex: 1}} open={displayBackDrop} >
              <CircularProgress color="inherit" />
            </Backdrop>
          </main>
        </>}
      </Router>
    </ThemeProvider>
  );
}

export default App;
