// https://joelmturner.com/blog/inline-text-edit-react-hooks

import React, {useCallback, useState, useRef, useEffect, FormEvent} from 'react';
import { useKeyPress, useOnClickOutside } from '../../app/hooks';
import DOMPurify from "dompurify";
import {BoudaToolTip} from "./BoudaToolTip";


function InlineEdit(props:{
  text: string | null,
  onSetText: Function,
  maxWidth?: number,
  errorMode?: boolean,
  errorText?: string
}) {

  const { text, onSetText, errorMode, errorText } = props;

  const maxWidth = props.maxWidth || 1024;

  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(text || '');

  // useEffect(() => {
  //   // console.log("InlineEdit useEffect 0 with text=" + text + " and inputValue=" + inputValue + " setting inputValue to text");
  //   setInputValue(text || '');
  // }, [text]);

  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const enter = useKeyPress('Enter');
  const tab = useKeyPress('Tab');
  const esc = useKeyPress('Escape');

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      // if (inputValue !== text) {
      // console.log("Setting text to " + inputValue + " and closing editor from CLICK outside");
        onSetText(inputValue);
        setIsInputActive(false);
      // console.log("%cInlineEdit after onSetText(inputValue) from CLICK outside", "color: green; font-size: medium");
      // }
    }
  });

  const onEnter = useCallback(() => {
    // if (text && text.startsWith ("Horse")) {
    //   console.log("InlineEdit onEnter with inputValue=" + inputValue + " and enter="  + enter);
    // }
    if (enter) {
      // console.log("Setting text to " + inputValue + " and closing editor from hitting ENTER");
      onSetText(inputValue);
      setIsInputActive(false);
      // console.log("%cInlineEdit after onSetText(inputValue) from  hitting ENTER", "color: red; font-size: medium");
    }
  }, [enter, inputValue, onSetText]);

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(text || '');
      setIsInputActive(false);
    }
  }, [esc, text]);

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive && inputRef !== null && inputRef.current !== null) {
      let input = inputRef.current as HTMLElement;
      input.focus();
      // console.log("InlineEdit just set focus (" + inputValue + ")");
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and close the editor

      // if (text && text.startsWith ("Horse")) {
      //   console.log("UseEffect before calling onEnter")
      // }
      onEnter();
      // if Escape is pressed, revert the text and close the editor
      onEsc();
    }
  }, [onEnter, onEsc, isInputActive]); // watch the Enter and Escape key presses

  const handleInputChange = useCallback(
    (event:FormEvent<HTMLInputElement>) => {
      // sanitize the input a little
      let target = event.target as HTMLInputElement;
      setInputValue(DOMPurify.sanitize(target.value));
    },
    [setInputValue]
  );

  const handleSpanClick = useCallback(() => {
    // console.log("InlineEdit callback handleSpanClick (" + inputValue + "), setting input active");
    setIsInputActive(true)
  }, [setIsInputActive]);

  const handleFocus = (e:any) => {
    e.preventDefault();
    e.target.setSelectionRange(0, e.target.value.length);
  };

  // if (text && text.startsWith ("Horse")) {
  //   console.log("%cRender InlineEdit with text = " + text + " and inputValue = " + inputValue + " and isInputActive = " + isInputActive + " and enter = " + enter, "color: blue; font-size: large");
  // }

  return (
    <BoudaToolTip placement="bottom" title={errorMode ? errorText : ''} arrow disableInteractive={true}
                  sx={{textAlign: 'center'}}>
      <div className={`inline-text ${errorMode ? 'error' : ''}`} ref={wrapperRef}>
      <span
          ref={textRef}
          onClick={handleSpanClick}
          className={`inline-text_copy inline-text_copy--${!isInputActive ? 'active' : 'hidden'} ${errorMode ? 'error' : ''}`}
      >
        {text}
      </span>
        <input
            ref={inputRef}
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            style={{ minWidth: Math.ceil(inputValue.length === 0 ? 1 : inputValue.length) + 'ch' }}
            value={inputValue}
            maxLength={maxWidth}
            onFocus={handleFocus}
            onChange={handleInputChange}
            // onClick={handleInputClick}
            className={`inline-text_input inline-text_input--${isInputActive ? 'active' : 'hidden'} ${errorMode ? 'error' : ''}`}
        />
      </div>
    </BoudaToolTip>
  );
}

export default InlineEdit;