import ColorTag from "../catalog/ColorTag";
import React, {useEffect, useState} from "react";
import {IProduct, ProductPricesString, VariantForColor, VariantInfos} from "../../app/product";
import {useAppSelector} from "../../app/hooks";
import {
  SelectedProducts, ImageAddon, ProductColorImages, ProductImage,
} from "../selection/selectionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid, Tabs, Tab, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {CustomButton} from "../utils/CustomButton";
import {
  AllPersoAddonsActivatedToDefault,
  AddonInfos,
  CollectionProduct,
  openProductConfig,
  PersoInfos,
  saveProductConfig,
  BoutiqueColorToHex,
  DetectBoutiqueColors,
  BoutiqueInfos,
  RequestProductPreview,
  CollectionProductColor, BoutiqueAllColors, RequestPreviewData, RequestPreviewColor
} from "./boutiqueSlice";
import Box from "@mui/material/Box";
import {editLogo, ILogoInfos} from "../logos/logosSlice";
import {LogoAddonsList} from "./LogoAddonsList";
import {FormattedMessage} from "react-intl";
import PersoAddonsList from "./PersoAddonsList";
import SelectProductMargin from "./SelectProductMargin";
import InlineEdit from "../utils/InlineEdit";
import {arrayMoveImmutable} from "../../app/utils";

const MAX_COLORS = 3;



export function CollectionProductConfig(props: {
                      logos: ILogoInfos[],
                      collectionProduct: CollectionProduct,
                      boutique: BoutiqueInfos,
                      show: boolean}) {


  const {logos, collectionProduct, boutique, show} = props;
  const {product, collectionId, version, productId} = collectionProduct
  const currentboutiqueColors = BoutiqueAllColors(boutique)

  const dispatch = useDispatch<AppDispatch>();

  const [tabShown, setTabShown] = useState(0);

  const [colors, setColors] = useState<CollectionProductColor[]>(collectionProduct.colors);

  const firstColorName = colors[0].colorImages.friendlyColor;

  const productAllAddons = colors[0].colorImages.images.
                map((image) => (image.logoAddons.concat(image.persoAddons))).flat()
  const addonExcludedAddons = (addonName: string) => productAllAddons.find(a => a.name === addonName)?.excludes || [];


  //----------------------------------------------------
  //------------------- MARGIN -------------------
  const [margin, setMargin] = useState(collectionProduct.margin);

  //----------------------------------------------------
  //------------------- MARGIN -------------------
  const [customTitle, setCustomTitle] = useState(collectionProduct.customTitle);


  //----------------------------------------------------
  //------------------- PERSO ADDONS -------------------
  //
  const [persoAllowed, setPersoAllowed] = useState<boolean>(collectionProduct.persoAllowed);

  const handlePersoAllowedChange = (allowed:boolean) => {
    if (allowed) {
      setPersoAllowed(true);
      setPersoAddons(allPersoAddonsActivated());
    } else {
      setPersoAllowed(false);
      setPersoAddons([]);
    }
  }
  
  const [persoExpanded, setPersoExpanded] = useState<boolean>(false);

  const initialPersoAddons = collectionProduct.persos.map((perso) => ({
    ...perso,
      colorList: perso.colorList.map((color) => DetectBoutiqueColors(currentboutiqueColors, color))
    }));
  const [persoAddons, setPersoAddons] = useState<PersoInfos[]>(initialPersoAddons);

  const setPersoInfos = (persoInfos: PersoInfos, colorIndex: number) => {

    // all perso addons of this product color images
    const imagePersoAddons = colors[colorIndex].colorImages.images.map((image) => image.persoAddons).flat()

    // we need to know which background has the changed addon, so we can apply the same color to all addons with the same background
    const persoAddon = imagePersoAddons.find((adInf) => adInf.id === persoInfos.addonId);
    // console.log("Setting persoAddon", JSON.stringify(persoAddon));

    // Just for debug
    // const persoAddonName = productAllAddons.find((adInf) => adInf.name === persoInfos.name)?.name || '';
    const persoAddonName = persoAddon?.name || '';
    if (persoAddon === undefined || persoAddonName !== persoInfos.name) {
      console.log("%cERROR: persoAddon is undefined or persoAddonName " + persoAddonName + " !== persoInfos.name " + persoInfos.name, "color: red");
      return;
    } else {
      // console.log("%cOK: Setting perso addon " + persoAddonName + " with lightBg=" + persoAddon.lightBg + " to " + JSON.stringify(persoInfos), "color: green");
    }

    let newPersoAddons = [...persoAddons];

    // Search if the addon is already present in the list
    // by addon name (not addon id, because the id is not the same for each color)
    let persoAddonInfosIndex = newPersoAddons.findIndex((adInf) => adInf.name === persoInfos.name);

    if (persoAddonInfosIndex === -1) {
      console.log("\tAdding...");
      newPersoAddons.push(persoInfos);

      // After adding a logo, deactivate all the addons that are excluded by the new logo
      const excludedAddons = addonExcludedAddons(persoInfos.name);
      newPersoAddons = newPersoAddons.filter((adInf) => !excludedAddons.includes(adInf.name));

    } else {
      // console.log("\tUpdating at index " + persoAddonInfosIndex + " value " + JSON.stringify(persoInfos));
      newPersoAddons[persoAddonInfosIndex] = persoInfos;
    }

    // console.log("Should update perso addons colors of all addons of color " + colorIndex);
    newPersoAddons.forEach((perso, index  ) => {

      // find the addon with the same name on this product color images
      const originalColorAddon = imagePersoAddons.find((adInf) => adInf.name === perso.name);
      // console.log("In color " + colorIndex + " perso addon " + index + " " + perso.name + " lightBg=" + (originalColorAddon ? originalColorAddon.lightBg : '?') + " " + JSON.stringify(perso));
      if (!originalColorAddon) {
        console.log("%c\tCannot find originalColorAddon for ", "color: red", perso.name);
      } else {
        if (originalColorAddon.lightBg == persoAddon.lightBg) {
          // console.log("%c\tUpdating persoAddon color " + perso.colorList[colorIndex] + " to " + persoInfos.colorList[colorIndex], "color: green");
          newPersoAddons[index].colorList[colorIndex] = persoInfos.colorList[colorIndex];
        }
      }
    })

    setPersoAddons(newPersoAddons);
  }

  const persoAddonsWithoutLogoExcluded = (logoAddonName: string, newPersoAddons: PersoInfos[]) => {

    const excludedAddons = addonExcludedAddons(logoAddonName);

    return(newPersoAddons.filter((adInf) => !excludedAddons.includes(adInf.name)));
  }

  const allPersoAddonsActivated = () => AllPersoAddonsActivatedToDefault(colors.map((color) => color.colorImages))

  //----------------------------------------------------
  //------------------- LOGO ADDONS -------------------
  //
  const initialColorLogoAddons = colors.map((color) => color.logoAddons);
  const [colorLogoAddons, setColorLogoAddons] = useState<AddonInfos[][]>(initialColorLogoAddons);

  const allExcludedAddons = colorLogoAddons.map((addons) => addons.
      map(a => addonExcludedAddons(a.name))).flat(2).filter((v,i,a)=>a.indexOf(v)==i);


  // console.log('Rendering with colorLogoAddons ', JSON.stringify(colorLogoAddons));

  const setAddonInfos = (colorIndex: number, addonInfos: AddonInfos) => {
    let newColorLogoAddons = [...colorLogoAddons];
    let newColorLogoAddonsForColor = [...newColorLogoAddons[colorIndex]];

    // We will redefine allowed and activated perso addons after changing the logo addons
    let newPersoAddons = persoAllowed ? allPersoAddonsActivated() : persoAddons;

    // Setting the logoId to 0 means that the logo on the addon is removed
    if (addonInfos.logoId === 0) {
      newColorLogoAddonsForColor = newColorLogoAddonsForColor.filter((adInf) => adInf.addonId !== addonInfos.addonId);
    } else {

      // Search if the addon is already present in the list
      let logoAddonInfosIndex = newColorLogoAddonsForColor.findIndex((adInf) => adInf.addonId === addonInfos.addonId);

      if (logoAddonInfosIndex === -1) {
        newColorLogoAddonsForColor.push(addonInfos);

        // After adding a logo, deactivate all the addons that are excluded by the new logo
        const excludedAddons = addonExcludedAddons(addonInfos.name);
        newColorLogoAddonsForColor = newColorLogoAddonsForColor.filter((adInf) => !excludedAddons.includes(adInf.name));
        
      } else {
        // console.log("Updating logo addon " + addonInfos.name + " to " + JSON.stringify(addonInfos));
        newColorLogoAddonsForColor[logoAddonInfosIndex] = addonInfos;
      }

      // console.log("Setting a logo addon, newPersoAddons before excluding => ", JSON.stringify(newPersoAddons));

      newPersoAddons = persoAddonsWithoutLogoExcluded(addonInfos.name, newPersoAddons);
    }

    setPersoAddons(newPersoAddons);

    newColorLogoAddons[colorIndex] = newColorLogoAddonsForColor;

    // If the first color is changed, we update all the colors with the same selection of activated addons
    // while making sure not to change the potentially already selected logoVersion
    if (colorIndex === 0) {

      // Prepare empties array to recreate addonInfos for all other colors
      const addonInfosForOtherColors: AddonInfos[][] = colors.map((color) => []);

      newColorLogoAddonsForColor.forEach((adInf) => {
        // const addonName = productAddonNames.find((addon) => addon.id === adInf.addonId)?.name;
        const addonName = productAllAddons.find((addon) => addon.id === adInf.addonId)?.name;
        // console.log('Updating all colors with ', JSON.stringify(adInf), ' for addon ', addonName);

          if (addonName) {
            colors.forEach((color, c_idx) => {
              if (c_idx !== 0) {
                const correspondingAddonId = colors[c_idx].colorImages.images.
                map((image) => (image.logoAddons.map((addon) => {
                  return({id: addon.id, name: addon.name})}))).flat().
                find((addon) => addon.name === addonName)?.id;

                const existingAddonInfo = colorLogoAddons[c_idx].find((adInf) => adInf.addonId === correspondingAddonId);

                if (correspondingAddonId)
                  if (!existingAddonInfo || existingAddonInfo.logoId === adInf.logoId) {
                    // console.log('Changing color ', c_idx, ' with addon ', addonName, ' id ', correspondingAddonId, ' existing addonInfo ', existingAddonInfo);
                    addonInfosForOtherColors[c_idx].push({
                      addonId: correspondingAddonId,
                      name: addonName,
                      logoId: adInf.logoId,
                      logoVersionId: adInf.logoVersionId,
                      zoom: adInf.zoom});
                  } else {
                    // console.log('Keeping color ', c_idx, ' with addon ', addonName, ' id ', correspondingAddonId, ' existing addonInfo ', existingAddonInfo);
                    addonInfosForOtherColors[c_idx].push({
                      addonId: correspondingAddonId,
                      name: addonName,
                      logoId: existingAddonInfo.logoId,
                      logoVersionId: existingAddonInfo.logoVersionId,
                      zoom: existingAddonInfo.zoom});
                  }
              }
            });
          }

        }
      );

      addonInfosForOtherColors.forEach((addonInfos, c_idx) => {
        if (c_idx !== 0) {
          newColorLogoAddons[c_idx] = addonInfosForOtherColors[c_idx]
        }
      });
    }

    setColorLogoAddons(newColorLogoAddons);
  }

  //----------------------------------------------------
  //------------------- PERSO COLORS -------------------
  //------------------- BOUTIQUE COLORS -------------------
  //
  // currentboutiqueColors contains color1, color2 and all colors from perso addon color
  const [boutiqueColors, setBoutiqueColors] = useState<string[]>(currentboutiqueColors);

  // back end saves colors as hex values, but we need to use color1, color2, etc. for the front end
  const initialPersoColors = colors.map((color) =>
    DetectBoutiqueColors(currentboutiqueColors, color.persoColor));

  const [persoColors, setPersoColors] = useState<string[]>(initialPersoColors);

  // console.log('Rendering with colors = ' + JSON.stringify(colors) + ' et persoColors ' + JSON.stringify(persoColors));

  const handlePersoColorChange = (colorIndex: number, color: string) => {
    console.log('Changing perso color for color index ', colorIndex, ' to ', color);
    let newPersoColors = [...persoColors];
    newPersoColors[colorIndex] = color;
    setPersoColors(newPersoColors);
  }

  const configChanged = () => {
    return (
      `${customTitle}` !== `${collectionProduct.customTitle}` ||
      margin !== collectionProduct.margin ||
      persoAllowed !== collectionProduct.persoAllowed ||
      JSON.stringify(initialPersoColors) !== JSON.stringify(persoColors) ||
      JSON.stringify(currentboutiqueColors) !== JSON.stringify(boutiqueColors) ||
      JSON.stringify(initialPersoAddons) !== JSON.stringify(persoAddons) ||
      JSON.stringify(initialColorLogoAddons) !== JSON.stringify(colorLogoAddons) ||
      JSON.stringify(collectionProduct.colors.map((color) => color.colorImages.color))
      !== JSON.stringify(colors.map((color) => color.colorImages.color))
    ) 
  }

  const configActions = () => {
    if (configChanged()) {
      return (
        <div className="save-actions">
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.cancel" />
          </CustomButton>
          <Button variant="contained" color="success" onClick={handleSave}>
            <FormattedMessage id="global.save" />
          </Button>
        </div>
      )
    }
  }

  const removeColor = (colorIndex: number) => {
    // console.log('Removing color ' + colorIndex);
    setColors(colors.filter((color, index) => index !== colorIndex));
    setPersoColors(persoColors.filter((color, index) => index !== colorIndex));
    setColorLogoAddons(colorLogoAddons.filter((color, index) => index !== colorIndex));
    setPersoAddons(persoAddons.map((perso) => ({
      ...perso,
      colorList: perso.colorList.filter((color, index) => index !== colorIndex)
    })));
  }

  const moveColorLeft = (colorIndex: number) => {
    // console.log('Moving color ' + colorIndex + ' to the left');
    setColors(arrayMoveImmutable(colors, colorIndex, colorIndex - 1));
    setPersoColors(arrayMoveImmutable(persoColors, colorIndex, colorIndex - 1));
    setColorLogoAddons(arrayMoveImmutable(colorLogoAddons, colorIndex, colorIndex - 1));
    setPersoAddons(persoAddons.map((perso) => ({
      ...perso,
      colorList: arrayMoveImmutable(perso.colorList, colorIndex, colorIndex - 1)
    })));
    setTabShown(colorIndex - 1);
  }

  const handleClose = () => {
    dispatch(openProductConfig(null))
  }

  const handleCancel = () => {
    setColors(collectionProduct.colors);
    setPersoColors(collectionProduct.colors.map((color) =>
                          DetectBoutiqueColors(currentboutiqueColors, color.persoColor)));
    setColorLogoAddons(collectionProduct.colors.map((color) => color.logoAddons));
    setMargin(collectionProduct.margin);
    setCustomTitle(collectionProduct.customTitle);
    setPersoAllowed(collectionProduct.persoAllowed);
    setPersoAddons(initialPersoAddons);
    setBoutiqueColors(currentboutiqueColors);
  }

  const handleSave = () => {
    // Before save, we convert all color codes to hex value
    const persoColorsConverted = persoColors.map((color) => BoutiqueColorToHex(boutiqueColors, color));

    const persoAddonsWithConvertedColors = persoAddons.filter((persoAddon) =>
      !allExcludedAddons.some((excludedAddonName: string) => persoAddon.name === excludedAddonName)).
    map((persoAddon) => (
      {
        ...persoAddon,
        colorList: persoAddon.colorList.map((color) => BoutiqueColorToHex(boutiqueColors, color))
      }));

    // console.log('%cSaving config with persoAddonsWithConvertedColors = ' + JSON.stringify(persoAddonsWithConvertedColors), 'color: green');

    console.log('%cSaving product config => ' + JSON.stringify(persoAddonsWithConvertedColors), 'color: green');

    dispatch(saveProductConfig(
      {
        id: product.id,
        productId: productId,
        collectionId: collectionId,
        version: version,
        margin: margin,
        customTitle: customTitle,
        colors: colors,
        persoAllowed: persoAllowed,
        persos: persoAddonsWithConvertedColors,
        colorLogoAddons: colorLogoAddons,
        persoColors: persoColorsConverted,
        boutiqueColors: boutiqueColors
      }
    ))
  }

    const handleRequestPreview = () => {

    const persoColorsConverted = persoColors.map((color) => BoutiqueColorToHex(boutiqueColors, color));

    const persoAddonsWithConvertedColors = persoAddons.map((persoAddon) => (
      {
        ...persoAddon,
        colorList: persoAddon.colorList.map((color) => BoutiqueColorToHex(boutiqueColors, color))
      }));

    const previewPayload = {
      infos: {
        boutiqueId: boutique.id,
        product: {
          id: product.id,
          productId: productId,
          version: version,
          colors: collectionProduct.colors.map((color, index) => (
            {
              colorName: color.colorImages.color,
              logoAddons: colorLogoAddons[index]
            } as RequestPreviewColor)
          ),
        }
      } as RequestPreviewData,
    }

    // console.log("Preview collProduct payload ", JSON.stringify(previewPayload));
    
    dispatch(RequestProductPreview(previewPayload));
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabShown(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    tabShown: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, tabShown, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabShown !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabShown === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const addonsTotalPrice = () => {
    return(colorLogoAddons[0].reduce((total, addon) => {
      if (addon.logoId && addon.logoVersionId) {
        const logo = logos.find((logo) => logo.id === addon.logoId);
        if (logo) {
          const logoVersion = logo.versions.find((version) => version.id === addon.logoVersionId);
          if (logoVersion) {
            return total + logoVersion.priceCents;
          } else return total;
        } else return total;
      } else return total;
    }, 0));
  }

  const displayedTabIndex = tabShown > (colors.length - 1) ? (colors.length - 1) : tabShown

  console.log("Rendering ProductConfig with " + colors.length + " colors with displayedTabIndex = " + displayedTabIndex + " and colorLogoAddons = " + JSON.stringify(colorLogoAddons));

  return(
    <Dialog fullWidth={true} maxWidth={'lg'} open={show} onClose={handleClose} className='product-config'>
      <DialogTitle>
        <span className='product-title'>
          <InlineEdit text={customTitle || product.title} onSetText={setCustomTitle}/>
        </span>
        {(version > 1) && <span className='product-version'> (v{version})</span>}
        <span className='product-price'>({ProductPricesString(product)})</span>
        <IconButton aria-label="close" onClick={handleClose} sx={{position: 'absolute', right: 8, top: 8, color: 'inherit'}}>
          <CloseIcon />
        </IconButton>
        <Tabs
          value={displayedTabIndex}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >

          {colors.map((color, i) => (
            <Tab key={i} label={
              <div className='color-tab-title'>
                <ColorTag key={`cpc-${color.colorImages.hexColor1}-${color.colorImages.hexColor2}`}
                          color1={color.colorImages.hexColor1} color2={color.colorImages.hexColor2}
                          active={false} size='big' />

                <div className='color-name'>{color.colorImages.friendlyColor}</div>

                {colors.length > 1 && displayedTabIndex === i &&
                  <CloseIcon aria-label="remove"  onClick={() => {removeColor(i)}}
                              sx={{position: 'absolute', right: -16, top: -12, color: 'inherit'}} />
                }
                {i > 0 && displayedTabIndex === i &&
                  <KeyboardDoubleArrowLeftIcon aria-label="move left" onClick={() => {moveColorLeft(i)}}
                              sx={{position: 'absolute', right: 0, top: -12, color: 'inherit'}} />
                }
                </div>
            } />
          ))}
        </Tabs>
        <div className='product-total-price'>
          <Grid container spacing={2} className='price-container'>
            <Grid item xs={12} sm={4} className='color-img'></Grid>
            <Grid item xs={12} sm={8}>

            </Grid>
          </Grid>
        </div>
        {configActions()}
      </DialogTitle>
      <DialogContent>
        {colors.map((color, colorIdx) => (
          <TabPanel key={colorIdx} tabShown={displayedTabIndex} index={colorIdx}>
            {color.colorImages.images.map((image, img_index) => {
              return(
              <Grid container key={image.id} spacing={2} className={img_index == 0 ? '' : 'product-img'}>
                <Grid item xs={12} sm={4} className='color-img'>
                  <img src={image.boutiqueUrl || image.url}/>
                  {img_index == 0 && configChanged() && <div className='request-preview'>
	                  <Button variant="contained" color="primary" onClick={handleRequestPreview}>
		                  <FormattedMessage id="product.request-preview" />
	                  </Button>
                  </div>}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <LogoAddonsList imageAddons={image.logoAddons} colorLogoAddons={colorLogoAddons[colorIdx]}
                                  colorIndex={colorIdx} firstColorName={firstColorName}
                                  excludedAddons={allExcludedAddons} setAddonInfos={setAddonInfos} logos={logos}/>
                  <PersoAddonsList imagePersoAddons={image.persoAddons}
                                 persoAllowed={persoAllowed} setPersoAllowed={handlePersoAllowedChange}
                                 persoColor={persoColors[colorIdx]} setPersoColor={handlePersoColorChange}
                                 boutiqueColors={boutiqueColors} setBoutiqueColors={setBoutiqueColors}
                                   persoExpanded={persoExpanded} setPersoExpanded={setPersoExpanded}
                                 persoAddons={persoAddons}
                                   colorIndex={colorIdx} firstColorName={firstColorName} nbProductColors={colors.length}
                                   excludedAddons={allExcludedAddons} setPersoInfos={setPersoInfos} />
                </Grid>
              </Grid>
              )})}
          </TabPanel>
        ))}
      </DialogContent>
      <DialogActions>
        <div>
          <FormattedMessage id="product.margin" />
        </div>
        <div className='product-boutique-margin'>
          <SelectProductMargin product={product} margin={margin} setMargin={setMargin} />
        </div>
        <div className='product-total-price'><FormattedMessage id="global.total-price" /> : {ProductPricesString(product, margin, addonsTotalPrice())}</div>
      </DialogActions>
    </Dialog>
  )
}
